import React, { useEffect, useRef, useState } from "react";
import ChatContainer from "../../components/ChatBot/ChatContainer";
import config from "../../components/ChatBot/config.json";
import characters from "../../data/studyBuddyCharacters.json";
import brenda from "../../components/ChatBot/data/brenda.json";

const PlaygroundScreen = () => {
  const videoRef = useRef();
  const [context, setContext] = useState(brenda);
  const [selectedChar, setSelectedChar] = useState(characters.data[0].name);

  const triesLeft = +localStorage.getItem("triesLeft");
  const isTried = localStorage.getItem("isTried");
  const maxTriesLeft = +process.env["REACT_APP_STUDY_BUDDY_NUM_MESSAGES"] || 5;

  const isMobile = window.innerWidth < 600; //eslint-disable-line

  const setTriesLeft = (tries, tried = false) => {
    localStorage.setItem("triesLeft", tries);
    localStorage.setItem("isTried", tried);
  };

  useEffect(() => {
    document.title = "Study Buddy Playground - TeachMe TV";

    if (isTried) return;
    setTriesLeft(maxTriesLeft, true);
  }, []); //eslint-disable-line

  const [messages, setMessages] = useState([]);

  const handleSelectedChar = (char) => {
    setSelectedChar(char);
    import(`../../components/ChatBot/data/${selectedChar}.json`)
      .then((module) => {
        setContext(module.default);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  };

  return (
    <ChatContainer
      config={config}
      defaultContext={context}
      videoRef={videoRef}
      selectedChar={selectedChar}
      setSelectedChar={handleSelectedChar}
      setMessages={setMessages}
      messages={messages}
      isPlayground
      triesLeft={triesLeft}
      setTriesLeft={setTriesLeft}
      isMobile={isMobile}
    />
  );
};

export default PlaygroundScreen;
