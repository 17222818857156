import React from 'react';
import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";

const RecommendationCardsContainer = styled(View)`
    width: 100%;
    display: flex; 
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  
    @media only screen and (max-width: 820px) {
      flex-direction: column;
      align-items: center;
      background: rgba(255, 255, 255, 0.90);
      backdrop-filter: blur(8px);
      padding: 2rem 0;
    }
`

const RecommendationCard = ({recommendation, onClick}) => {
    return (
        <div
            onClick={onClick}
            style={{display: 'flex', flexDirection: 'column', width: 'calc(50% - 0.5rem)'}}
        >
            <p style={{fontSize: '1rem', margin: 0, fontWeight: 700, height: '1.5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                {recommendation.name}
            </p>
            <img
                alt={recommendation.name}
                src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${recommendation.thumbnail}.png`}
                style={{
                    width: '100%',
                    aspectRatio: '16/9',
                    cursor: 'pointer',
                    borderRadius: 20,
                    objectFit: 'cover',
                    objectPosition: '50% 50%',
                    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)'
                }}
            />
        </div>
    )
}

function RecommendationCards({recommendations, setRecommendation}) {
    return (
        <RecommendationCardsContainer>
            {recommendations?.map(rec => <RecommendationCard key={rec.id} recommendation={rec} onClick={() => setRecommendation(rec)} />)}
        </RecommendationCardsContainer>
    );
}

export default RecommendationCards;