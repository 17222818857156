import {Flex, Heading, Image, View} from "@aws-amplify/ui-react";
import {LandingButton} from "../../themed";

export default function LandingScreenActivityTypes(props) {
    return (<Flex
        direction={'column'}
        backgroundColor={'white'}
        paddingTop={'60px'}
        paddingBottom={'60px'}
    >
        <Heading
            level={1}
            fontFamily={'muli, sans-serif'}
            textAlign={'center'}
        >
            Our interactive lessons, games, videos and printable worksheets help develop creative and critical thinking skills.
        </Heading>
        <Flex justifyContent={'center'} alignItems={'center'} wrap={'wrap'} paddingTop={'30px'}>
            <View>
                <Image
                    alt={'Math icon'}
                    src={'/images/landing/icon-math.png'}
                    maxWidth={'150px'}
                />
                <Heading level={2} fontFamily={'muli, sans-serif'} textAlign={'center'}>
                    Math
                </Heading>
            </View>
            <View>
                <Image
                    alt={'Language Arts icon'}
                    src={'/images/landing/icon-languagearts.png'}
                    maxWidth={'150px'}
                />
                <Heading level={2} fontFamily={'muli, sans-serif'} textAlign={'center'}>
                    Language Arts
                </Heading>
            </View>
            <View>
                <Image
                    alt={'Science icon'}
                    src={'/images/landing/icon-science.png'}
                    maxWidth={'150px'}
                />
                <Heading level={2} fontFamily={'muli, sans-serif'} textAlign={'center'}>
                    Science
                </Heading>
            </View>
            <View>
                <Image
                    alt={'Music icon'}
                    src={'/images/landing/icon-music.png'}
                    maxWidth={'150px'}
                />
                <Heading level={2} fontFamily={'muli, sans-serif'} textAlign={'center'}>
                    Music
                </Heading>
            </View>
            <View>
                <Image
                    alt={'Art icon'}
                    src={'/images/landing/icon-art.png'}
                    maxWidth={'150px'}
                />
                <Heading level={2} fontFamily={'muli, sans-serif'} textAlign={'center'}>
                    Art
                </Heading>
            </View>
        </Flex>
        <LandingButton
            text={'See Samples'}
            margin={'30px auto 0px auto'}
            colors={{background: '#44acee', font: 'white'}}
            plansRef={props.samplesRef}
        />
    </Flex>)
}