import {Flex, Image, Text, View} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import activityTypes from "../../data/activityTypes.json";

export default function KidsZoneActivityCard({index, activity, isMobile}) {
    const navigate = useNavigate()

    return (<Flex
            key={index}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'20vw'}
            position={'relative'}
            onClick={() => {
                if (activity.type === 'printable' && activity.url) {
                    window.location.href = activity.url
                } else {
                    navigate(`/play/${activity.id}`)
                    window.scrollTo(0, 0)
                }
            }}
        >
            <View
                style={{
                    position: "absolute",
                    top: -10,
                    right: -10,
                    width: 40,
                    backgroundColor: "white",
                    borderRadius: 8,
                    border: "thin solid #000",
                    filter: "drop-shadow(3px 3px 3px #333)"
                }}
            >
                <Image
                    alt={activity.type}
                    src={`/images/${activityTypes.data[activity.type].icon}`}
                    width={40}
                />
            </View>
            <img
                alt={activity.name}
                src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png`}
                style={{
                    height: '13vw',
                    width: '100%',
                    cursor: 'pointer',
                    borderRadius: 8,
                    boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
                    objectFit: 'cover',
                    objectPosition: '50% 50%'
                }}
            />
            <Text fontSize={isMobile ? '11pt' : '1.4vw'}>
                {activity.name}
            </Text>
        </Flex>)
}