import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MarkdownRenderer from "./MarkdownRenderer";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import CachedIcon from "@mui/icons-material/Cached";
import Assistant from "./Assistant";
import getActivities from "../../helpers/getActivities";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import AudioRecorder from "../audio-recorder";
import useMic from "../../hooks/useMic";
// import {Flex} from "@aws-amplify/ui-react";
import { Grid, Link, Typography } from "@mui/material";
import Avatar from "./Avatar";
import AvatarSelector from "./AvatarSelector";
import EmotionSelector from "./EmotionSelector";
import { getActivityHistory } from "../../helpers/getActivityHistory";
import { UserProfile } from "../../models";
import { DataStore } from "aws-amplify";
import activityTypes from "../../data/activityTypes.json";
import ActionBar from "./ActionBar";
import {
  askBinaryQuestion,
  getMessageCategory,
} from "../../helpers/ChatGPTHelper";

const audioContext = new AudioContext();

const FILTERED_OUT_ACTIVITIES = ["You", "Hello"];
const TYPE_HUMAN = "human";
const PREFERENCE_KEY_PHRASES = [
  "I like",
  "I prefer",
  "My favourite",
  "I play",
  "I love",
  "I’m a fan of",
  "I have a liking for",
  "I opt for",
  "I appreciate",
  "My thing",
  "Soft spot for",
  "Lean towards",
  "I feel drawn to",
  "I can’t resist",
  "The best is",
];

const ChatContainer = (props) => {
  const {
    config,
    defaultContext,
    videoRef,
    handleCloseDialog,
    messages,
    setMessages,
    onAssistantResponded,
    selectedChar,
    setSelectedChar,
    minimized,
    setMinimized,
    isPlayground = false,
    triesLeft,
    setTriesLeft,
    isMobile=false
  } = props;
  const messagesContainerRef = useRef(null);
  const audioRef = useRef(null);
  const {
    user,
    name,
    selectedGrade,
    selectedEmotion,
    setSelectedEmotion,
    isVoiceControlled,
    setIsVoiceControlled,
  } = useStudyBuddyState(isPlayground);

  const sampleRate = useMic(
    isVoiceControlled,
    setIsVoiceControlled,
    config.transcribe.isAllowed
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [context, setContext] = useState(defaultContext);
  const [animationName, setAnimationName] = useState("intro");
  const [message, setMessage] = useState("");
  const [assistant, setAssistant] = useState(null);
  const [activities, setActivities] = useState(null);
  const [activitiesById, setActivitiesById] = useState(null);
  const [recommendedActivities, setRecommendedActivities] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [displayAvatarSelection, setDisplayAvatarSelection] = useState(false);
  const [displayEmotionSelection, setDisplayEmotionSelection] = useState(false);
  const [displayActionBar, setDisplayActionBar] = useState(true);

  const textareaRef = useRef(null);

  // Function to scroll to the bottom of the messages container
  const scrollMessageContainerToBottom = (scrollRef) => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }, 0);
    }
  };

  // useEffect to scroll to the bottom of the messages container when the component mounts
  useEffect(() => {
    if (messages.length && messagesContainerRef.current) {
      scrollMessageContainerToBottom(messagesContainerRef);
    }
  }, []);

  useEffect(() => {
    if (!selectedChar) return;

    import(`./data/${selectedChar}.json`)
      .then((module) => {
        const newContext = module.default;
        audioRef.current.pause();
        setContext(newContext);
        setAnimationName("intro");
        setDisplayAvatarSelection(false);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  }, [selectedChar]);

  useEffect(() => {
    setDisplayEmotionSelection(false);
  }, [selectedEmotion]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message]); // Recalculate height whenever the message changes

  useEffect(() => {
    if (!assistant) return;

    if (!location.pathname.includes("/play/")) return;

    const activityId = location.pathname.substring(6);
    const activity = activitiesById[activityId];
    const newMessage = {
      text: `Context: I'm viewing a TeachMe TV activity called ${activity.name}. Here's what this activity is about: ${activity.description}`,
      sender: "user",
      name: "You",
      hidden: true,
    };

    addMessageToConversation(newMessage).then((r) => {});
  }, [location, assistant]);

  // onload
  useEffect(() => {
    if (!name || !context) return;

    getActivities().then((activities) => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }

      if (activities.hasOwnProperty("data")) activities = activities.data;

      const namedActivities = activities.reduce((obj, item) => {
        obj[item.name] = item.id;
        return obj;
      }, {});
      setActivities(namedActivities);

      const tempActivitiesById = activities.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      setActivitiesById(tempActivitiesById);

      const newAssistant = new Assistant(
        selectedGrade,
        videoRef,
        audioRef,
        context,
        "assistant",
        activities,
        getCurrentlyViewingActivity(tempActivitiesById),
        isPlayground,
        isViewingActivity
      );

      if (messages.length <= 2) {
        newAssistant.speakGreeting(messages[0]?.text||'').then(() => {
          setAnimationName(
            context.avatar.animations.hasOwnProperty("talking_entry")
              ? "talking_entry"
              : "talking"
          );
        });
      }

      setAssistant(newAssistant);
      if (messages.length === 0) setMessages(newAssistant.getConversations());
      newAssistant
        .setUp(user?.username, name, selectedGrade, selectedChar)
        .then((_) => {});
    });
  }, [name, context]);

  const getSelectedEmotion = () => selectedEmotion || "ok";

  const handleAudioEnd = () => {
    if (!isThinking || isPlayground) {
      const randomIndex = Math.floor(
        Math.random() * context.avatar.idle_animations.length
      );
      setAnimationName(context.avatar.idle_animations[randomIndex]);
    } else {
      setAnimationName(
        context.avatar.animations.hasOwnProperty("thinking_intro")
          ? "thinking_intro"
          : "thinking"
      );
    }
  };

  const handleAudioPlay = () => {
    setAnimationName("talking");
  };

  const receiveTranscribedMessage = async (msg) => {
    if (msg.trim().length === 0) return;

    setIsRecording(false);
    textareaRef.current.focus();
    setMessage(msg);
    await handleSendMessage();
  };

  const addMessageToConversation = async (message) =>
    await assistant.addMessagesToConversation([message]);

  const animateCommand = (message) => {
    const command = message.split(" ")[0];
    if (Object.keys(context.avatar.animations).includes(command)) {
      setAnimationName(command);
      setMessage("");
    }
  };

  const speakRecordedMessage = (message) => {
    const waitingId = Buffer.from(message)
      .toString("base64")
      .replace(/\//g, "%3A"); // replaces / with %3A
    setAnimationName("talking");
    const audioURL = `https://teachmetv.s3.amazonaws.com/audio/waiting/${waitingId}.mp3`;
    audioRef.current.muted = false;
    audioRef.current.src = audioURL;
    audioRef.current.play().catch((error) => {
      console.error("Error trying to play the audio:", error);
    });
  };

  const isViewingActivity = () => location.pathname.includes("/play/");
  const getCurrentlyViewingActivity = (activitiesToUse) => {
    if (!isViewingActivity()) return null;


    const activities = activitiesToUse || activitiesById;

    return activities[location.pathname.substring(6)];
  };

  const handleSendMessage = async (newMessage) => {
    if (isPlayground && triesLeft > 0) {
      setTriesLeft(triesLeft - 1);
    }
    if (isPlayground && triesLeft <= 0) {
      return;
    }
    const messageToUse = newMessage || message;
    if (audioContext.state === "suspended") await audioContext.resume();

    videoRef.current?.play();

    if (!messageToUse.trim()) return;

    if (messageToUse[0] === "/") {
      // It's a command
      animateCommand(messageToUse.substring(1));
      return;
    }

    tryUpdatingPreferences(messageToUse).then((r) =>
      console.log("Preferences updated")
    );

    const yourMessage = { text: messageToUse, sender: "user", name: "You" };
    const hasRecommendations = await tryGivingRecommendations(
      messageToUse,
      yourMessage
    );

    if (hasRecommendations) return;

    setIsThinking(true);
    setMessage("");

    let messageCategory = await getMessageCategory(messageToUse);
    messageCategory = context.technical.waiting.hasOwnProperty(messageCategory)
      ? messageCategory
      : "other";

    let msgs;
    let conversationContainsWaitingMessage = false;
    const isAcademicQuestion = await askBinaryQuestion(
      "Is that either a math, language, or science question",
      messageToUse
    );
    if (isAcademicQuestion) {
      let waitingMessage =
        context.technical.waiting[messageCategory][
          Math.floor(
            Math.random() * context.technical.waiting[messageCategory].length
          )
        ] || "...";
      trySpeakingRecordedMessage(waitingMessage);

      const assistantMessage = {
        text: waitingMessage,
        sender: "assistant",
        name: context.biography.call_me,
      };
      msgs = [...messages, yourMessage, assistantMessage];
      conversationContainsWaitingMessage = true;
    } else {
      msgs = [...messages, yourMessage];
    }

    setMessages(msgs);

    setTimeout(() => {
      setMessage("");
      // Scroll to the bottom
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }, 200);

    let assistantResponse;
    try {
      assistantResponse = await assistant.addMessagesToConversation([
        yourMessage,
      ]);
    } catch (e) {
      console.error(e);
    }

    try {
      await handleAssistantResponse(
        msgs,
        assistantResponse,
        conversationContainsWaitingMessage
      );
    } catch (e) {
      console.error(e);
    }
  };

  const tryUpdatingPreferences = async (message) => {
    const preferenceSentence = detectPreferenceSentence(message);
    if (!preferenceSentence || isPlayground) return;

    try {
      const newPreference = new UserProfile({
        userId: user?.username,
        sentence: preferenceSentence,
        avatarId: selectedChar,
      });

      await DataStore.save(newPreference);
    } catch (e) {
      console.error(e);
    }
  };

  const detectPreferenceSentence = (str) => {
    // Split the input string into sentences.
    const sentences = str.match(/[^.!?]+[.!?]+|[^.!?]+$/g) || [];

    for (const phrase of PREFERENCE_KEY_PHRASES) {
      for (const sentence of sentences) {
        if (phrase.split(" ").every((word) => sentence.includes(word)))
          return sentence.trim(); // Return the detected preference sentence.
      }
    }

    return null;
  };

  const tryGivingRecommendations = async (message, yourMessage) => {
    if (!assistant.isAskingForRecommendation(message)) return;

    try {
      return await giveRecommendations(yourMessage);
    } catch (e) {
      console.error(e);
    }
  };

  const trySpeakingRecordedMessage = (message) => {
    if (message === "...") return;

    try {
      speakRecordedMessage(message);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAssistantResponse = async (
    previousMessages,
    assistantResponse,
    conversationContainsWaitingMessage
  ) => {
    const messageToSpeak = assistantResponse;
    let message = assistantResponse;
    const previousMessage =
      previousMessages[
        conversationContainsWaitingMessage
          ? previousMessages.length - 2
          : previousMessages.length - 1
      ].text;

    if (isViewingActivity()) {
      const currentActivity = getCurrentlyViewingActivity();
      message = appendActivityContentToMessage(
        currentActivity,
        previousMessage,
        message
      );
      message = appendForcedRecommendationsToMessage(currentActivity, message);
    }

    // Disabling inline recommendations for now
    // const recommendedActivities = assistant.findRecommendationsToAddToMessage(message).splice(0, 3);
    // if (recommendedActivities.length > 0) {
    //     addToRecommendedActivities(recommendedActivities.map(activity => activity.id));
    //     message = `${message}\n\nMaybe you'd like to try: \n\n${recommendedActivities
    //         .map(activity => formatRecommendation(activity))
    //         .join('')}`;
    // }
    //
    // // TODO: This should be moved to Assistant.js
    // const hasReceivedRecommendations = await appendRecommendationsToMessage(previousMessage, message);
    // if (!isViewingActivity() && !hasReceivedRecommendations)
    //     appendMatchedActivityContentToMessage(message);

    const msgs = [
      ...previousMessages.slice(
        0,
        conversationContainsWaitingMessage ? -1 : previousMessages.length
      ),
      {
        text: message,
        sender: "assistant",
        name: context.biography.call_me,
      },
    ];

    assistant.setMessages(msgs);
    let streamingResponse;
    if (context.avatar.type === TYPE_HUMAN && assistant.isStreamingEnabled())
      streamingResponse = streamHumanAvatar(messageToSpeak, previousMessage);
    else streamingResponse = await assistant.speak(messageToSpeak);

    if (!streamingResponse)
      msgs[
        msgs.length - 1
      ].text = `(Sorry I can't talk right now because I'm in a quiet space)\n\n${message}`;

    setMessages(msgs); // Only set after since getting a reply back from the speech provider takes time
    setIsThinking(false);

    if (onAssistantResponded)
      onAssistantResponded(message.content).then((_) => {
        // Scroll to the bottom
        setTimeout(() => {
          // Scroll to the bottom
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }, 500);
      });
  };

  const formatRecommendation = (activity) =>
  `\n\n [${activity.name}](/play/${activity.id}) \n\n <img href="/play/${activity.id}" style="width: 60%; cursor: pointer;" src="https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png" alt="${activity.name}"/>`

  const getLastActivitySubjects = (activityHistory) =>
    activityHistory?.length? activitiesById[activityHistory.slice(-3)[0].activityID].subjects: false;

  // Check if the subjects of the last activity are the same in the last 3 activities
  const hasSameSubjectInLastThree = (
    lastThreeActivities,
    lastActivitySubjects
  ) =>
    lastThreeActivities.every(
      (activity) =>
        JSON.stringify(activitiesById[activity.activityID].subjects) ===
        JSON.stringify(lastActivitySubjects)
    );

  const giveRecommendations = async (previousMessage) => {
    const activityHistory = await getActivityHistory(user);
    const lastActivitySubjects = getLastActivitySubjects(activityHistory);

    if (
      activityHistory.length >= 3 &&
      hasSameSubjectInLastThree(activityHistory.slice(-3), lastActivitySubjects)
    )
      return await appendRecommendationsToMessages(
        previousMessage,
        `You've done a lot of ${lastActivitySubjects.join(
          ", "
        )} activities. Would you like to try something different? Here are some suggestions: `,
        lastActivitySubjects
      );
    else
      return await appendRecommendationsToMessages(
        previousMessage,
        `I recommend these activities:`
      );

    return false;
  };

  const appendRecommendationsToMessages = async (
    previousMessage,
    introMessage,
    subjects
  ) => {
    let newMessage = "";
    const recommendations = (
      await assistant.getRecommendations(
        user,
        selectedGrade,
        getSelectedEmotion(),
        previousMessage.text,
        subjects
      )
    ).filter((activity) => !recommendedActivities.includes(activity.id));
    addToRecommendedActivities(recommendations.map((activity) => activity.id));
    recommendations.forEach((recommendedActivity) => {
      newMessage += formatRecommendation(recommendedActivity);
    });
    const assistantMessage = {
      text: `${introMessage} ${newMessage}`,
      sender: "assistant",
      name: context.biography.call_me,
    };
    const msgs = [...messages, previousMessage, assistantMessage];
    setMessages(msgs);
    resetView();

    // TODO: Update message saying it can't speak right now if it fails
    await assistant.speak(`${introMessage} ${newMessage}`);

    return true;
  };

  const resetView = () => {
    setMessage("");

    setTimeout(() => {
      setMessage("");
      // Scroll to the bottom
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }, 100);
  };

  const appendRecommendationsToMessage = async (previousMessage, message) => {
    // We might also want to append recommendations after answering a question
    if (!assistant.isAskingForRecommendation(previousMessage)) return false;

    message.content += "\n\nHere are a few activities you can try:";

    const recommendations = await assistant.getRecommendations(
      user,
      selectedGrade,
      getSelectedEmotion(),
      previousMessage
    );
    addToRecommendedActivities(recommendations.map((activity) => activity.id));
    recommendations.forEach((recommendedActivity) => {
      message.content += formatRecommendation(recommendedActivity);
    });

    return true;
  };

  const appendMatchedActivityContentToMessage = (message) => {
    const matchedActivities = Object.keys(activities).filter(
      (key) => !FILTERED_OUT_ACTIVITIES.includes(key) && message.includes(key)
    );
    matchedActivities.forEach((activityId) => {
      message += formatRecommendation(activities[activityId]);
    });
  };

  const appendActivityContentToMessage = (
    activity,
    previousMessage,
    message
  ) => {
    if (
      !(
        activity &&
        activity.hasOwnProperty("ai") &&
        activity.ai.hasOwnProperty("post_curation")
      )
    )
      return message;

    const images = [];

    for (const key in activity.ai.post_curation) {
      const matched = Object.keys(activity.ai.post_curation[key]).find((key2) =>
        previousMessage.toLowerCase().includes(key2)
      );
      if (
        matched &&
        activity.ai.post_curation[key][matched].hasOwnProperty("img") &&
        !images.includes(activity.ai.post_curation[key][matched].img)
      ) {
        message += `\n\n${activity.ai.post_curation[key][matched].text}\n\n![Helper image](${activity.ai.post_curation[key][matched].img})\n`;
        images.push(activity.ai.post_curation[key][matched].img);
      }
    }

    return message;
  };

  const appendForcedRecommendationsToMessage = (currentActivity, message) => {
    if (
      !(
        currentActivity &&
        currentActivity.hasOwnProperty("ai") &&
        currentActivity.ai.hasOwnProperty("recommendations")
      )
    )
      return message;

    addToRecommendedActivities(currentActivity.ai.recommendations);

    currentActivity.ai.recommendations.forEach((recommendedActivityId) => {
      const activity = activitiesById[recommendedActivityId];
      message += formatRecommendation(activity);
    });

    return message;
  };

  const streamHumanAvatar = async (messageToSpeak, previousMessage) => {
    const talkResponse = await assistant.talk(
      messageToSpeak,
      context,
      previousMessage
    );

    videoRef.current.src = talkResponse.data.url;
    videoRef.current.loop = false;
    videoRef.current.play();

    return talkResponse;
  };

  // Function to add elements to the array and update state correctly
  const addToRecommendedActivities = (newElements) =>
    setRecommendedActivities((prevRecommendedActivities) => [
      ...prevRecommendedActivities,
      ...newElements,
    ]);

  return (
    <Grid
      container
      style={{
        backgroundColor: config.ui.chat_box?.hasOwnProperty("color")
          ? config.ui.chat_box.color
          : "#E0E8F1",
      }}
    >
      <Grid item xs={12} sm={6} display={'flex'} direction={'column'} alignContent={'center'} justifyContent={'center'}>
          {/* {isMobile && (
              <Typography padding={2}>
                  Not optimized for mobile
              </Typography>
          )} */}
          {isPlayground && (
              <Link
                  style={{ cursor: "pointer" }}
                  component="button"
                  variant="body1"
                  onClick={()=>
                    window.open("https://teachmetv.s3.amazonaws.com/documents/For+Immediate+Release.pdf")
                  }
              >
                  Press Release
              </Link>
          )}
        {config.avatar.available.length > 0 && !isPlayground && (
          <button
            className="white-button"
            style={{
              position: "absolute",
              top: 30,
              left: 10,
              width: "100px",
              height: "64px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 9999,
            }}
            onClick={() => {
              setDisplayAvatarSelection(true);
            }}
          >
            <CachedIcon sx={{ color: "black" }} />
            <img
              src={`/images/avatars/${context?.id}.png`}
              alt={`${context?.id} avatar`}
              style={{
                width: "48px",
                height: "48px",
              }}
            />
          </button>
        )}
        {displayAvatarSelection && (
          <AvatarSelector
            config={config}
            setSelectedChar={setSelectedChar}
            setDisplayAvatarSelector={setDisplayAvatarSelection}
            // avatarStyles={isPlayground ? { top: 80 } : {}}
            isPlayground={isPlayground}
            setMessages={setMessages}
          />
        )}
        {displayEmotionSelection && (
          <EmotionSelector
            backgroundColor={"white"}
            setSelectedEmotion={setSelectedEmotion}
            setDisplayEmotionSelection={setDisplayEmotionSelection}
          />
        )}
        <Grid>
        <Avatar
          config={config}
          context={context}
          videoRef={videoRef}
          animation={animationName}
          isThinking={isThinking}
        />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} padding={isPlayground ? 2 : 0}>
        <div
          className="chat-container"
          style={{ minWidth: isPlayground ? "unset" : "400px" }}
        >
          {isRecording && (
            <AudioRecorder
              sampleRate={sampleRate}
              callbackFn={receiveTranscribedMessage}
            />
          )}

          {/* Hiding for now */}
          {/*<button*/}
          {/*    className="white-button"*/}
          {/*    style={{*/}
          {/*        position: 'absolute',*/}
          {/*        top: 98,*/}
          {/*        left: 10,*/}
          {/*        width: "100px",*/}
          {/*        height: '64px',*/}
          {/*        display: "flex",*/}
          {/*        justifyContent: "space-between",*/}
          {/*        alignItems: "center",*/}
          {/*    }}*/}
          {/*    onClick={() => {*/}
          {/*        setDisplayEmotionSelection(!displayEmotionSelection);*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <CachedIcon sx={{color: 'black'}}/>*/}
          {/*    <span>{(getSelectedEmotion() && EMOTIONS[getSelectedEmotion()].emoji) || EMOTIONS.ok.emoji}</span>*/}
          {/*</button>*/}

          {/*<button*/}
          {/*    className={'send-button'}*/}
          {/*    style={{*/}
          {/*        position: 'absolute',*/}
          {/*        left: 0,*/}
          {/*        top: '50%',*/}
          {/*        transform: 'translateY(-50%)'*/}
          {/*    }}*/}
          {/*    onClick={() => setMinimized(!minimized)}*/}
          {/*>*/}
          {/*    {!minimized ? '<' : '>'}*/}
          {/*</button>*/}

          <audio
            ref={audioRef}
            playsInline
            onEnded={handleAudioEnd}
            onPlay={handleAudioPlay}
          />
          <div ref={messagesContainerRef} className="messages-container">
            {messages
              .filter((msg) => !msg.hidden)
              .map((msg, index) => (
                <div
                  key={index}
                  className={`message ${msg.sender.toLowerCase()}`}
                >
                  <strong>{msg.name}: </strong>
                  <MarkdownRenderer
                    markdownContent={msg.text}
                    context={context}
                    sender={msg.sender.toLowerCase()}
                    assistant={assistant}
                    handleCloseDialog={handleCloseDialog}
                  />
                </div>
              ))}
          </div>
          {displayActionBar && (
            <ActionBar
              messages={messages}
              handleSendMessage={handleSendMessage}
              handleAssistantResponse={handleAssistantResponse}
              isThinking={isThinking || (isPlayground && triesLeft === 0)}
              isViewingActivity={isViewingActivity()}
              selectedGrade={selectedGrade}
              isPlayground={isPlayground}
            />
          )}
          <div className="input-container">
            <textarea
              ref={textareaRef}
              value={message}
              disabled={isThinking || (isPlayground && triesLeft === 0)}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={async (e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleSendMessage();
                  e.preventDefault();
                }
              }}
              placeholder="Type your message..."
              rows="1" // This sets the initial number of rows. You can adjust this.
              style={{
                overflowY: "auto",
                resize: "none",
                maxHeight: "500px",
                boxSizing: "border-box", // to include padding and border in total height
              }}
            />

            {/* Not recoding but a message got typed: Show send button */}
            {(!config.transcribe.isAllowed ||
              (!isRecording && message.trim().length > 0) ||
              !isVoiceControlled) && (
              <button
                disabled={isThinking || (isPlayground && triesLeft === 0)}
                className={`send-button ${message.length > 2 ? "pulsate" : ""}`}
                onClick={async () => await handleSendMessage()}
              >
                <SendIcon />
              </button>
            )}

            {/* Not recording and nothing typed yet: show recoding icon */}
            {config.transcribe.isAllowed &&
              !isRecording &&
              message.trim().length === 0 &&
              isVoiceControlled && (
                <button
                  disabled={isThinking || (isPlayground && triesLeft === 0)}
                  className={`send-button ${!isRecording ? "pulsate" : ""}`}
                  onClick={async () => setIsRecording(true)}
                >
                  <MicIcon />
                </button>
              )}
          </div>
        </div>

        {isPlayground && (
          <>
            <Typography padding={2}>
              {triesLeft !== 0 ? triesLeft : "No"} more messages left
            </Typography>
            <Link
              style={{ cursor: "pointer" }}
              component="button"
              variant="body1"
              onClick={() => navigate("/contact")}
            >
              {triesLeft !== 0 ? "Tell us what you think" : "Want more tries?"}
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatContainer;
