import {Button, Link, Text, TextField} from "@aws-amplify/ui-react"
import axios from "axios"
import {useState} from "react"

export default function LandingScreenEmailWidget() {
    const [email, setEmail] = useState('')
    const [isSendingEmail, setIsSendingEmail] = useState(false)
    const [isEmailSent, setIsEmailSent] = useState(false)

    if (isEmailSent) return (<Text>
            Thank you! <Link
            href="https://teachmetv.s3.amazonaws.com/printables/Playground%2Bbuilding%2Bactivities.pdf">Click to
            download your free PDF</Link>.
        </Text>)


    return (<>
            <TextField
                label={'Email Address'}
                labelHidden={true}
                placeholder={'email address'}
                disabled={isSendingEmail}
                margin={0}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <Button
                marginTop={8}
                disabled={isSendingEmail}
                onClick={async () => {
                    try {
                        setIsSendingEmail(true);
                        await axios.post(`${process.env['REACT_APP_EMAIL_SERVICE_URL']}/contact/add`, {
                            email: email
                        });

                        setIsEmailSent(true);
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                Submit
            </Button>
        </>)
}