import {Button, Divider, Flex, Heading, Text, TextAreaField, TextField, useTheme, View} from "@aws-amplify/ui-react";
import axios from "axios";
import {useEffect, useState} from "react";

export default function ContactScreen() {
    useEffect(() => {
        document.title = "Contact - TeachMe TV";
    }, []);

    const {tokens} = useTheme();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isSending, setIsSending] = useState(false);
    const [emailSentStatus, setEmailSentStatus] = useState(null);


    const Spacer = () => <View paddingTop={tokens.space.large}/>

    const sendEmail = async () => {
        setIsSending(true);

        try {
            const response = await axios.post(`${process.env['REACT_APP_EMAIL_SERVICE_URL']}/send`, {
                firstName: firstName, lastName: lastName, email: email, message: message
            });

            setEmailSentStatus(response.status);
        } catch (error) {
            console.error(error);
            setEmailSentStatus(400);
            setIsSending(false);
        }
    }

    return (<View>
            <Heading level={1}>Contact Us</Heading>
            <Divider paddingTop={tokens.space.medium}/>
            {(emailSentStatus === null || emailSentStatus === 400) && <View paddingBottom={tokens.space.xxl}>
                <Heading level={2} padding={tokens.space.large}>We’d love to hear from you! Use the form below to
                    get in touch.</Heading>
                <form
                    style={{
                        width: '80vw',
                        maxWidth: '600px',
                        margin: 'auto auto',
                        backgroundColor: 'white',
                        padding: tokens.space.large,
                        borderRadius: '15px',
                        boxShadow: '5px 5px 15px 5px #888888',
                    }}
                >
                    <Flex direction={{base: 'row'}} width={'100%'}>
                        <TextField label="First Name" width={'50%'} value={firstName}
                                   onChange={(e) => setFirstName(e.currentTarget.value)}/>
                        <TextField label="Last Name" width={'50%'} value={lastName}
                                   onChange={(e) => setLastName(e.currentTarget.value)}/>
                    </Flex>

                    <Spacer/>

                    <TextField label="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)}
                               isRequired={true}/>

                    <Spacer/>

                    <TextAreaField label="Message" resize="vertical" value={message}
                                   onChange={(e) => setMessage(e.currentTarget.value)} isRequired={true}/>

                    <Spacer/>

                    <Button
                        variation="primary"
                        disabled={isSending}
                        onClick={() => sendEmail()}
                    >
                        {!isSending ? 'Submit' : 'Sending...'}
                    </Button>
                    {emailSentStatus === 400 &&
                        <Text padding={tokens.space.large}>An error occurred, please try again later.</Text>}
                </form>
            </View>}
            {emailSentStatus === 200 &&
                <Text paddingTop={tokens.space.large}>Thank you for your message. We'll get back to you as soon as
                    possible.</Text>}
        </View>)
}