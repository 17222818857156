import { useState, useRef, useEffect } from "react";
import ChatContainer from "./ChatContainer";
import "./chat.css";
import "./animations.css";

import config from "./config.json";
import DragResizeDialog from "./DragResizeDialog";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import { Flex, Heading } from "@aws-amplify/ui-react";
import { useLocation } from "react-router-dom";
import CharacterAnimation from "./CharacterAnimation";

const TYPE_MONSTER = "monster";
const TYPE_HUMAN = "human";
const STUDY_BUDDY_RESIZE_RATIO = 150;

const ChatBot = ({ defaultContext = null, displayThumbnail = true }) => {
  const videoRef = useRef();
  const location = useLocation();
  const [context, setContext] = useState(defaultContext);
  const { user, selectedChar, setSelectedChar } = useStudyBuddyState();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isOveractive, setIsOveractive] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [messages, setMessages] = useState([]);

  const [open, setOpen] = useState(false);

  let inactivityTimer = null;
  let componentTimer = null;

  const resetTimer = () => {
    clearTimeout(inactivityTimer);
    clearTimeout(componentTimer);

    inactivityTimer = setTimeout(() => {
      setIsInactive(true);
    }, config.inactivity_timer || 120000); // 2 minutes in milliseconds

    componentTimer = setTimeout(() => {
      setIsOveractive(true);
    }, config.overactivity_timer || 1200000); // 20 minutes in milliseconds
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keydown", handleActivity);

    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);

      clearTimeout(inactivityTimer);
      clearTimeout(componentTimer);
    };
  }, []);

  // On selectedChar changed
  useEffect(() => {
    if (!selectedChar) return;

    import(`./data/${selectedChar}.json`)
      .then((module) => {
        setContext(module.default);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  }, [selectedChar]);

  //auto close chat box when route changes
  useEffect(() => {
    setMessages([]);
  }, [location.pathname]);

  const handleCloseDialog = () => {
    if (!minimized) setOpen(false);
  };

  const handleVideoButtonClick = () => {
    videoRef.current?.play();
    setIsAnimating(true);
    setOpen(true);
    setTimeout(
      () => {
        setIsAnimating(false);
      },
      config.ui.video_thumbnail.animated
        ? config.ui.video_thumbnail.animation_duration
        : 0
    ); // This timeout should match the duration of your CSS transition
  };

  const getPingMessage = () => {
    if (!selectedChar || !context) return "Hello!";

    const messages = isInactive ? "inactivity" : "overactivity";

    const randomIndex = Math.floor(
      Math.random() * context.technical.ping[messages].length
    );
    return context.technical.ping[messages][randomIndex];
  };

  const getRandomIdleAnimation = () => {
    const randomIndex = Math.floor(
      Math.random() * context.avatar.idle_animations.length
    );
    return context.avatar.idle_animations[randomIndex];
  };

  if (!config.enabled || !user) return <></>;

  // Not authorized on the page the user is currently viewing
  if (
    !config.authorized_routes.some((route) => location.pathname.includes(route))
  )
    return <></>;

  return (
    <>
      <DragResizeDialog
        nearFullscreen={config.ui.chat_box.fullscreen}
        open={open}
        onClose={handleCloseDialog}
        minimized={minimized}
      >
        <ChatContainer
          config={config}
          defaultContext={context}
          videoRef={videoRef}
          selectedChar={selectedChar}
          setSelectedChar={setSelectedChar}
          minimized={minimized}
          setMinimized={setMinimized}
          handleCloseDialog={handleCloseDialog}
          setMessages={setMessages}
          messages={messages}
        />
      </DragResizeDialog>
      <div className="Chat">
        {(isInactive || isOveractive) && !open && (
          <Flex
            id={"talk-video-preview-notifier"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              width: config.avatar.thumbnail_size,
            }}
          >
            <Heading level={3}>{getPingMessage()}</Heading>
          </Flex>
        )}
        <div
          id="talk-video-preview-container"
          className={isAnimating ? "fade-out" : ""}
          onClick={handleVideoButtonClick}
          style={{ display: !isAnimating && open ? "none" : "block" }}
        >
          <div
            className="inner-container"
            style={{
              width: config.avatar.thumbnail_size,
              height: config.avatar.thumbnail_size,
            }}
          >
            <div className="bubble">Let's chat!</div>
            {context.avatar.type === TYPE_MONSTER && selectedChar && (
              <CharacterAnimation
                characterId={context.id}
                avatarData={context.avatar}
                animationName={getRandomIdleAnimation()}
                scale={context.avatar.sprite.width / STUDY_BUDDY_RESIZE_RATIO }
              />
            )}
            {context.avatar.type === TYPE_HUMAN && (
              <video
                id="talk-video-preview"
                width={config.avatar.thumbnail_size}
                height={config.avatar.thumbnail_size}
                playsInline={true}
                autoPlay
                loop
                muted={true}
                src={context.avatar.idle_video}
              ></video>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBot;
