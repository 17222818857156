import React from "react";
import styled from "styled-components";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Image } from "@aws-amplify/ui-react";
import { handleScheduleDemo } from "./schedule-demo";
import { ReflectivePractices } from "../../components/ReflectivePractices";

const StyledCardWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "32px",
  height: "100%",

  ".card": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "32px",
    padding: "16px",
  },
});

const StyledContainer = styled(Container)({
  backgroundColor: "#f4f1ec",
  height:"100%",
  paddingBlock: "24px",
});

const StyledButton = styled(Button)({
  "&&": {
    transition: "0.5s",

    "&:hover": {
      boxShadow: "none",
      transform: "scale(1.08)",
    },
  },
});

const PLANS = [
  {
    title: "Make lessons interactive, dynamic and stimulating.",
    header: "Engaging",
    icon: "/images/schools-screen/engaging.webp",
    bgColor: "#44abee",
  },
  {
    title: "Organize and coordinate resources with state standards",
    header: "Efficient",
    icon: "/images/schools-screen/efficient.webp",
    bgColor: "#ffc122",
  },
  {
    title: "Access mini lessons & assessments with every activity",
    header: "Effective",
    icon: "/images/schools-screen/effective.webp",
    bgColor: "#ef4b4a",
  },
];

const Plans = () => {
  return (
    <StyledContainer maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "space-between", xs: "center" },
          gap: "16px",
          flexWrap: "wrap",
          marginBottom: "48px",
        }}
      >
        <Typography variant="h5" fontSize={40} fontWeight="bold">
          Plans for Schools, Teachers & Districts
        </Typography>
        <StyledButton onClick={handleScheduleDemo}>
          <Image
              src={"/images/schools-screen/schedule-demo.svg"}
              alt="Schedule Demo"
              width={"300px"}
          />
        </StyledButton>
      </Box>
      <Grid container marginTop={4} rowSpacing={2} columnSpacing={{ xs: 10 }}>
        {PLANS.map((item, index) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <StyledCardWrapper>
              <Typography
                variant="h6"
                fontSize={28}
                fontWeight={600}
                sx={{ color: "#1f2351", lineHeight: 1.2 }}
              >
                {item.title}
              </Typography>
              <Box className="card" style={{ backgroundColor: item.bgColor }}>
                <Typography
                  variant="h6"
                  fontSize={36}
                  fontWeight={600}
                  sx={{ color: "#1f2351", lineHeight: 1 }}
                >
                  {item.header}
                </Typography>
                <Image alt={item.title} src={item.icon} height={300} />
              </Box>
            </StyledCardWrapper>
          </Grid>
        ))}
      </Grid>
      <ReflectivePractices />
    </StyledContainer>
  );
};

export default Plans;
