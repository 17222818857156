import {Flex, Heading, Image, Text, View} from "@aws-amplify/ui-react";
import LandingScreenPriceCard from "./price-card";

export default function LandingScreenPlans(props) {
    return (<View
            backgroundColor={'#7ca7e6'}
        >
            <Flex
                ref={props.plansRef}
                direction={'column'}
                alignItems={'center'}
                paddingBottom={'150px'}
                backgroundImage={"url('/images/landing/stars2.svg')"}
                style={{
                    backgroundSize: 'contain'
                }}
            >
                <Image
                    alt={'Logo with shadow'}
                    src={'/images/landing/logo-with-shadow.png'}
                    width={props.isMobile ? '80%' : '44vw'}
                    style={{zIndex: 1}}
                />
                <View
                    backgroundImage={"url('/images/landing/banner-small.png')"}
                    margin={'auto auto'}
                    style={{
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: props.isMobile ? '90vw' : '450px',
                        paddingBottom: '50px',
                        zIndex: 1
                    }}
                >
                    <Heading level={2} fontFamily={'muli, sans-serif'} textAlign={'center'} marginTop={'30px'}>
                        Plans
                    </Heading>
                </View>

                <View>
                    <Text
                        color={'white'}
                        fontSize={'48pt'}
                        fontFamily={'muli, sans-serif'}
                        fontWeight={700}
                    >
                        Every plan includes a 7 day free trial
                    </Text>
                </View>

                <Flex
                    wrap={'wrap'}
                    justifyContent={'center'}
                    gap={props.isMobile ? 30 : 60}
                >
                    <LandingScreenPriceCard
                        title={'Families Monthly'}
                        price={'$' + process.env['REACT_APP_STRIPE_MONTHLY_PRICE']}
                        label={'MOST FLEXIBLE'}
                        link={process.env['REACT_APP_STRIPE_MONTHLY_URL']}
                    />

                    <LandingScreenPriceCard
                        title={'Families Annually'}
                        price={'$' + process.env['REACT_APP_STRIPE_ANNUAL_PRICE']}
                        label={'BEST VALUE!'}
                        showPointer={true}
                        link={process.env['REACT_APP_STRIPE_ANNUAL_URL']}
                    />

                    <LandingScreenPriceCard
                        title={'Teachers Annually'}
                        price={'$' + process.env['REACT_APP_STRIPE_TEACHER_PRICE']}
                        showDiscount={true}
                        discountLabel={'20% OFF'}
                        link={process.env['REACT_APP_STRIPE_TEACHER_URL']}
                    />
                </Flex>
            </Flex>
        </View>)
}