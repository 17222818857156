import nlp from "compromise";
const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  organization: process.env["REACT_APP_OPENAPI_ORGANIZATION"],
  apiKey: process.env["REACT_APP_OPENAPI_API_KEY"],
});
const openai = new OpenAIApi(configuration);

const config = {
  model: "gpt-4-1106-preview",
  temperature: 1,
  max_tokens: 1024,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
};

const askCategoryQuestion = async (
  question = "",
  message = "",
  categories = []
) => {
  try {
    const content = `${question}: "${message}". Answer only with one of the following choices: ${categories.join(
      ", "
    )}. No period.`;
    const openaiResponse = await openai.createChatCompletion({
      ...config,
      messages: [
        {
          content,
          role: "user",
        },
      ],
    });

    return openaiResponse.data.choices[0].message.content.toLowerCase();
  } catch (e) {
    console.error(e);
  }

  return null;
};

const askBinaryQuestion = async (question, message, configOverride) => {
  try {
    const configToUse = configOverride ? configOverride : config;
    const content = `${question}: ${message}. Answer only by "yes" or "no" in a single word. No period.`;
    const openaiResponse = await openai.createChatCompletion({
      ...configToUse,
      messages: [
        {
          content,
          role: "user",
        },
      ],
    });

    return openaiResponse.data.choices[0].message.content
      .toLowerCase()
      .includes("yes");
  } catch (e) {
    console.error(e);
  }

  return false;
};

const containsYesNoQuestion = async (message) => {
  const configOverride = JSON.parse(JSON.stringify(config));
  configOverride.model = "gpt-3.5-turbo"; // Somehow, GPT-3.5 is better than 4 at this
  return await askBinaryQuestion(
    "Is there a yes/no question as the last part of this text?: ",
    message,
    configOverride
  );
};

const getMessageCategory = async (message) =>
  await askCategoryQuestion(
    "Is this a personal, academic, or math question, or something else?: ",
    message,
    ["personal", "academic", "math", "other"]
  );

//remove personal info of the user before passing the message to openAI
const removePersonaliInfo = (sentence) => {
  let doc = nlp(sentence);

  // Remove entities that might contain personal details
  doc.people().replace(" ");
  doc.places().replace(" ");
  doc.match("#PhoneNumber").replace(" ");
  doc.match("#Email").replace(" ");
  doc.match("#Date").replace(" ");
  doc.match("#Place").replace(" ");

  return doc.text();
};

export {
  askBinaryQuestion,
  containsYesNoQuestion,
  getMessageCategory,
  removePersonaliInfo,
};
