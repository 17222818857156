import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from "@mui/material/MenuItem";
import { DataStore } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

const AccountMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleAccount() {
				handleClose();
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        navigate("/accountInformation");
    }

    return (
        <div>
            <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
                <MenuIcon fontSize="large" />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={handleAccount}>Account</MenuItem>
                <Authenticator>
                    {({ signOut }) => (
                        <MenuItem
                            onClick={async () => {
                                handleClose();
                                await DataStore.clear();
                                searchParams.set("signin", "true");
                                signOut();
                                navigate("/account");
                            }}
                        >
                            Sign out
                        </MenuItem>
                    )}
                </Authenticator>
            </Menu>
        </div>
    );
};

export default AccountMenu;
