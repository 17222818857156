import SpeechBubble from "./SpeechBubble";
import CharacterAnimation from "./CharacterAnimation";

const TYPE_MONSTER = "monster";

export default function Avatar({
  config,
  context,
  videoRef,
  animation = "intro",
  isThinking,
  onClick,
  additionalStyle,
  children
}) {
  // const animationName = isThinking ? 'thinking' : animation;
  return (
    <div className="avatar-container">
      {isThinking && <SpeechBubble />}
      {context.avatar.type === TYPE_MONSTER ? (
        <CharacterAnimation
          characterId={context.id}
          avatarData={context.avatar}
          animationName={animation || "intro"}
          onClick={onClick}
          additionalStyle={additionalStyle}
        >
          {children}
        </CharacterAnimation>
      ) : (
        <video
          id="talk-video"
          ref={videoRef}
          width={config.avatar.size}
          height={config.avatar.size}
          playsInline={true}
          autoPlay
          loop
          preload="auto"
          poster={config.avatar.static_image}
          onEnded={(event) => {
            event.target.src = context.avatar.idle_video;
            event.target.loop = true;
            event.target.play();
          }}
          style={{
            opacity: 1,
            visibility: "visible",
          }}
          src={context.avatar.idle_video}
        ></video>
      )}
    </div>
  );
}
