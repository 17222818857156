import {Image, View} from "@aws-amplify/ui-react";
import React, {useContext} from "react";

import tipsDict from '../../data/tips.json';
import {AudioContext} from "../../App";

const tips = tipsDict.data;

export function TipGiver({
                             isMobile,
                             characterType = 'inquisitor',
                             bubbleType = 'thinking',
                         }) {
    const {playVO} = useContext(AudioContext);

    if (!tips.characters.hasOwnProperty(characterType) || !tips.bubbles.hasOwnProperty(bubbleType))
        return <></>

    const character = tips.characters[characterType];
    const bubble = tips.bubbles[bubbleType];

    return (
        <View
            position={'relative'}
        >
            <Image
                alt={`${characterType} character`}
                src={character.image}
                width={character.width}
                marginLeft={window.innerWidth < 450 ? character.marginLeft.mobile : character.marginLeft.desktop}
                marginTop={isMobile ? character.marginTop.mobile : character.marginTop.desktop}
            />
            <Image
                alt={`${bubbleType} bubble`}
                src={bubble.image}
                width={isMobile ? bubble.width.mobile : bubble.width.desktop}
                position={'absolute'}
                top={0}
                left={0}
                onMouseEnter={() => playVO(bubble.audio)}
            />
        </View>
    )
}