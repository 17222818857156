import {Flex, Heading, Image, View} from "@aws-amplify/ui-react";
import {LandingButton} from "../../themed";

export default function LandingScreenFun(props) {
    const IPadFrame = (props) => (<Image
        src={'/images/landing/ipad-frame-5.png'}
        style={{
            zIndex: 0
        }}
        {...props}
    />)

    const Content = () => (<View
        width={props.isMobile ? '100%' : '33vw'}
        paddingTop={props.isMobile ? '30px' : '0px'}
        paddingLeft={props.isMobile ? '30px' : '0px'}
        paddingRight={props.isMobile ? '30px' : '0px'}
        paddingBottom={props.isMobile ? '0px' : '30px'}
    >
        <Heading level={1} fontFamily={'muli, sans-serif'} color={'white'} fontSize={props.isMobile ? '32pt' : '42pt'} fontWeight={'800'}>
            Impactful learning that's fun...
        </Heading>
        <Heading level={2} color={'white'} paddingTop={'30px'} paddingBottom={'70px'}
                 fontFamily={'muli, sans-serif'}>
            for kids ages 5-12.
            See what we can do for your child.
        </Heading>
        {!props.isMobile && <CTA/>}
    </View>)

    const CTA = () => (<LandingButton text={'Sign Up For free'} plansRef={props.plansRef}/>)

    return (<Flex
        backgroundColor={'#ef4b4b'}
        wrap={'wrap'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingTop={20}
    >
        {props.isMobile && <>
            <Content/>
            <CTA/>
            <IPadFrame paddingTop={'60px'}/>
        </>}
        {!props.isMobile && <Flex alignItems={'flex-end'}>
            <IPadFrame width={'60vw'} objectFit={'contain'}/>
            <Content/>
        </Flex>}
    </Flex>)
}