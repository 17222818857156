import AWS from "aws-sdk";
import axios from "axios";

AWS.config.region = "us-east-1";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env["REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID"],
});

const audioCtxt =  new (window.AudioContext || window.webkitAudioContext)()

const SUPPORTED_PROVIDERS = ["amazon", "elevenlabs"];

const SYMBOL_TO_SPELLING_MAP = {
  " + ": "plus",
  " - ": "minus",
  " * ": "times",
  " x ": "times",
  " / ": "divided by",
  " ^ ": "raised to the power of",
  "%": "percent",
  " = ": "equals",
  " < ": "less than",
  " > ": "greater than",
  " <= ": "less than or equal to",
  " >= ": "greater than or equal to",
  " != ": "not equal to",
  " | ": "absolute value",
  " √ ": "square root",
};

const WORD_CORRECTION_MAP = {
  graphing: "graffing",
};

class SpeechHelper {
  constructor(audioRef, context, speechConfig) {
    this.audioRef = audioRef;
    this.context = context;
    this.speechConfig = speechConfig;
  }

  //
  // PUBLIC METHODS

  async speak(message) {
    if (
      !this.speechConfig.active ||
      !SUPPORTED_PROVIDERS.includes(this.context.speech.provider.type)
    )
      return Promise.resolve(false);

    let curatedMessage = this._stripCodeBlocks(message);
    curatedMessage = this._replaceWords(curatedMessage);
    curatedMessage = this._replaceMathSymbolsWithSpellings(curatedMessage);
    curatedMessage = this._stripMarkdown(curatedMessage);

    try {
      switch (this.context.speech.provider.type) {
        case "amazon":
          return await this._speakMessageAWS(curatedMessage);
        case "elevenlabs":
          return await this.elevenLabsRobustnessResponse(curatedMessage);
        default:
          return await this._speakMessageAWS(curatedMessage);
      }
    } catch (error) {
      console.error("Error trying to speak the message:", error);
      return Promise.resolve(false);
    }
  }

  //
  // PRIVATE METHODS

  _stripMarkdown = (str) =>
    str.replace(/(\[)(.*?)(\])(\()(.*?)(\))/g, "$2").trim();

  async _speakMessageAWS(messageContent) {
    const polly = new AWS.Polly();
    const params = {
      OutputFormat: "mp3",
      Text: messageContent,
      VoiceId: this.context.speech.provider.voice_id,
      Engine: "neural",
      TextType: "text",
    };
    const audioStream = await polly.synthesizeSpeech(params).promise();
    if (!audioStream.AudioStream) return false;

    await this._playAudioStream(audioStream.AudioStream);

    return true;
  }

  // added try catch to increase robustness in eleven labs api
  async _speakMessageElevenLabs(messageContent) {
    try {
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${this.context.speech.provider.voice_id}/stream`,
        {
          text: messageContent,
          model_id: "eleven_turbo_v2",
          voice_settings: {
            stability: 0.5,
            similarity_boost: 0.5,
          },
        },
        {
          headers: {
            accept: "audio/mpeg",
            "xi-api-key": process.env["REACT_APP_ELEVEN_LABS_API_KEY"],
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer", // Important for audio response
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "audio/mpeg" });
        await this._playAudioURL(URL.createObjectURL(blob));
        return true;
      } else {
        throw new Error("No data returned from Eleven Labs API");
      }
    } catch (e) {
      throw e;
    }
  }

  // call the api thrice to increase robustness in eleven labs api
  elevenLabsRobustnessResponse = async (messages, attempt = 1) => {
    try {
      const result = await this._speakMessageElevenLabs(messages);
      return result;
    } catch (err) {
      if (attempt < 3) {
        return await this.elevenLabsRobustnessResponse(messages, attempt + 1);
      } else {
        console.error(err,"err")
        return false;
      }
    }
  };

  async checkAudioPermission() {
    try {
     await audioCtxt.resume();
     return true; 
    } catch (error) {
      return false; 
    }
  }

  async _playAudioStream(audioStream) {
    try {
      if (!this.audioRef.current) {
        throw new Error("Audio element reference is not available.");
      }
  
      const audioUrl = window.URL.createObjectURL(
        new Blob([audioStream], { type: "audio/mp3" })
      );

      const permissionGranted = await this.checkAudioPermission();
      if (!permissionGranted) {
        return false
        // throw new Error("User denied permission for audio playback.");
      }
      
      this.audioRef.current.src = audioUrl;
      await this.audioRef.current?.play();
    } catch (error) {
      console.error("Error trying to play the audio:", error);
    }
  }
  
  async _playAudioURL(audioURL) {
    try {
      if (!this.audioRef.current) {
        throw new Error("Audio element reference is not available.");
      }
  
      const permissionGranted = await this.checkAudioPermission();
      if (!permissionGranted) {
        // return false;
        throw new Error("User denied permission for audio playback.");
      }

      this.audioRef.current.src = audioURL;
      await this.audioRef.current?.play();
    } catch (error) {
      console.error("Error trying to play the audio:", error);
    }
  }  

  _escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
  }

  _stripCodeBlocks(inputString) {
    // Regular expression to match common code block patterns
    const codeBlockRegex = /```[\s\S]*?```|`[^`\n]*`/g;

    return inputString.replace(codeBlockRegex, "");
  }

  _replaceWords(inputString) {
    const regex = new RegExp(Object.keys(WORD_CORRECTION_MAP).join("|"), "gi");
    return inputString.replace(regex, (matched) => {
      return WORD_CORRECTION_MAP[matched.toLowerCase()];
    });
  }

  _replaceMathSymbolsWithSpellings(inputString) {
    // Define a regex pattern with positive lookahead and lookbehind for numbers
    const regexPattern = `(?<=\\d)(${Object.keys(SYMBOL_TO_SPELLING_MAP)
      .map(this._escapeRegExp)
      .join("|")})(?=\\d)`;
    const regex = new RegExp(regexPattern, "g");

    return inputString.replace(regex, (match) => SYMBOL_TO_SPELLING_MAP[match]);
  }
}

export default SpeechHelper;
