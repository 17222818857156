import React from 'react';
import {Flex, View, Heading, Image} from '@aws-amplify/ui-react';

import awardsData from '../../data/awards.json';

const Awards = () => {
    const awards = Object.entries(awardsData.data).map(([key, value]) => ({ id: key, src: value.image }));

    return (
        <View
            paddingTop={'50px'}
            paddingBottom={'50px'}
        >
            <Heading level={1}>Our Awards</Heading>
            <Flex direction="row" wrap="wrap" gap={3} justifyContent={"center"} alignItems={'center'}>
                {awards.map((award) => (
                    <div key={award.id} style={{ padding: '10px', maxWidth: '200px' }}>
                        <Image src={award.src} />
                    </div>
                ))}
            </Flex>
        </View>
    );
};

export default Awards;
