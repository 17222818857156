import {Flex} from "@aws-amplify/ui-react";
import Card from "./card";

export default function LandingScreenCards() {
    const isMobile  = window.innerWidth < 768;
    return (
        <Flex
            backgroundColor={'#f4f6fc'}
            paddingLeft={'20px'}
            paddingRight={'20px'}
            wrap={isMobile ? 'wrap' : 'nowrap'}
        >
            <Card
                title={'Research-Based'}
                paragraph={"We're impact driven. All our materials adhere to best practices, founded in the latest research."}
                backgroundColor={'#44acee'}
                image={'kids-computers.png'}
                isMobile={isMobile}
            />
            <Card
                title={'Vetted by Teachers'}
                paragraph={"We work with teachers in their classrooms to guarantee ease of use and curricula teachers want."}
                backgroundColor={'#d49d24'}
                image={'teacher.png'}
                isMobile={isMobile}
            />
            <Card
                title={'Tested by Kids and Families'}
                paragraph={"Everything we create is tested by kids and families to  ensure a great experience every time."}
                backgroundColor={'#ef4b4b'}
                image={'kids-computers-2.png'}
                isMobile={isMobile}
            />
        </Flex>
    )
}