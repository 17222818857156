import { Box, Collapse } from "@mui/material";

const MobileBurgerMenu = ({ children, drawerOpen }) => {
  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999999,
        marginTop: 10,
      }}
    >
      <Collapse
        in={drawerOpen}
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            marginBottom: 10,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            {children}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default MobileBurgerMenu;
