import {Button, Flex, Text, TextField, useTheme, View, Heading} from "@aws-amplify/ui-react";
import {useContext, useState} from "react";
// import {AudioContext} from "../App";
import axios from "axios";
import AudioRecorder from "../components/audio-recorder";


export default function Problem() {
    const {tokens} = useTheme();
    const [botVisible, setBotVisible] = useState('none')
    // const {playVO} = useContext(AudioContext);

    // const [audio, setAudio] = useState(null);

    const playAudio = (audio) => {
        const audioContext = new AudioContext();
        const source = audioContext.createBufferSource();
        audioContext.decodeAudioData(audio, (buffer) => {
            source.buffer = buffer;
            source.connect(audioContext.destination);
            source.start();
        });
    };

    return (
        <>
            <AudioRecorder />
            <Flex direction={"column"} alignContent={'center'} justifyContent={'center'} textAlign={'center'}>
                <Heading level={1}>
                    What is 26-9?
                </Heading>
                <TextField
                    label={''}
                    placeholder={'Type your answer here'}
                />
                <Button>Submit</Button>
                <Button
                    onClick={async () => {
                        const response = await axios.post('https://api.elevenlabs.io/v1/text-to-speech/Gnc1ZxsZ30lRAMbjgY9h/stream', {
                            text: 'How can I help? Bark!',
                            voice_settings: {
                                stability: 0.75,
                                similarity_boost: 0.75,
                            },
                        }, {
                            headers: {
                                'accept': '*/*',
                                'xi-api-key': '365b766a090366d65d69de15f27c642f',
                                'Content-Type': 'application/json',
                            },
                            responseType: 'arraybuffer',
                        });
                        console.log(response.data)
                        // setAudio(response.data);
                        playAudio(response.data)
                        setBotVisible('block')
                    }}
                >
                    I need help
                </Button>
            </Flex>


            <View
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    zIndex: 100,
                    display: botVisible
                }}
            >
                <View style={{backgroundImage: "url(/images/thinking.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', bottom: '140px', right: '170px', width: '300px', height: '300px'}}>
                    <Text padding={tokens.space.large} marginTop={50}>How can I help? Bark!</Text>
                </View>
                <div className="dog" />
            </View>
        </>
    )
}