import axios from "axios";
import query from "./query";

export default async function fetchSubscription(username) {
    const userSubscription = (await query(username, 'subscriptions'))[0];
    if (!userSubscription) return null;

    // TODO: Make this work and replace the above
    // const userSubscriptions = await DataStore.query(UserSubscription);
    // if (userSubscriptions.length === 0)
    //     return null;

    // const userSubscription = userSubscriptions[0];

    try {
        const endpoint = `${process.env['REACT_APP_STRIPE_API_ENDPOINT']}subscription/${userSubscription.stripeSubscriptionID}`;

        return (await axios.get(endpoint)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
}