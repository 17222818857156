import config from "./config.json";
import SpeechHelper from "./SpeechHelper";
import axios from "axios";
import { getRecommendations as getRecommendationsFn } from "../../helpers/getRecommendations";
import subjects from "../../data/subjects.json";
import { DataStore } from "aws-amplify";
import { UserProfile } from "../../models";
import { removePersonaliInfo } from "../../helpers/ChatGPTHelper";

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  organization: process.env["REACT_APP_OPENAPI_ORGANIZATION"],
  apiKey: process.env["REACT_APP_OPENAPI_API_KEY"],
});
const openai = new OpenAIApi(configuration);

const DEFAULT_SENDER = "assistant";
const TYPE_ACTION = {
  video: "watching",
  game: "playing",
  activity: "interacting with",
  music: "listening to",
  printable: "viewing",
};
const RECOMMENDATION_KEYWORDS = [
  "recommend",
  "recommendation",
  "recommendations",
  "suggest",
  "suggestions",
  "best",
  "favorite",
  "top",
  "good activities",
  "should I do",
  "should I try",
  "what to try",
  "where to go",
  "things to do",
  "must do",
  "must try",
  "do you have",
  "I'd like to",
  "want to play",
  "play games",
  "play a game",
  "listen to music",
  "listen music",
  "watch videos",
  "watch a video",
  "learn math",
  "learn science",
  "learn language arts",
];

const QUESTION_INDICATORS = [
  "?",
  "what",
  "what's",
  "why's",
  "why",
  "when",
  "when's",
  "how",
  "how's",
  "who",
  "who's",
  "where",
  "where's",
  "which",
  "whose",
];

function getSpecialTimeGreeting(data) {
  const currentHour = new Date().getHours();

  for (let period in data) {
    if (Math.random() < 0.5) continue;

    const { gt, lt, message } = data[period];

    if (
      (gt === undefined || currentHour > gt) &&
      (lt === undefined || currentHour < lt)
    ) {
      return message;
    }
  }

  return null; // Default greeting if none of the conditions match
}

function getDayGreeting(data) {
  if (Math.random() < 0.5) return null;

  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const currentDay = days[new Date().getDay()];

  const greetingsForToday = data[currentDay];
  if (greetingsForToday && greetingsForToday.length > 0) {
    const randomIndex = Math.floor(Math.random() * greetingsForToday.length);
    return greetingsForToday[randomIndex];
  }

  return null; // Default greeting if none of the conditions match
}

class Assistant {
  constructor(
    selectedGrade,
    videoRef,
    audioRef,
    context,
    sender = DEFAULT_SENDER,
    activities = [],
    currentlyViewingActivity = null,
    isPlayground = false,
    isViewingActivity = null
  ) {
    this.context = context;
    this.speechHelper = new SpeechHelper(audioRef, context, config.speech);
    this.activities = activities;
    this.currentlyViewingActivity = currentlyViewingActivity;
    this.isViewingActivity = isViewingActivity;

    this.greeting =
      context.technical.greetings[
        Math.floor(Math.random() * context.technical.greetings.length)
      ];

    const specialTimeGreeting = getSpecialTimeGreeting(
      context.technical.special_greetings.time
    );
    if (specialTimeGreeting) {
      this.greeting = `${specialTimeGreeting} ${this.greeting}`;
    } else {
      const specialDayGreeting = getDayGreeting(
        context.technical.special_greetings.day
      );
      if (specialDayGreeting)
        this.greeting = `${specialDayGreeting} ${this.greeting}`;
    }

    if (
      config.greeting_additions.messages.length > 0 &&
      Math.random() < config.greeting_additions.chance
    ) {
      const randomIndex = Math.floor(
        Math.random() * config.greeting_additions.messages.length
      );
      this.greeting = `${this.greeting} \n\n${config.greeting_additions.messages[randomIndex]}`;
    }

    // TODO: Add something based on a holiday
    // TODO: Add something based on a previous activities
    // TODO: Add something based on a previous interaction

    if (currentlyViewingActivity) {
      if (
        currentlyViewingActivity.hasOwnProperty("ai") &&
        currentlyViewingActivity.ai.hasOwnProperty("greetings")
      )
        this.greeting =
          currentlyViewingActivity.ai.greetings[
            Math.floor(
              Math.random() * currentlyViewingActivity.ai.greetings.length
            )
          ];
      else
        this.greeting = `Looks like you're ${
          TYPE_ACTION[currentlyViewingActivity.type]
        } ${
          currentlyViewingActivity.name
        }. That's a good one! Let me know how I can help!`;
    } else {
      this.greeting = isPlayground ? this.greeting: `${this.greeting} \n\nYou can [explore activities on your own](https://www.teachmetv.co/kids-zone?grade=${selectedGrade}), or work on your learning skills Or, play a game. It's your choice.`;
    }

    this.conversations = [
      {
        text: this.greeting,
        sender: sender,
        name: context.biography.call_me,
      },
      {
        text: this._buildRules(),
        sender: "system",
        name: "You",
        hidden: true,
      },
    ];
  }

  setUp = async (userId, name, grade, avatarId) => {
    // if (this.isStreamingEnabled())
    //     this.streamHelper.connect().catch(reason => console.error(reason));

    await this._addPersonalInfoToConversation(userId, name, grade, avatarId);

    await openai.createChatCompletion({
      ...config.api,
      messages: this.conversations.map((msg) => {
        return {
          role: msg.sender,
          content: msg.text,
        };
      }),
    });
  };

  getRecommendationKeywords = () => RECOMMENDATION_KEYWORDS;
  getConversations = () => this.conversations;
  setMessages = (messages) => (this.conversations = messages);
  isAskingForRecommendation = (message) => {
    const words = message
      .replace(/[.?!,;]*/g, "")
      .toLowerCase()
      .split(" ");
    return this.getRecommendationKeywords().some((keyword) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      return words.some((word) => word === lowerCaseKeyword);
    });
  };

  // added the function to get the response from chatGPT API if it fails to get the response from the API thrice
  chatGPTResponse = async (messages, attempt = 1) => {
    try {
      const chatMessage = messages.map((msg) => {
        return {
          role: msg.sender,
          content: removePersonaliInfo(msg.text),
        };
      });
      const result =  await openai.createChatCompletion({
        ...config.api,
        messages:chatMessage,
      });
      return result;
    } catch (err) {
        console.error(err);
      if (attempt < 3) {
        return await this.chatGPTResponse(messages, attempt + 1);
      } else {
        const result = {
            data: {
              choices: [
                {
                  message: {
                    content:
                      "I'm having a little trouble understanding right now, try again later."
                  },
                },
              ],
            },
          }
        return result;
      }
    }
  };

  // TODO: This seems poorly named
  addMessagesToConversation = async (messages = []) => {
    this.conversations = [...this.conversations, ...messages];

    // Don't generate a new answer if it's not a recommendation
    // This saves time and money by not querying ChatGPT again.
    const lastMessage = messages[messages.length - 1];
    const conversationId = this._generateConversionId(lastMessage.text);
    const isARecommendation = this.isAskingForRecommendation(lastMessage.text);
    if (!isARecommendation) {
      const endpoint = `${process.env["REACT_APP_DATA_EXPLORER_ENDPOINT"]}/query/prompts/${conversationId}`;
      try {
        const response = await axios.get(endpoint);
        if (response.data && !response.data.hasOwnProperty("error"))
          return response.data;
      } catch (e) {
        // It doesn't exist, keep going
      }
    }

    const conversationsToSend = JSON.parse(JSON.stringify(this.conversations));
    if (this._isMathQuestion(lastMessage.text))
      conversationsToSend[conversationsToSend.length - 1].text +=
        config.pre_answer_rules["math"].join(" ");

    //added the function to get the response from chatGPT API if it fails to get the response from the API thrice
    const openaiResponse = await this.chatGPTResponse(conversationsToSend);

    let newMessage = openaiResponse.data.choices[0].message.content;

    // If it's not a recommendation and it reached this point, save it to the database
    const isAQuestion = QUESTION_INDICATORS.some((indicator) =>
      lastMessage.text.includes(indicator)
    );
    if (
      !isARecommendation &&
      isAQuestion &&
      lastMessage.text.split(" ").length > 2 &&
      config.caching.chatGPT
    ) {
      axios
        .post(`${process.env["REACT_APP_DATA_EXPLORER_ENDPOINT"]}/prompts`, {
          id: conversationId,
          prompt: lastMessage.text,
          answer: openaiResponse.data.choices[0].message.content,
          data: openaiResponse.data,
          createdAt: Date.now(),
        })
        .then((_) => {}); // Fire and forget
    }

    return newMessage;
  };

  findRecommendationsToAddToMessage = (message) =>
    this.currentlyViewingActivity
      ? []
      : this._findObjectsWithKeyword(this.activities, message);

  speakGreeting = (text) => this.speak(text || this.greeting);
  speak = (message) => this.speechHelper.speak(message);
  talk = async (messageToSpeak, context, previousMessage) =>
    await axios.post(`${process.env["REACT_APP_DID_API_ENDPOINT"]}/talk`, {
      message: messageToSpeak,
      context: context,
      promptId: this._generateConversionId(previousMessage),
      prompt: previousMessage,
    });

  isStreamingEnabled = () => {
    // check env variable first
    if (process.env.hasOwnProperty("REACT_APP_STREAMING_ENABLED"))
      return process.env["REACT_APP_STREAMING_ENABLED"] === "true";

    return config.streaming.active;
  };

  getRecommendations = async (
    user,
    grade,
    emotion,
    message,
    subjectsToExclude = []
  ) => {
    if (this.currentlyViewingActivity)
      // Don't recommend activity when already viewing activity
      return [];

    const subject = this._getSubjectFromMessage(message);
    const typeRecommendations = this._findObjectsWithType(
      this.activities,
      message
    );

    const emotionRecommendations =
      typeRecommendations.length < 3
        ? await this._getRecommendationsBasedOnEmotion(
            user,
            grade,
            emotion,
            subject ? [subject] : [],
            subjectsToExclude
          )
        : [];

    const keywordRecommendations =
      typeRecommendations.length + emotionRecommendations.length < 3
        ? this._findObjectsWithKeyword(this.activities, message)
        : [];

    const recommendations = [
      ...typeRecommendations,
      ...emotionRecommendations,
      ...keywordRecommendations,
    ];

    // Remove currently viewing activity from recommended list
    if (this.currentlyViewingActivity)
      recommendations.filter(
        (activity) => activity.id === this.currentlyViewingActivity.id
      );

    // Do we know their strengths and weaknesses?
    // Do we know what they want to focus on?
    // What day of the week are we?
    // Is there a holiday today?

    this._shuffleArray(recommendations);

    return recommendations.slice(0, 3);
  };

  _getSubjectFromMessage = (message) => {
    const subjectsArray = Object.keys(subjects.data).map((subject) =>
      subject.toLowerCase()
    );
    const matchedSubject = subjectsArray.find((subject) =>
      message.toLowerCase().includes(subject)
    );

    return matchedSubject || null;
  };

  _getRecommendationsBasedOnEmotion = async (
    user,
    grade,
    emotion,
    subjectsToInclude,
    subjectsToExclude
  ) =>
    !emotion
      ? []
      : await getRecommendationsFn(
          user,
          grade,
          emotion,
          3,
          subjectsToInclude,
          subjectsToExclude
        );

  _buildRules() {
    const bio = this.context.biography;
    const rules = [...config.rules];

    // added a conditional rule to address the activity when the user interacts with the chatbot
    // first one is to check if the variable is not null
    // second one is to check whether you are in the activity, (it is a callback from the chat container this component is being called).
    if (this.isViewingActivity && this.isViewingActivity()) {
      rules.push(`If the user asks a question about the current activity, please mention that the user is currently interacting with "${this.currentlyViewingActivity.name}"`)
    }

    if (bio.hasOwnProperty("answers")) {
      const answers = bio.answers;
      if (answers.hasOwnProperty("tone"))
        rules.push(`Use this tone when answering: ${answers.tone}`);
      if (answers.hasOwnProperty("voice_of"))
        rules.push(`Use ${answers.voice_of}'s style when answering question.`);
    }

    rules.push(
      `Going forward, you are ${bio.name}. When asked for your name, you say ${bio.call_me}.`
    );
    if (bio.hasOwnProperty("birthday"))
      rules.push(`Your birthday is on ${bio.birthday}.`);
    if (bio.hasOwnProperty("age")) rules.push(`Your age is on ${bio.age}.`);
    if (bio.hasOwnProperty("grade"))
      rules.push(`Your grade is on ${bio.grade}.`);
    if (bio.hasOwnProperty("eye_color"))
      rules.push(`Your eyes are ${bio.eye_color}.`);
    if (bio.hasOwnProperty("hair color"))
      rules.push(`Your hair color is ${bio.hair_color}).`);
    if (bio.hasOwnProperty("body")) rules.push(`Your body is ${bio.body}.`);
    if (bio.hasOwnProperty("other_physical_features"))
      rules.push(
        `Here are your other physical features: ${bio.other_physical_features.join(
          ", "
        )}.`
      );
    if (bio.hasOwnProperty("workout_routine"))
      rules.push(`Your workout routine is: ${bio.workout_routine.join(", ")}.`);
    if (bio.hasOwnProperty("country"))
      rules.push(`Your home country is ${bio.country}.`);
    if (bio.hasOwnProperty("spouse"))
      rules.push(`Your partner is ${bio.spouse}.`);
    if (bio.hasOwnProperty("children"))
      rules.push(`Your children are: ${bio.children.join(", ")}.`);
    if (bio.hasOwnProperty("languages"))
      rules.push(`Your languages are: ${bio.languages.join(", ")}.`);
    if (bio.hasOwnProperty("hobbies"))
      rules.push(`Your hobbies are: ${bio.hobbies.join(", ")}.`);
    if (bio.hasOwnProperty("creations"))
      rules.push(`You are the creator of ${bio.creations.join(", ")}. `);
    if (bio.hasOwnProperty("professions"))
      rules.push(`You are a ${bio.professions.join(", ")}.`);
    if (bio.hasOwnProperty("skills"))
      rules.push(`You are your skills: ${bio.skills.join(", ")}.`);
    if (bio.hasOwnProperty("education"))
      rules.push(`Here are your degrees: ${bio.education.join(", ")}.`);
    if (bio.hasOwnProperty("fun_facts"))
      rules.push(`Here are fun facts about you: ${bio.fun_facts.join(", ")}.`);
    if (bio.hasOwnProperty("linkedin_bio"))
      rules.push(`Here is your LinkedIn bio: ${bio.linkedin_bio}.`);

    return rules.join(" ");
  }

  _addPersonalInfoToConversation = async (userId, name, grade, avatarId) => {
    if (!userId || !name || !grade) return;

    const preferences = await DataStore.query(
      UserProfile,
      (userProfile) =>
        userProfile.userId("eq", `${userId}`) &&
        userProfile.avatarId("eq", avatarId)
    );
    this.conversations.push({
      text: `My name is ${name} and I'm in grade ${grade}. ${preferences
        .map((p) => p.sentence)
        .join(". ")}`,
      sender: "user",
      name: "You",
      hidden: true,
    });
  };

  _shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  }

  _isMathQuestion = (message) => {
    // Regular expressions for common math terms and symbols
    const patterns = [
      /\d+ \+ \d+/, // Addition symbol
      /\d+ \- \d+/, // Subtraction symbol
      /\d+ \* \d+/, // Multiplication symbol
      /\d+ \/ \d+/, // Division symbol
      /sqrt/, // Square root
      /cube root/, // Cube root
      /power of/, // Power of
      /logarithm/, // Logarithm
      /integral/, // Calculus
      /derivative/, // Calculus
      /\^/, // Exponentiation
      /factorial/, // Factorial
      /sin|cos|tan/, // Trigonometry
      /angle/, // Geometry
      /area/, // Geometry
      /perimeter/, // Geometry
      /solve for/, // Algebra
      /equation/, // General math problems
      /sum/, // Summation
      /\bpi\b/, // Pi
      /addition/, // Addition term
      /subtraction/, // Subtraction term
      /division/, // Division term
      /multiplication/, // Multiplication term
      /fractions?/, // Fraction or Fractions
    ];

    for (let pattern of patterns) {
      if (pattern.test(message.toLowerCase())) return true;
    }

    return false;
  };

  _generateConversionId = (prompt) =>
    Buffer.from(
      JSON.stringify({
        prompt: prompt,
        avatarId: this.context.id,
      })
    ).toString("base64");

  _findObjectsWithKeyword = (
    objectsArray,
    searchString,
    maxNumberObjects = 3
  ) => {
    let matchingObjects = [];
    let count = 0;

    for (let obj of objectsArray) {
      if (count >= maxNumberObjects) {
        break; // Exit if we've reached the max number of matching objects
      }

      for (let keyword of obj.keywords) {
        if (
          searchString &&
          keyword &&
          searchString.toLowerCase().includes(` ${keyword.toLowerCase()} `)
        ) {
          matchingObjects.push(obj);
          count++;
          break; // Exit the inner loop to avoid pushing the same object multiple times
        }
      }
    }

    return matchingObjects;
  };

  _findObjectsWithType = (objectsArray, searchString, maxNumberObjects = 3) => {
    const array = [...objectsArray];
    this._shuffleArray(array);
    let matchingObjects = [];
    let count = 0;

    for (let obj of array) {
      if (count >= maxNumberObjects) {
        break; // Exit if we've reached the max number of matching objects
      }

      if (
        searchString &&
        searchString.toLowerCase().includes(` ${obj.type.toLowerCase()}`)
      ) {
        matchingObjects.push(obj);
        count++;
      }
    }

    return matchingObjects;
  };
}

export default Assistant;
