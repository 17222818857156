import {Button, Flex, Heading, Text, TextField, useTheme, View} from "@aws-amplify/ui-react";
import axios from "axios";
import {useState} from "react";

export function EmailSignUp() {
    const {tokens} = useTheme();
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    return (
        <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} margin={'auto auto'}
              gap={tokens.space.xxxl} wrap={'wrap'}>
            <View>
                <Heading level={3}>Don't miss a thing!</Heading>
                <Text>Sign up with your email to receive news and updates right to your inbox.</Text>
            </View>
            <View>
                <Flex alignItems={'flex-end'} justifyContent={'center'}>
                    {
                        !emailSent &&
                        <>
                            <TextField
                                label={''}
                                labelHidden={true}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type={'email'}
                                placeholder={'Email Address'}
                                backgroundColor={'white'}
                            />
                            <Button
                                variation='primary'
                                onClick={async () => {
                                    try {
                                        await axios.post(`${process.env['REACT_APP_EMAIL_SERVICE_URL']}/contact/add`, {
                                            email: email
                                        });

                                        setEmailSent(true);
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                            >
                                Sign Up
                            </Button>
                        </>
                    }
                    {
                        emailSent &&
                        <Text>Thank you!</Text>
                    }
                </Flex>
            </View>
        </Flex>
    );
}