import { Grid, useTheme, View } from "@aws-amplify/ui-react";
import { BackButton, FlexCentered, FlexCenteredColumn, MuliHeading, SectionHeading } from "../../themed";
import SearchBar from "../../components/search-bar";
import { TipGiver } from "./tip-giver";
import { Selectors } from "./selectors";

import subjectsDict from "../../data/subjects.json";
import { GRADE_HEADINGS } from "../../constants";
import { AudioContext } from "../../App";
import { useContext } from "react";

export default function KidsZoneSubjects({ isMobile, activities, setSelectedGrade, setSelectedSubject, setSearchTerm, grade }) {
    const { tokens } = useTheme();
    const { playVO } = useContext(AudioContext);

    const handleBackButton = () => {
        setSelectedGrade(null);
        playVO("");
    };

    return (
        <>
            <FlexCenteredColumn>
                <Grid templateColumns={"1fr 5fr 1fr"} width={"100%"} alignItems={"center"}>
                    <View>
                        <BackButton onClickHandler={() => handleBackButton()} />
                    </View>
                    <SectionHeading>{GRADE_HEADINGS[grade]}</SectionHeading>
                </Grid>

                <MuliHeading level={2}>Choose a subject</MuliHeading>
                <Selectors setSelectorFn={setSelectedSubject} sourceDict={subjectsDict} />
                <FlexCentered marginTop={tokens.space.large}>
                    <SearchBar width={"30vw"} isMobile={isMobile} activities={activities.data} setSearchTerm={setSearchTerm} />
                    <TipGiver isMobile={isMobile} />
                </FlexCentered>
            </FlexCenteredColumn>
        </>
    );
}
