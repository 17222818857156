import {Divider, Heading, Text, View} from "@aws-amplify/ui-react";
import {useEffect} from "react";

export default function TermsScreen() {
    useEffect(() => {
        document.title = "Terms and Conditions - TeachMe TV";
    }, []);

    return (
        <View>
            <Heading level={1}>Terms and Conditions</Heading>
            <Divider/>
            <View
                padding={20}
                textAlign='left'
                margin={'auto auto'}
                maxWidth={'800px'}
            >
                <ol>
                    <li style={{marginBottom: '13px'}}><Text><strong>Trademarks.</strong> Certain trademarks, trade
                        names, service marks and logos used or displayed on this Web Site are registered and
                        unregistered trademarks, trade names and service marks of TeachMe TV and its affiliates. Other
                        trademarks, trade names and service marks used or displayed on this Web Site are the registered
                        and unregistered trademarks, trade names and service marks of their respective owners, including
                        TeachMe TV and its affiliates. Nothing contained on this Web Site grants or should be construed
                        as granting, by implication, estoppel, or otherwise, any license or right to use any trademarks,
                        trade names, service marks or logos displayed on this Web Site without the written permission of
                        TeachMe TV or such third-party owner.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>Use of this Web Site.</strong> The Web Site design
                        and all text, graphics, information, content, and other material displayed on or that can be
                        downloaded from this Web Site are either the property of, or used with permission by TeachMe TV
                        and are protected by copyright, trademark and other laws and may not be used except as permitted
                        in these Terms and Conditions or with the prior written permission of the owner of such
                        material. You may not modify the information or materials located on this Web Site in any way or
                        reproduce or publicly display, perform, or distribute or otherwise use any such materials for
                        any public or commercial purpose. Any unauthorized use of any such information or materials may
                        violate copyright laws, trademark laws, laws of privacy and publicity, and other laws and
                        regulations.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>Linking to this Web Site.</strong> Creating or
                        maintaining any link from another Web site to any page on this Web Site without TeachMe TV’s
                        prior written permission is prohibited. Running or displaying this Web Site or any material
                        displayed on this Web Site in frames or through similar means on another Web site without
                        TeachMe TV’s prior written permission is prohibited. Any permitted links to this Web Site must
                        comply with all applicable laws, rules and regulations.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>Inappropriate Material.</strong> You are prohibited
                        from posting or transmitting any unlawful, threatening, defamatory, libelous, obscene,
                        pornographic or profane material or any material that could constitute or encourage conduct that
                        would be considered a criminal offense or give rise to civil liability, or otherwise violate any
                        law. In addition to any remedies that TeachMe TV may have at law or in equity, if TeachMe TV
                        reasonably determines that you have violated or are likely to violate the foregoing
                        prohibitions, TeachMe TV may take any action they reasonably deem necessary to cure or prevent
                        the violation, including without limitation, the immediate removal from this Web Site of the
                        related materials. TeachMe TV will fully cooperate with any law enforcement authorities or court
                        order or subpoena requesting or directing TeachMe TV to disclose the identity of anyone posting
                        such materials.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>User Information.</strong> Other than personally
                        identifiable information, which is subject to this Web Site's Privacy Policy, any material,
                        information, suggestions, ideas, concepts, know-how, techniques, questions, comments or other
                        communication you transmit or post to this Web Site in any manner ("User Communications") is and
                        will be considered non-confidential and non-proprietary. TeachMe TV, each of its affiliates
                        and/or their designees may use any or all User Communications for any purpose whatsoever,
                        including, without limitation, reproduction, transmission, disclosure, publication, broadcast,
                        development, manufacturing and/or marketing in any manner whatsoever for any or all commercial
                        or non-commercial purposes. TeachMe TV may, but is not obligated to, monitor or review any User
                        Communications. TeachMe TV shall have no obligations to use, return, review, or respond to any
                        User Communications. TeachMe TV will have no liability related to the content of any such User
                        Communications, whether or not arising under the laws of copyright, libel, privacy, obscenity,
                        or otherwise. TeachMe TV retains the right to remove any or all User Communications that
                        includes any material TeachMe TV deems inappropriate or unacceptable. TeachMe TV does not sell
                        user information to third parties nor does it collect data on its users other than name and
                        email. This data is used solely to improve user experience and to create an
                        overall-more-inclusive experience for our user community. </Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>DISCLAIMERS.</strong><p> YOUR USE OF THIS SITE IS
                        AT YOUR RISK. THE MATERIALS AND SERVICES PROVIDED IN CONNECTION WITH THIS WEB SITE ARE PROVIDED
                        "AS IS" WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                        A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. NEITHER PBS KIDS SHOP NOR
                        ANY OF THEIR AFFILIATES WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR SERVICES ON OR
                        THROUGH THIS WEB SITE. THE MATERIALS AND SERVICES ON OR THROUGH THIS WEB SITE MAY BE OUT OF
                        DATE, AND NEITHER PBS KIDS Shop NOR ANY OF THEIR AFFILIATES MAKE ANY COMMITMENT OR ASSUMES ANY
                        DUTY TO UPDATE SUCH MATERIALS OR SERVICES. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO NOT
                        APPLY TO THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH
                        PROHIBITIONS.</p><p>IN THE EVENT OF ANY PROBLEM WITH THIS WEB SITE OR ANY CONTENT, YOU AGREE
                        THAT YOUR SOLE REMEDY IS TO CEASE USING THIS WEB SITE. IN THE EVENT OF ANY PROBLEM WITH THE
                        PRODUCTS OR SERVICES THAT YOU HAVE PURCHASED ON OR THROUGH THIS WEB SITE, YOU AGREE THAT YOUR
                        REMEDY, IF ANY, IS FROM THE MANUFACTURER OF SUCH PRODUCTS OR SUPPLIER OF SUCH SERVICES, IN
                        ACCORDANCE WITH SUCH MANUFACTURER'S OR SUPPLIER'S WARRANTY, OR TO SEEK A RETURN AND REFUND FOR
                        SUCH PRODUCT OR SERVICES IN ACCORDANCE WITH THE RETURNS AND REFUNDS POLICIES POSTED ON THIS WEB
                        SITE.</p></Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>LIMITATIONS OF LIABILITY.</strong> TeachMe TV does
                        not assume any responsibility, and shall not be liable for, any damages to, or viruses that may
                        infect, your computer, telecommunication equipment, or other property caused by or arising from
                        your access to, use of, or browsing this Web Site or your downloading of any materials, from
                        this Web Site. IN NO EVENT WILL PBS KIDS Shop, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
                        SHAREHOLDERS, AFFILIATES, AGENTS, SUCCESSORS, ASSIGNS, RETAIL PARTNERS NOR ANY PARTY INVOLVED IN
                        THE CREATION, PRODUCTION OR TRANSMISSION OF THIS WEB SITE BE LIABLE TO ANY PARTY FOR ANY
                        INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION,
                        THOSE RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE,
                        INABILITY TO USE, OR THE RESULTS OF USE OF THIS WEB SITE, ANY WEB SITES LINKED TO THIS WEB SITE,
                        OR THE MATERIALS, INFORMATION OR SERVICES CONTAINED AT ANY OR ALL SUCH SITES, WHETHER BASED ON
                        WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY
                        OF SUCH DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY DO NOT APPLY TO THE EXTENT PROHIBITED BY
                        LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH PROHIBITIONS.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>Revisions to these Terms and
                        Conditions.</strong> TeachMe TV may revise these Terms and Conditions at any time by updating
                        this posting. You should visit this page from time to time to review the then-current Terms and
                        Conditions because they are binding on you. Certain provisions of these Terms and Conditions may
                        be superseded by expressly designated legal notices or terms located on particular pages at this
                        Web Site.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>Termination.</strong> You or we may suspend or
                        terminate your account or your use of this Web Site at any time, for any reason or for no
                        reason. You are personally liable for any orders that you place or charges that you incur prior
                        to termination. We reserve the right to change, suspend, or discontinue all or any aspect of
                        this Web Site at any time without notice.</Text></li>
                    <li style={{marginBottom: '13px'}}><Text><strong>Additional Assistance.</strong> If you do not
                        understand any of the foregoing Terms and Conditions or if you have any questions or comments,
                        we invite you to email our <a href="mailto:info@teachmetv.co">customer service
                            department</a>.</Text></li>
                </ol>
            </View>
        </View>
    )
}