// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { UserActivity, UserSubscription, Talk, User, UserProfile, Prompt } = initSchema(schema);

export {
  UserActivity,
  UserSubscription,
  Talk,
  User,
  UserProfile,
  Prompt
};