import React, {useCallback} from 'react';
import AudioRecorder from "../audio-recorder";
import EmojiTextCards from "../EmojiTextCards";
import {CONTINUE_ACTIVITIES} from "../../constants";
import DialogueScene from "./DialogueScene";
import EmotionCardsContainer from "../EmotionCardsContainer";


function CheckStartActivities({video, setStartActivities, isVoiceControlled, sampleRate}) {
    const setStartActivitiesBySpeech = useCallback((text) => {
        if (text.includes('yes') || text.includes('yup') || text.includes('yeah')  || text.includes('yep')) setStartActivities(true)
        else if (text.includes('no') || text.includes('nope') || text.includes('nah')) setStartActivities(false)
    }, [setStartActivities])

    const _setStartActivities = (text) => {
        setStartActivities(text === 'yes')
    }

    return (
        <div style={{height: '100%', position: 'relative'}}>
            {isVoiceControlled && <AudioRecorder callbackFn={setStartActivitiesBySpeech} sampleRate={sampleRate} />}
            <EmotionCardsContainer>
                <EmojiTextCards data={CONTINUE_ACTIVITIES} setSelectedEmoji={_setStartActivities}/>
            </EmotionCardsContainer>

            <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                <DialogueScene
                    dialgueVideo={video}
                    isWithBackButton={false}
                    videoPos={'right'}
                />
            </div>
        </div>
    );
}

export default CheckStartActivities;