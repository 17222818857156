import React, {useState} from 'react';
import {Heading, SelectField, TextAreaField, View, TextField, Flex, Button} from "@aws-amplify/ui-react";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

function SourcesField({sourceIndex, info, infos, setInfos, isLoadingInfo, setIsLoadingInfo}) {
    const getInfoFromURL = (src) => {
        const url = new URL(src)
        const name = url.host
        let id
        if (name === 'simmer.io') id = url.pathname.split('/')[2]
        else id = url.pathname.split('/')[1]
        return {name: name, id: id}
    }

    const getInfoFromIframe = (code) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(code, 'text/html');
        const iframe = doc.querySelector('iframe');
        const src = iframe.getAttribute('src');
        return getInfoFromURL(src)
    }

    const isURL = (str) => {
        try {
            new URL(str)
            return true
        } catch (_) {
            return false
        }
    }

    const getPlatformFromDomain = (url) => {
        switch (url) {
            case 'view.genial.ly': return 'genially';
            case 'vimeo.com': return 'vimeo';
            case 'simmer.io': return 'simmer';
            default: return '';
        }
    }

    // on paste to iframe field, get the platform name and id from the iframe or url.
    // return: {platform: '', platformID: ''}
    const onPaste = (e, index) => {
        setIsLoadingInfo(true)

        const code = e.clipboardData.getData('text')
        let res
        if (isURL(code)) res = getInfoFromURL(code)
        else {
            try {
                res = getInfoFromIframe(code)
            } catch (_) {
                // Not URL or iframe so return
                setIsLoadingInfo(false)
                return {platform: '', platformID: ''}
            }
        }

        const platformElement = document.getElementById(`source-platform-${index}`)
        platformElement.value = getPlatformFromDomain(res.name)
        const platformInfo = {platform: getPlatformFromDomain(res.name), platformID: res.id}

        // getThumbnail(`https://${res.name}/${res.id}`, `${res.name}_${res.id}`).then(location => {
        //     setThumbnail(location)
        // })
        setIsLoadingInfo(false)
        return platformInfo
    }

    return (
        <View>
            <Flex justifyContent="space-between">
                <Heading level={2} style={{ fontSize: '1rem' }}>Source {sourceIndex + 1}</Heading>
                <Button onClick={() => {
                    const tmp = [...infos].filter(i => i.platformID !== info.platformID)
                    setInfos(tmp)
                }}>
                    Remove source
                </Button>
            </Flex>

           {/*iframe*/}
            <TextAreaField
                autoComplete="off"
                label="(Optional) Paste iframe code (Genially) or URL (Vimeo, Simmer) to automatically detect Platform and Platform ID"
                name="iframe"
                placeholder={'<div style=\"width: 100%... or vimeo.com/video/65...'}
                rows="3"
                size="small"
                value={info.code}
                onChange={e => {
                    const tmp = [...infos]
                    tmp[sourceIndex].code = e.target.value
                    setInfos(tmp)
                }}
                onPaste={e => {
                    const newPlatform = onPaste(e, sourceIndex)
                    if (newPlatform.platform !== '' || newPlatform.platformID !== '') {
                        const tmp = [...infos]
                        tmp[sourceIndex] = newPlatform
                        setInfos(tmp)
                    }
                }}
            />

            {/*Platform*/}
            <View style={{width: '100%'}}>
                <SelectField
                    id={`source-platform-${sourceIndex}`}
                    label="Platform*"
                    isRequired
                    isDisabled={isLoadingInfo}
                    placeholder={isLoadingInfo && 'Computing from iframe...'}
                    defaultValue={info.platform}
                    onChange={e => {
                        const tmp = [...infos]
                        tmp[sourceIndex].platform = e.target.value
                        setInfos(tmp)
                    }}
                    icon={isLoadingInfo && <AiOutlineLoading3Quarters className={'loading'} />}
                >
                    <option value="genially">Genially</option>
                    <option value="batterypop">Battery Pop</option>
                    <option value="music">Music</option>
                    <option value="simmer">Simmer</option>
                    <option value="s3">S3</option>
                    <option value="Squarespace">Squarespace</option>
                    <option value="vimeo">Vimeo</option>
                </SelectField>
            </View>

            {/*Platform ID*/}
            <View style={{width: '100%'}}>
                <TextField
                    id={`source-platformID-${sourceIndex}`}
                    label={'Platform ID*'}
                    isRequired
                    value={info.platformID}
                    onChange={e => {
                        const tmp = [...infos]
                        tmp[sourceIndex].platformID = e.target.value
                        setInfos(tmp)
                    }}
                    fullWidth
                    isDisabled={isLoadingInfo}
                    placeholder={isLoadingInfo && 'Computing from iframe...'}
                    innerEndComponent={isLoadingInfo &&
                        <Flex justifyContent={'center'} alignItems={'center'} height={'100%'} paddingRight={'1rem'}>
                            <AiOutlineLoading3Quarters className={'loading'} />
                        </Flex>
                    }
                />
            </View>
        </View>
    );
}

export default SourcesField;