import React, { useContext } from "react";
import { Flex, Heading, View } from "@aws-amplify/ui-react";
import styled from "styled-components";
import { AudioContext } from "../App";

const Card = ({ backgroundColor, textColor, label, grade, setSelectedGrade }) => {
    const StyledView = styled(View)`
        width: 50%;
        height: 33.33%;
        position: relative;
        cursor: pointer;
        padding: 0;
        margin: 0;
        background-color: ${backgroundColor};
    `;

    const StyledHeading = styled(Heading)`
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: muli, sans-serif;
        color: ${textColor};
        @media only screen and (max-width: 769px) {
            font-size: 1.5em !important;
            overflow-wrap: break-word;
        }
    `;
    const { playVO } = useContext(AudioContext);

    const handleGradeSelection = () => {
        setSelectedGrade(grade);
        playVO("");
    };

    return (
        <StyledView onClick={() => handleGradeSelection()}>
            <StyledHeading level={2}>{label}</StyledHeading>
        </StyledView>
    );
};

function GradesCards({ setSelectedGrade }) {
    return (
        <Flex width={"100%"} height={"100%"} wrap={"wrap"} gap={0}>
            <Card label={"Kindergarten"} backgroundColor={"#44acee"} textColor={"white"} grade={"K"} setSelectedGrade={setSelectedGrade} />
            <Card label={"Grade 1"} backgroundColor={"#ed2024"} textColor={"white"} grade={"1"} setSelectedGrade={setSelectedGrade} />
            <Card label={"Grade 2"} backgroundColor={"#ef615b"} textColor={"white"} grade={"2"} setSelectedGrade={setSelectedGrade} />
            <Card label={"Grade 3"} backgroundColor={"#ffa53b"} textColor={"#6422b8"} grade={"3"} setSelectedGrade={setSelectedGrade} />
            <Card label={"Grade 4"} backgroundColor={"#6422b8"} textColor={"white"} grade={"4"} setSelectedGrade={setSelectedGrade} />
            <Card label={"Grade 5 & 6"} backgroundColor={"#ffd93a"} textColor={"#6422b8"} grade={"5"} setSelectedGrade={setSelectedGrade} />
        </Flex>
    );
}

export default GradesCards;
