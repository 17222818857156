import {Flex, Image, Text, useTheme} from "@aws-amplify/ui-react";
import Vimeo from "@u-wave/react-vimeo";
import {LandingButton} from "../../themed";

export default function LandingScreenKidsTeachKids({plansRef, isMobile}) {
    const {tokens} = useTheme();

    return (<Flex
            backgroundColor={'#ebebec'}
            paddingTop={tokens.space.xxl}
            paddingBottom={tokens.space.xxl}
            wrap={'wrap'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Flex
                direction={'column'}
                alignItems={'center'}
                width={isMobile ? '100%' : 800}
            >
                <Flex>
                    <Image alt={'Video Icon'} src={'/images/landing/icon-video.svg'} width={'11vw'} height={'11vw'}
                           minWidth={60} minHeight={60}/>
                    <Image alt={'Title with brush stroke '} src={'/images/landing/brush-stroke-ktk.png'}
                           width={isMobile ? '60vw' : '35vw'} height={'15vw'} objectFit={'contain'}/>
                </Flex>
                <Flex
                    direction={'column'}
                    paddingBottom={tokens.space.large}
                >
                    <Text>Meet Andy. He's a competitive dancer who loves to share his love of dance.  With Andy, kids learn how to tap dance by practicing a paradiddle. </Text>
                    <Text>Peer-to-peer learning is often associated with positive academic outcomes. One thing we know
                        for sure is, kids LOVE watching other kids! Our TeachMe TV kids are passionate about so many
                        things: science, preventing climate change, martial arts, dance, acting and singing. Let your
                        kids be inspired. We are! </Text>
                </Flex>
                <LandingButton text={'Get Started'} plansRef={plansRef}/>
            </Flex>
            <Vimeo video="906903677" width={300} height={600}/>
        </Flex>)
}