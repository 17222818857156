import React, {useCallback, useEffect} from 'react';
import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";
import {FaPlay} from 'react-icons/fa'
import {FaPause} from 'react-icons/fa'
import ListeningIcon from "../ListeningIcon";

const DialogueBoxContainer = styled(View)`
  margin: 0 2rem 1rem;
  width: calc(100% - 4rem);
  position: absolute;
  bottom: 0;
  z-index: 10;
`

const TagsContainer = styled(View)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const NameField = styled(View)`
  background-color: #F6931E;
  font-size: 1rem;
  width: fit-content;
  padding: 0.5rem 2rem;
  font-weight: bold;
`

const AutoPlayButton = styled.button`
  width: fit-content;
  padding: 0.5rem 2rem;
  border: 2px solid #F7941D;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  font-weight: 700;
  margin-bottom: -2px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`

const TextField = styled(View)`
  border: 2px solid #F6931E;
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(4px);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media only screen and (max-height: 580px) {
    padding: 1rem;
  }
`

const Text = styled.p`
  margin: 0;
`

const ControlsContainer = styled(View)`
  display: flex;
  justify-content: flex-end;
`

const ContinueButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #F6931E;
  border: none;
  width: fit-content;
  cursor: pointer;
`

const VoiceControlsContainer = styled(View)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const VoiceButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #00AEEF;
  border: none;
  width: fit-content;
  cursor: pointer;
`

const ListeningContainer = styled(View)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

function DialogueBox({
                         name,
                         dialogue,
                         videoRef,
                         onClick,
                         isAutoPlay,
                         setIsAutoPlay,
                         isVoiceControlled,
                         setIsVoiceControlled
                     }) {

    const handleVideoEnd = useCallback(() => {
        if (dialogue.delay && dialogue.delay !== -1) {
            const timer = setTimeout(() => {
                if (isAutoPlay || !dialogue.isAutoPlayEnabled) {
                    onClick()
                }
            }, dialogue.delay)
            return () => clearTimeout(timer)
        } else if (isAutoPlay) {
            onClick()
        }
    }, [isAutoPlay, onClick, dialogue])


    useEffect(() => {
        const video = videoRef.current
        if (video) {
            video.addEventListener('ended', handleVideoEnd)

            // if autoplay status changes after the video has ended, immediately call handleVideoEnd to check if it should autoplay
            if (video.ended && isAutoPlay) handleVideoEnd();

            return () => video.removeEventListener('ended', handleVideoEnd)
        }
    }, [videoRef, isAutoPlay, handleVideoEnd])

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    return (
        <DialogueBoxContainer>
            <TagsContainer>
                <NameField>{toTitleCase(name)}</NameField>
                {dialogue.isAutoPlayEnabled && (
                    <AutoPlayButton onClick={() => setIsAutoPlay(!isAutoPlay)}>
                        {isAutoPlay ? (
                            <>
                                <FaPause/>
                                Pause auto-play
                            </>
                        ) : (
                            <>
                                <FaPlay/>
                                Auto-play
                            </>
                        )}
                    </AutoPlayButton>
                )}
            </TagsContainer>

            <TextField>
                <div>
                    {dialogue.texts.map(text => <Text key={text}>{text}</Text>)}
                </div>

                <ControlsContainer>
                    {dialogue.isVoiceControlled &&
                        <VoiceControlsContainer>
                            {isVoiceControlled ? (
                                <>
                                    <VoiceButton onClick={() => setIsVoiceControlled(false)}>
                                        Choose with buttons
                                    </VoiceButton>
                                    <ListeningContainer>
                                        I'm listening...
                                        <ListeningIcon/>
                                    </ListeningContainer>
                                </>
                            ) : (
                                <VoiceButton onClick={() => setIsVoiceControlled(true)}>
                                    Choose with voice
                                </VoiceButton>
                            )}
                        </VoiceControlsContainer>
                    }
                    {dialogue.isWithContinue && <ContinueButton onClick={onClick}>Continue</ContinueButton>}
                </ControlsContainer>
            </TextField>
        </DialogueBoxContainer>
    );
}

export default DialogueBox;