import React from 'react';
import {Flex, Link} from "@aws-amplify/ui-react";

function LinkDisplay({value}) {
    function isURL(str) {
        const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{2,5})?(\/.*)?$/
        return urlPattern.test(str)
    }

    const getURL = (url) => {
        if (isURL(url)) return url
        return `${window.location.origin.toString()}/play/${url}`
    }

    return (
        <Flex direction={'column'}>
            {
                value.map(link =>
                    <Link key={link.url} href={getURL(link.url)} target={'_blank'}>
                        {link.name}
                    </Link>
                )
            }
        </Flex>
    );
}

export default LinkDisplay;