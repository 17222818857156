import {Divider, Flex, Heading, Text, View} from "@aws-amplify/ui-react";
import {useEffect} from "react";

export default function PrivacyScreen() {
    useEffect(() => {
        document.title = "Privacy Policy - TeachMe TV";
    }, []);

    return (
        <View>
            <Heading level={1}>Privacy Policy</Heading>
            <Divider/>
            <Flex
                padding={20}
                textAlign='left'
                direction={{base: 'column'}}
                margin={'auto auto'}
                gap={20}
                maxWidth={'800px'}
            >
                <Text>TeachMe TV is committed to making your and your children’s online experience safe, secure and
                    confidential to the highest standards available. Following are some relevant facts about how we
                    treat your information.</Text>
                <Text><strong>We do not share or sell data.</strong> We collect billing and shipping information (if
                    applicable) in order to process your order and deliver it to its destination. This information is
                    used only by TeachMe TV for the purposes of fulfilling your order. Personal information collected
                    through this site is used only for our internal purposes. We will not sell your personal information
                    or authorize third parties to use such information for any commercial purpose, without your prior
                    consent.</Text>
                <Text><strong>Personal Information.</strong> We do not automatically collect personal information such
                    as your name, address, telephone number or email address. Such information will be collected only if
                    you provide it to us voluntarily in order to order products, enter a contest or promotion, or sign
                    up to receive our e-Update newsletter. We don't require that any personal information be provided in
                    order to view our website unless you choose to become a member in which case, we collect your email
                    and name so that you may create a username and log in information. If you and/or your children are
                    using any of the TeachMe TV® Study Buddies, information and questions regarding math, science,
                    language arts, music, or anything pertaining to their learning and instruction will be sent to Open
                    AI. This is for learning purposes only.
                </Text>
                <View>
                    <Text>Information collected by TeachMe TV is used to:</Text>
                    <ul>
                        <li>allow you to become a member, register for e-Update and participate in special promotions;
                        </li>
                        <li>improve the site by observing products that are most popular; and</li>
                        <li>personalize the delivery of information and recommend products to you.</li>
                    </ul>
                </View>
                <Text>Your personal information may also be used to create statistics about TeachMe TV that we use to
                    measure our performance. These statistics do not contain any information that could identify you
                    personally. TeachMe TV does not sell your personal information to any organization for any
                    purpose.</Text>
                <Text><strong>We Do Send E-mail.</strong> We will send you email at regular intervals to ensure that you
                    are always kept up to date on new offerings and best practices. From time to time we send additional
                    e-flyers out to inform our customers of specials or promotions that they might be interested in. You
                    can choose not to receive the e-flyers by selecting this option in your TeachMe TV’s member account.</Text>
                <Text><strong>We Offer Optional Registration.</strong> We recommend that you register as a customer in
                    order to take advantage of the many benefits of maintaining an account.</Text>
                <Text><strong>Credit Card Info Protected by 128bit SSL Encryption.</strong> The security of your
                    personal information is paramount. All Credit Card Transactions are completed using a 128 Bit SSL
                    Encrypted Secure Transaction. As we transmit the information to the Bank's Secure SSL Server, they
                    require a 128-bit transaction and will not process a transaction without one. Even though 40 or 56
                    Bit transactions are very secure, our Bank's insistence on 128 Bit SSL means that there is never any
                    chance of your information every being intercepted or decoded.</Text>
                <Text><strong>Password Protected Accounts.</strong> When you decide to register an account, your account
                    information is secured by password protection assigned and maintained by you. If you forget your
                    password, this information is only released to your specific email address recorded in your account
                    profile. It is not given out in any other situation without identity verification provided by you.
                    The account information contains the billing and shipping addresses, phone numbers and email address
                    provided and maintained by you as well as your order history. Credit card information is not
                    included in this file.</Text>
                <Text><strong>We Use Cookies.</strong> Like most advanced sites these days, TeachMe TV uses cookies to
                    make your experience the best it can be. No personal or credit card information is stored in these
                    cookies and there is no risk to your privacy or security caused by this action. It is simply an
                    identifier transferred to your system from ours that helps us manage your transaction. It remembers
                    items in your basket and currency selections. You can choose not to use cookies if you like. We do
                    not require cookies to process your order however the lack of the memory feature may cause some
                    frustration in certain situations.</Text>
                <Text><strong>Getting Your Consent.</strong> By using TeachMe TV you are consenting to the collection
                    and use of personal and account information by TeachMe TV, for the purposes set out in this policy.</Text>
                <Text><strong>Changes to this Privacy Policy.</strong> All changes will be posted on
                    https://teachmetv.co
                    promptly to inform visitors of what information is collected, how it is used, and under what
                    circumstances it may be disclosed.</Text>
                <Divider/>
                <Heading level={2}>Children's Privacy Policy</Heading>
                <Text>TeachMe TV does not knowingly solicit or collect personally identifiable information online from
                    children under the age of 13 without prior verifiable parental consent. If TeachMe TV learns that a
                    child under the age of 13 has submitted personally identifiable information online without parental
                    consent, it will take all reasonable measures to delete such information from its databases and to
                    not use such information for any purpose (except where necessary to protect the safety of the child
                    or others as required or allowed by law). If you become aware of any personally identifiable
                    information, we have collected from children under 13, please contact us at
                    info@teachmetv.co.</Text>
                <View>
                    <Text>For additional tips on how to help children stay safe on the Internet, we recommend that you
                        visit the following sites:</Text>
                    <ul>
                        <li><a href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy"
                               target={'_blank'}
                               rel={'noreferrer'}>https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy/</a>
                        </li>
                        <li><a href="https://consumer.ftc.gov/articles/kids-socializing-online"
                               target={'_blank'}
                               rel={'noreferrer'}>https://consumer.ftc.gov/articles/kids-socializing-online</a>
                        </li>
                        <li><a href="https://consumer.ftc.gov/identity-theft-and-online-security/protecting-kids-online"
                               target={'_blank'}
                               rel={'noreferrer'}>https://consumer.ftc.gov/identity-theft-and-online-security/protecting-kids-online</a>
                        </li>
                    </ul>
                </View>
            </Flex>
        </View>
    )
}