const theme = {
    name: 'custom-theme',
    tokens: {
      components: {
        button: {
          // this will affect the font weight of all Buttons
          fontWeight: { value: '{fontWeights.black.value}' },
          // this will only style Buttons which are the "primary" variation
          primary: {
            backgroundColor: { value: 'orange' },
            color: { value: 'black' },
            _hover: {
              backgroundColor: { value: 'red' },
            },
          },
          link: {
            color: { value: '#00acee' }
          },
          footerLink: {
            color: { value: 'white' }
          }
        },
        text: {
          secondary: {
            fontSize: {value: '12em'},
            color: {value: 'grey'}
          },
          light: {
            color: {value: 'white'}
          }
        },
        togglebutton: {
          borderColor: { value: 'orange' },
          color: { value: 'orange' },
          _hover: {
            backgroundColor: { value: 'orange' },
          },
          _focus: {
            color: { value: 'white' },
            _hover: {
              backgroundColor: { value: 'white' },
            },
          },
          _active: {
            backgroundColor: { value: 'orange' },
          },
          _pressed: {
            backgroundColor: { value: 'orange' },
            color: { value: 'white' },
            _hover: {
              backgroundColor: { value: 'orange' },
            },
          },
          primary: {
            backgroundColor: { value: 'orange' },
          },
        },
        table: {
          row: {
            hover: {
              backgroundColor: { value: '{colors.blue.20}' },
            },
            striped: {
              backgroundColor: { value: '{colors.blue.10}' },
            },
          },
          header: {
            color: { value: '{colors.blue.80}' },
            fontSize: { value: '{fontSizes.large}' },
          },
          data: {
            fontWeight: { value: '{fontWeights.semibold}' },
          },
        },
        searchfield: {
          button: {
            backgroundColor: { value: 'white' },
            color: { value: 'orange' },
            _focus: {
              backgroundColor: {
                value: 'orange',
              },
              color: { value: 'white' },
            },
            _hover: {
              backgroundColor: {
                value: 'orange',
              },
              color: { value: 'white' },
            },
          },
        },
      },
    },
  };

export default theme;