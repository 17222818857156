import React, {useEffect, useState} from 'react';
import RestaurantGridItem from './grid-item';
import restaurantActivities from '../../data/restaurantActivities.json';
import getActivities from "../../helpers/getActivities";


export default function RestaurantGrid() {
    const [activities, setActivities] = useState(null);

    useEffect(() => {
        loadActivities();
    }, [])

    const loadActivities = async () =>
        setActivities(await getActivities());


    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(3, 1fr)',
        height: '100vh',
        alignItems: 'stretch',
        justifyContent: 'center',
    };

    const colors = [
        "#FF6384",   // Pink
        "#36A2EB",   // Blue
        "#FFCE56",   // Yellow
        "#4BC0C0",   // Turquoise
        "#9966FF",   // Purple
        "#FF9F40",   // Orange
        "#32CD32",   // Green
        "#FF99C3",   // Light Pink
        "#008080"    // Teal
    ];


    const getActivityData = (restaurantActivityEntry) => {
        if (restaurantActivityEntry.hasOwnProperty('data'))
            return restaurantActivityEntry.data;

        return activities.data.find(activity => activity.id === restaurantActivityEntry.id);
    }


    if (!activities)
        return <></>

    return (
        <div style={gridStyle}>
            {
                restaurantActivities.data.map((restaurantActivity, index) => (
                    <RestaurantGridItem
                        key={restaurantActivity.id}
                        baseColor={colors[index]}
                        activity={getActivityData(restaurantActivity)}
                        {...restaurantActivity}
                    />
                ))
            }
        </div>
    )
}
