import React, {useCallback} from 'react';
import DialogueScene from "./DialogueScene";
import EmojiTextCards from "../EmojiTextCards";
import AudioRecorder from "../audio-recorder";
import {EMOTIONS} from "../../constants";
import EmotionCardsContainer from "../EmotionCardsContainer";

function EmotionSelectionScene({video, setSelectedEmotion, isVoiceControlled, sampleRate}) {
    const setEmotionBySpeech = useCallback((text) => {
        if (text.includes('calm') || text.includes('happy') || text.includes('ok')) setSelectedEmotion('ok')
        else if (text.includes('tired') || text.includes('sad') || text.includes('sick') || text.includes('Bored')) setSelectedEmotion('tired')
        else if (text.includes('silly') || text.includes('hyper') || text.includes('confused')) setSelectedEmotion('silly')
        else if (text.includes('mad') || text.includes('frustrated')) setSelectedEmotion('mad')
    }, [setSelectedEmotion])

    return (
        <div style={{height: '100%', position: 'relative'}}>
            {isVoiceControlled && <AudioRecorder callbackFn={setEmotionBySpeech} sampleRate={sampleRate} />}
            <EmotionCardsContainer>
                <EmojiTextCards data={EMOTIONS} setSelectedEmoji={setSelectedEmotion}/>
            </EmotionCardsContainer>

            <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                <DialogueScene
                    dialgueVideo={video}
                    isWithBackButton={false}
                    videoPos={'right'}
                />
            </div>
        </div>
    );
}

export default EmotionSelectionScene;