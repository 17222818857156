import {Flex, Heading, Image, View} from "@aws-amplify/ui-react";
import {LandingButton} from "../../themed";
import React from "react";
import { ReflectivePractices } from '../../components/ReflectivePractices';

export default function LandingScreenMistakes(props) {
    const Content = () => (<View>
        <Heading level={1} color={'#50a7ef'} fontFamily={'muli, sans-serif'} fontWeight={'700'} fontSize={props.isMobile ? '24pt' : '3.3rem'}>
            What makes us different?
        </Heading>
        <Heading level={2} color={'#50a7ef'} paddingTop={'30px'} fontFamily={'muli, sans-serif'} fontWeight={'500'} fontSize={props.isMobile ? '24pt' : '2rem'}>
            We treat mistakes as opportunities for strategy training and discovery. Critical feedback is a part of
            everything we do.
        </Heading>
        <LandingButton marginTop={'60px'} text={'Explore Plans'} plansRef={props.plansRef}/>
       <ReflectivePractices />
    </View>)

    const Computer = () => (<Image
        alt={'Computer screenshot'}
        src={'/images/landing/computer-screenshot.png'}
        objectFit={'contain'}
        margin={'30px'}
        width={props.isMobile ? '100%' : '50%'}
    />)

    return (<Flex
        backgroundColor={'white'}
        wrap={props.isMobile ? 'wrap' : 'nowrap'}
        justifyContent={'center'}
        alignItems={'center'}
        padding={'60px'}
    >
        {props.isMobile && <>
            <Computer/>
            <Content/>
        </>}
        {!props.isMobile && <>
            <Content/>
            <Computer/>
        </>}
    </Flex>)
}