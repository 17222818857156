import React, {forwardRef} from 'react';
import styled from "styled-components";
import {Box, Modal} from "@mui/material";
import {Button, Flex, Heading, View} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

const StyledVideo = styled.video`
  height: 100%;
  position: ${props => props.videoPos === 'right' ? 'absolute' : 'relative'};
  margin-right: ${props => props.videoPos === 'right' ? '-10rem' : 0};

  @media only screen and (max-height: 580px) {
    margin-right: ${props => props.videoPos === 'right' ? '-5rem' : 0};
  }
`

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    border: '2px solid var(--orange, #F7941D)',
    background: '#fff',
    backdropFilter: 'blur(8px)',
};

const ParentTutorialScene = forwardRef(({dialgueVideo}, ref) => {
    const navigate = useNavigate()

    const onClickTutorial = () => {
        navigate('/kids-zone', { state: { from: 'ParentTutorialScene' } });
    }
    const onClickSkip = () => {
        navigate('/home')
    }

    return (
        <View style={{height: '100%', position: 'relative', overflow: 'hidden'}}>
            <Flex justifyContent={'center'} style={{height: '100%', width: '100%'}}>
                <StyledVideo
                    src={dialgueVideo}
                    ref={ref}
                    autoPlay
                    videoPos={'center'}
                />
            </Flex>

            <Modal
                open
                aria-labelledby="tutorial modal"
                onClose={() => {
                }}
            >
                <Box sx={style}>
                    <Flex direction={'column'} justifyContent={'space-evenly'} alignItems={'center'} style={{width: '100%', height: '100%'}}>
                        <Heading level={1} style={{margin: '1rem 0'}}>Start Tutorial</Heading>
                        <video src={'https://teachmetv.s3.amazonaws.com/videos/internal/parentTutorial.mp4'} autoPlay loop
                               style={{margin: '1rem 0', width: '60%'}}/>
                        <View style={{
                            width: '100%',
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr 1fr',
                            margin: '1rem 0'
                        }}>
                            <View />
                            <View style={{display: 'flex', justifyContent: 'center'}}>
                                <Button style={{animation: 'pulse 1.5s infinite'}} variation={'primary'} onClick={onClickTutorial}>Explore</Button>
                            </View>
                            <View style={{display: 'flex', justifyContent: 'center'}}>
                                <Button onClick={onClickSkip}>Skip</Button>
                            </View>
                        </View>
                    </Flex>
                </Box>
            </Modal>
        </View>
    );
})

export default ParentTutorialScene;