import React from "react";
import {Box, Container, Button, Typography} from "@mui/material";
import styled from "styled-components";
import {Image} from "@aws-amplify/ui-react";
import {handleScheduleDemo} from "./schedule-demo";
import './style.css';

const StyledContainer = styled(Container)({
    backgroundColor: "rgb(12 68 153)",
    justifyContent: "center",
    height: "100%",
    paddingBlock: "48px",

    ".amplify-image": {
        width: "500px",
        height: "auto",
    },

    ".MuiTypography-root": {
        color: "#fff",
        marginBottom: "16px",
    },
});

const StyledButton = styled(Button)({
    "&&": {
        transition: "0.5s",

        "&:hover": {
            boxShadow: "none",
            transform: "scale(1.08)",
        },
    },
});

function VideoPlayer(fileId) { // Make sure fileId is deconstructed from props
    const embedUrl = `https://drive.google.com/file/d/${fileId}/preview`;

    return (
        <div className="video-container">
            <iframe
                className="responsive-iframe"
                src={embedUrl}
                allow="autoplay"
                title="Video title"
            ></iframe>
        </div>
    );
}

const PhilosophyScreen = () => {

    return (
        <StyledContainer maxWidth={false}>
            <Box
                sx={{
                    width: "75%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginInline: "auto",
                    textAlign: "center",
                }}
            >
                <Image
                    alt={"teach-me-tv-logo"}
                    src={"/images/schools-screen/teach-me-tv-logo.webp"}
                />
                <Typography variant="h5" fontSize={43} fontWeight="bold">
                    Our Philosophy
                </Typography>
                <Typography variant="h6" fontSize={23} fontWeight="bold">
                    “Given the explosion of knowledge, people cannot learn in school all
                    they will need to know in later life. So they need to learn how to
                    learn…” <br/>
                    (Collins and Halverson 2009)
                </Typography>
            </Box>
            <Typography
                variant="h6"
                fontSize={23}
                fontWeight="bold"
                textAlign={"center"}
            >
                TeachMe TV® ignites a passion for learning by fostering critical thinking skills and student agency
                essential for both academic success and facing real-world challenges. Through interactive, multimedia
                lessons, enhanced with and supported by AI, our program motivates students to discover their strengths
                and pave paths towards problem-solving. At TeachMe TV®, we believe in nurturing a generation of children
                poised to question, understand, and excel in school and beyond.
            </Typography>
            <StyledButton onClick={handleScheduleDemo}>
                <Image
                    src={"/images/schools-screen/schedule-demo.svg"}
                    alt="Schedule Demo"
                    width={"300px"}
                />
            </StyledButton>
            {
                VideoPlayer("1SZRLw__TmSxEk6gdNBD3DRiw2PU5_mB4")
            }
        </StyledContainer>
    );
};

export default PhilosophyScreen;
