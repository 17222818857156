import React, {useEffect, useState} from "react";
import { useAnimation } from "../../hooks/useAnimation";
import { useMobile } from "../../hooks/useMobile";

const SPRITE_SHEET_DIMENSION = 512;
const FPS = 10;
const VALID_SPRITE_SHEET_COUNT = 13

function CharacterAnimation(props) {
  const { characterId, avatarData, animationName, scale, additionalStyles, onClick } = props;
  const { width: deviceWidth} = useMobile();

  const [context, setContext] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [currentAnimationName, setCurrentAnimationName] =
    useState(animationName);

  // This useEffect will update currentAnimationName when animationName prop changes
  useEffect(() => {
    setCurrentAnimationName(animationName);
    // Reset the rowIndex and columnIndex to start the new animation from the beginning
  }, [animationName]);

  useEffect(() => {
    if (context)
      setAnimationData(context.avatar.animations[currentAnimationName]);

    if (avatarData)
      setAnimationData(avatarData.animations[currentAnimationName]);
  }, [currentAnimationName]);

  useEffect(() => {
    if (avatarData) {
      setAnimationData(avatarData.animations[currentAnimationName]);
      return;
    }

    import(`./data/${characterId}.json`)
      .then((module) => {
        const newContext = module.default;
        setContext(newContext);
        setAnimationData(newContext.avatar.animations[currentAnimationName]);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  }, [characterId]);

  
  useEffect(() => {
    if (!animationData) return;

    // Detect if animation is finish by checking if the current column index is the last column

    if (animationData.hasOwnProperty("next")) {
      let nextAnimationName = animationData.next;

      // Check if 'next' is an array and pick a random element if it is
      if (Array.isArray(nextAnimationName)) {
        const randomIndex = Math.floor(
          Math.random() * nextAnimationName.length
        );
        nextAnimationName = nextAnimationName[randomIndex];
      }

      setCurrentAnimationName(nextAnimationName);
    }
  }, [animationData]);

  const scaleBasesonWidth = deviceWidth<SPRITE_SHEET_DIMENSION ? SPRITE_SHEET_DIMENSION/deviceWidth : 1;

  const style = useAnimation({
    sprite: `/images/avatars/${characterId}_spritesheet.png`,
    width: SPRITE_SHEET_DIMENSION,
    height: SPRITE_SHEET_DIMENSION,
    fps: FPS,
    scale: scale ? scale : scaleBasesonWidth,
    row:animationData?.row,
    maxFrames: animationData?.numColumns||VALID_SPRITE_SHEET_COUNT,
    ...additionalStyles
  })

  return <div className="character" style={style} onClick={onClick}/>

}

export default CharacterAnimation;