import { Image } from "@aws-amplify/ui-react";
import { Grid, Link, Typography } from "@mui/material";

export const ReflectivePractices = () => {
  return (
    <Grid container display="flex" alignItems="center" justifyContent="center" padding={2}>
      <Grid item>
        <Link target='_blank' href="/pdf/reflective-practices-sheet.pdf">
          <Image
            src="/images/landing/reflective-practices.png"
            width={410}
            alt="Reflective Practices"
          />
          <Typography >

          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
