import axios from "axios";

export async function deleteThumbnail(filename) {
    const url = `${process.env['REACT_APP_AWS_API_ENDPOINT']}/s3?prefix=images/thumbnails&file=${filename}`;
    const response = await axios.delete(url);
    return response;
}

export async function postThumbnail(base64Image, filename) {
    const url = `${process.env['REACT_APP_AWS_API_ENDPOINT']}/s3/uploadFile`;

    const match = base64Image.match(/^data:image\/(\w+);base64,/);
    const fileExtension = match ? match[1] : null;

    if (!['png', 'jpg', 'jpeg'].includes(fileExtension)) {
        throw new Error()
    }

    const imageData = {
        image: base64Image,
        prefix: 'images/thumbnails',
        filename: `${filename}.${fileExtension}`
    };

    try {
        await axios.post(
            url,
            imageData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    } catch (error) {
        console.error("Error uploading the image:", error);
    }
}
