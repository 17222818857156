import {CognitoIdentityClient} from "@aws-sdk/client-cognito-identity";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-provider-cognito-identity";
import {TranscribeStreamingClient} from "@aws-sdk/client-transcribe-streaming";
import MicrophoneStream from "microphone-stream";
import {StartStreamTranscriptionCommand} from "@aws-sdk/client-transcribe-streaming";
import {Buffer} from "buffer";
import * as awsID from "../aws-exports";

const DESIRED_SAMPLE_RATE = 16000
let microphoneStream = undefined
let transcribeClient = undefined

const getTranscribeClient = () => {
    if (!transcribeClient) {
        transcribeClient = new TranscribeStreamingClient({
            region: 'us-east-1',
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({region: awsID.default.aws_cognito_region}),
                identityPoolId: awsID.default.aws_cognito_identity_pool_id,
            }),
        });
    }

    return transcribeClient;
}

export const startRecording = async (language, callback, sampleRate) => {
    if (!language) {
        return false;
    }
    if (microphoneStream || transcribeClient) {
        stopRecording();
    }
    transcribeClient = getTranscribeClient();
    createMicrophoneStream();
    await startStreaming(language, callback, sampleRate);
};

export const stopRecording = function () {
    if (microphoneStream) {
        microphoneStream.stop();
        microphoneStream.destroy();
        microphoneStream = undefined;
    }
    if (transcribeClient) {
        transcribeClient.destroy();
        transcribeClient = undefined;
    }
};

const createMicrophoneStream = async () => {
    microphoneStream = new MicrophoneStream();
    microphoneStream.setStream(
        await window.navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        })
    );
}

const startStreaming = async (language, callback, sampleRate) => {
    try {
        const command = new StartStreamTranscriptionCommand({
            LanguageCode: language,
            MediaEncoding: "pcm",
            MediaSampleRateHertz: DESIRED_SAMPLE_RATE,
            AudioStream: getAudioStream(sampleRate),
        });
        const data = await transcribeClient.send(command);
        for await (const event of data.TranscriptResultStream) {
            for (const result of event.TranscriptEvent.Transcript.Results || []) {
                if (result.IsPartial === false) {
                    const noOfResults = result.Alternatives[0].Items.length;
                    for (let i = 0; i < noOfResults; i++) {
                        callback(result.Alternatives[0].Items[i].Content + " ");
                    }
                }
            }
        }
    } catch (error) {
        console.error('Error while starting transcription:', error);
    }
}

const getAudioStream = async function* (sampleRate) {
    for await (const chunk of microphoneStream) {
        const rawPCM = MicrophoneStream.toRaw(chunk);
        const downsampledAudio = downsampleBuffer(rawPCM, sampleRate, DESIRED_SAMPLE_RATE)

        yield {
            AudioEvent: {
                AudioChunk: encodePCMChunk(downsampledAudio),
            },
        }
    }
};

// Down sampling by linear interpolation
function downsampleBuffer(buffer, inputSampleRate = 44100, outputSampleRate = 16000) {
    if (outputSampleRate === inputSampleRate) {
        return buffer;
    }

    let sampleRateRatio = inputSampleRate / outputSampleRate;
    let newLength = Math.round(buffer.length / sampleRateRatio);
    let result = new Float32Array(newLength);

    for (let i = 0; i < newLength; i++) {
        let sampleIndex = i * sampleRateRatio;
        let lowerIndex = Math.floor(sampleIndex);
        let fraction = sampleIndex - lowerIndex;

        // Linear interpolation
        result[i] = (1.0 - fraction) * buffer[lowerIndex] + fraction * buffer[Math.min(lowerIndex + 1, buffer.length - 1)];
    }

    return result;
}


// Down sampling by averaging
// function downsampleBuffer(buffer, inputSampleRate = 44100, outputSampleRate = 16000) {
//     if (outputSampleRate === inputSampleRate) {
//         return buffer;
//     }
//
//     const sampleRateRatio = inputSampleRate / outputSampleRate;
//     const newLength = Math.round(buffer.length / sampleRateRatio);
//     const result = new Float32Array(newLength);
//     let offsetResult = 0;
//     let offsetBuffer = 0;
//
//     while (offsetResult < result.length) {
//         let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
//
//         let accum = 0
//         let count = 0
//
//         for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
//             accum += buffer[i];
//             count++;
//         }
//
//         result[offsetResult] = accum / count;
//         offsetResult++;
//         offsetBuffer = nextOffsetBuffer;
//
//     }
//
//     return result;
// }

const encodePCMChunk = (chunk) => {
    let offset = 0;
    const buffer = new ArrayBuffer(chunk.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < chunk.length; i++, offset += 2) {
        let s = Math.max(-1, Math.min(1, chunk[i]));
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return Buffer.from(buffer)
}
