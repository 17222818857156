import {View, Text} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

import dict from "../../data/bannerPromos.json";

const promoData = dict.data;

export default function TopBanner({isMobile}) {
    const navigate = useNavigate();

    const today = new Date();
    const currentPromo = Object.values(promoData)
        .find(promo => new Date(promo.date.from) <= today && new Date(promo.date.to) >= today);

    if (!currentPromo)
        return <></>

    return (
        <View
            backgroundColor={currentPromo.backgroundColor}
            style={{cursor: 'pointer'}}
            onClick={() => {
                // eslint-disable-next-line default-case
                switch (currentPromo.link.type) {
                    case "internal": navigate(currentPromo.link.url); break;
                    case "external": window.document.location.href = currentPromo.link.url; break;
                    case "activity": navigate(`/play/${currentPromo.link.url}`); break;
                }
            }}
        >
            <Text
                color={'white'}
                fontSize={isMobile ? '0.9em' : '1.5em'}
            >
                {currentPromo.message}
            </Text>
        </View>
    )
}