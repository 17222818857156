import {Flex, Heading, Image, Text} from "@aws-amplify/ui-react";
import EmailWidget from "./email-widget";

export default function LandingScreenEmailSignup(props) {
    return (
        <Flex
            ref={props.signupRef}
            direction={'column'}
            backgroundColor={'#f4f6fc'}
            alignItems={'center'}
            justifyContent={'center'}
            wrap={'wrap'}
            textAlign={'center'}
            paddingBottom={props.isMobile ? '50px' : '0px'}
        >
            <Heading
                level={3}
                fontFamily={'muli, sans-serif'}
                fontWeight={900}
                width={props.isMobile ? '100%' : '60vw'}
                padding={30}
            >
                Join our mailing list for news & FREE resources!
            </Heading>
            <Flex
                alignItems={'center'}
                justifyContent={'center'}
                wrap={'wrap'}
                position={'relative'}
            >
                {
                    !props.isMobile &&
                    <Flex
                        backgroundImage={"url('/images/landing/shape-subscribe.gif')"}
                        width={600}
                        height={500}
                        alignItems={'center'}
                        justifyContent={'center'}
                        style={{
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            transform: props.isMobile ? 'scale(0.5)' : ''
                        }}
                    >
                        <Text padding={50}>Yes! Please add me to TeachMe TV's mailing list for updates and FREE
                            downloads too! </Text>
                    </Flex>
                }
                {
                    props.isMobile &&
                    <Image
                        alt={'Brush stroke newsletter mobile'}
                        src='/images/landing/brush-stroke-newsletter-mobile.png'
                    />
                }
                <EmailWidget/>
            </Flex>
        </Flex>
    )
}