import { Button, ButtonGroup, useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";

const KIDS_ZONE = "/kids-zone";

const NavBarTabs = (props) => {
  const {
    aboutRef,
    direction = "row",
    buttonFontSize,
    handleDrawerToggle,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthenticator((context) => [context.user]);
  const isSample = location.pathname.includes("/sample/");
  const isKidsZone = location.pathname.includes(KIDS_ZONE);

  const handleDrawerToggleAndNavigate = () => {
    handleDrawerToggle && handleDrawerToggle();
  };

  return (
    <>
      <ButtonGroup
        direction={direction}
        variation="link"
        justifyContent={"flex-end"}
        gap={0}
      >
        {!user && (
          <Button
            onClick={() => {
              setTimeout(() => {
                aboutRef.current?.scrollIntoView({
                  behavior: "instant",
                  block: "start",
                });
              }, 200);
              navigate("/");
              handleDrawerToggleAndNavigate();
            }}
          >
            About
          </Button>
        )}
        {!user && (
          <Button
            onClick={() => {
              handleDrawerToggleAndNavigate();
              navigate("/parents");
            }}
          >
            Families
          </Button>
        )}
        {!user && (
          <Button
            onClick={() => {
              handleDrawerToggleAndNavigate();
              navigate("/teachers");
            }}
          >
            Schools
          </Button>
        )}
        {/* {!user && <Button onClick={() => navigate("/schools")}>Schools</Button>} */}

        {/* <Button onClick={() => navigate('/blog')}>Blog</Button> */}
        {/* {user && <Button onClick={() => navigate('/account')}>Account</Button>} */}
      </ButtonGroup>
      {!user && (
        <Button
          variation="primary"
          borderRadius={300}
          border={"thick solid black"}
          fontSize={buttonFontSize}
          onClick={() => {
            navigate(
              !isSample
                ? "/account?signin=true"
                : "/?ref=from_squeeze_page_test1"
            );
            handleDrawerToggleAndNavigate();
          }}
        >
          {!isSample ? "Login" : "Discover More"}
        </Button>
      )}
      {user && !isKidsZone && (
        <Button
          variation="primary"
          borderRadius={300}
          border={"thick solid black"}
          onClick={() => {
            handleDrawerToggleAndNavigate();
            navigate("/kids-zone");
          }}
        >
          Play Now!
        </Button>
      )}
    </>
  );
};

export default NavBarTabs;
