import React from 'react';
import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";

const EmotionCardContainer = styled(View)`
  display: flex;
  flex-direction: column;
  width: calc(50% - 0.5rem);
  height: 156px;
  padding: 2.75rem;
  justify-content: center;
  align-items: center;
  background: ${props => props.backgroundColor || '#FFE4CB'};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  
  @media only screen and (max-height: 580px) {
    height: 100px;
  }
`

const EmojiTextCard = ({emotion, backgroundColor, onClick}) => {
    return (
        <EmotionCardContainer backgroundColor={backgroundColor} onClick={onClick}>
            <p style={{fontSize: '2.5rem', margin: 0}}>{emotion.emoji}</p>
            <p style={{fontSize: '1rem', margin: 0}}>{emotion.text}</p>
        </EmotionCardContainer>
    )
}

function EmojiTextCards({data, setSelectedEmoji, backgroundColor}) {
    return (
        <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center'}}>
            {
                Object.keys(data)
                    .map(d =>
                        <EmojiTextCard
                            key={d}
                            emotion={data[d]}
                            backgroundColor={backgroundColor}
                            onClick={() => setSelectedEmoji(d)}
                        />
                    )
            }
        </div>
    );
}

export default EmojiTextCards;