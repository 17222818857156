import nlp from "compromise";

const removeEmojis = (str) => {
  return str.replace(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE0F}]/gu,
    ""
  );
};

const AUX_VERBS = [
  "is",
  "am",
  "are",
  "was",
  "were",
  "have",
  "has",
  "had",
  "do",
  "does",
  "did",
  "can",
  "could",
  "will",
  "would",
  "shall",
  "should",
  "may",
  "might",
  "must",
];

const removeSpacesAndSpecialChars = (text) => {
  return text.replace(/[^a-zA-Z0-9]/g, "");
};

export const containsYesNoQuestion = (message) => {
  const cleanedMessage = removeEmojis(message);
  const docSentence = nlp(cleanedMessage);
  const sentences = docSentence.sentences().out("array");
  let sentence = sentences.pop();
  if (["yesorno", "yesno"].includes(removeSpacesAndSpecialChars(sentence)?.toLowerCase())) {
    sentence = sentences.pop();
  }
  const doc = nlp(sentence);
  const firstTerm = doc.terms().first().text();
  return AUX_VERBS.includes(firstTerm.toLowerCase());
};
