import axios from 'axios';

export default async function getActivities() {
    if (document.location.href.includes('localhost')) {
        return require('../data/activities.json');
    }

    const env = document.location.href.includes('beta') ? 'DEV' : 'PROD';
    const url = `${process.env[`REACT_APP_AWS_API_ENDPOINT_${env}`]}/s3/?prefix=data&file=activities.json`;
    const response = await axios.get(url);

    return response.data;
}

export const getFilteredActivitiesByGrade = (activities, grade) => {
    return activities.data.filter(a => a.grades.includes(grade.toUpperCase()))
}