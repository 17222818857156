import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import config from "../../components/ChatBot/config.json";
import brenda from "../../components/ChatBot/data/brenda.json";
import Avatar from "../../components/ChatBot/Avatar";
import { Image } from "@aws-amplify/ui-react";
import { useMobile } from "../../hooks/useMobile";
import { useNavigate } from "react-router-dom";

const dynamicFont = (minFont = 20) =>
  `calc(${minFont}px + (26 - 16) * ((100vw - 400px) / (1600 - 400)))`;

const Conversation = () => {
  // const [animation, setAnimation] = useState('idle_simple');
  const { isMobile, width } = useMobile();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     setAnimation("idle_simple");
  //   }, 3 * 1000);

  //   return () => clearTimeout(timerId);
  // }, []);

  return (
    <Grid
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid padding={5}>
        <Typography
          style={{
            fontSize: dynamicFont(35),
          }}
          variant="h2"
          gutterBottom
        >
          Meet Brenda, our AI powered Study Buddy
        </Typography>

        <Typography
          style={{
            fontSize: dynamicFont(),
          }}
          gutterBottom
        >
          She's one of our Study Buddies that help children learn math, reading,
          science or anything they are studying in school. She's all about
          asking good questions. She's multilingual too.
        </Typography>

        <Grid
          container
          spacing={2}
          paddingRight={isMobile ? 0 : 6}
          paddingLeft={isMobile ? 0 : 6}
        >
          <Grid item xs={12} md={6}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <div style={{ paddingTop: "25%", paddingBottom: "25%" }}>
                <Image
                  alt="Brenda the Study Buddy"
                  src={"/images/avatars/Brenda_Waving.gif"}
                  onClick={() => navigate("/playground")}
                  additionalStyle={{
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                />
              </div>
              <Image
                position={"absolute"}
                alt={"Floating symbol conversation bubble"}
                top={10}
                width={isMobile ? "75%" : "65%"}
                src={"/images/schools-screen/conversation-bubble.png"}
                onClick={() => navigate("/playground")}
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Grid>
          <Grid item xs={false} md={6} lg={6} position={"relative"}>
            <Image
              alt={"Floating symbol rectagle"}
              position={"absolute"}
              display={width < 900 ? "none" : undefined}
              top={"20%"}
              right={"20%"}
              width={"250px"}
              src={"/images/schools-screen/rectangle.webp"}
            />
            <Image
              alt={"Floating symbol puzzle"}
              position={"absolute"}
              display={width < 900 ? "none" : undefined}
              top={"65%"}
              right={"18%"}
              width={"125px"}
              src={"/images/schools-screen/puzzle.png"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Conversation;
