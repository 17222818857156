import {Flex, Heading, Image, Text, View} from "@aws-amplify/ui-react";
import {PrimaryButton} from "../../themed";

const ENV_URLS = {
    dev: {
        monthly: 'https://buy.stripe.com/test_00g5kS5r1bHJaS43ce',
        annually: 'https://buy.stripe.com/test_8wM00ycTt9zB0dqaEE',
        teacher: 'https://buy.stripe.com/test_eVaeVs4mXdPRaS4cMN'
    }, prod: {
        monthly: 'https://buy.stripe.com/5kAeVJ25F6mddEccMN',
        annually: 'https://buy.stripe.com/dR6eVJbGf25XeIg4gi',
        teacher: 'https://buy.stripe.com/28ocNB7pZ3a19nWeUX'
    }
}

export default function LandingScreenTeachers(props) {

    const benefits = ["Create small groups for personalized learning", "Integrate our interactive, audible lessons with your classroom technology", "Pair interactive lessons with printable lesson extenders already matched for you by subject and skill level", "Address lost learning with skill practice in novel and engaging ways", "Create standards-based lessons and connect to your school’s curricula"]

    return (<Flex
        direction={'column'}
        backgroundColor={'#f5fae5'}
        paddingTop={30}
        paddingBottom={30}
        position={'relative'}
    >
        <View
            textAlign={'center'}
            position={'relative'}
            width={props.isMobile ? '90vw' : '60vw'}
            margin={'auto auto'}
        >
            {window.innerWidth > 1300 && <Image
                alt={'Curvy arrow'}
                position={'absolute'}
                top={0}
                left={-100}
                src='/images/landing/arrow-curvy.svg'
            />}
            <Heading level={1} fontFamily={'muli, sans-serif'}>
                TeachMe TV is for Teachers too!
            </Heading>
            <Text>We can help you:</Text>
        </View>
        <Flex
            direction={'column'}
            paddingTop={30}
            paddingBottom={30}
            width={props.isMobile ? '100%' : '75vw'}
            margin={'auto auto'}
            textAlign={'left'}
            gap={20}
        >
            {benefits.map((benefit, index) => (<Flex key={index}>
                <Image
                    alt={'Bullet shape'}
                    src={'/images/landing/shape-bullet.png'}
                    height={'45px'}
                />
                <Text>{benefit}</Text>
            </Flex>))}
        </Flex>
        <Flex
            justifyContent={'center'}
            alignItems={'center'}
            paddingBottom={80}
            wrap={'wrap'}
        >
            <PrimaryButton text={'Get Started'}
                           onClick={() => window.location.href = ENV_URLS[process.env['REACT_APP_ENV'] || 'dev']['teacher']}/>
            <Image
                alt={'Star shape'}
                src="/images/landing/shape-star.png"
                width={'13vw'}
            />
        </Flex>
        <Image
            alt={'Clapping lady GIF'}
            src={'/images/landing/clapping-lady.gif'}
            position={'absolute'}
            bottom={0}
            right={0}
            width={'23vw'}
            style={{
                transform: 'scaleX(-1)'
            }}
        />
    </Flex>)
}