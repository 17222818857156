import SchoolScreenIntro from "./school-screen-intro";
import Plans from "./plans";
import Subjects from "./subjects";
import PhilosophyScreen from "./philospohy-screen";
import ContactUs from "./contactUs";
import Conversation from "./conversastion";

export default function SchoolScreen() {
    return (
        <>
            <SchoolScreenIntro />
            <Plans />
            <Subjects />
            <PhilosophyScreen />
            <Conversation />
            <ContactUs />
        </>
    )
}