import React, { useCallback, useEffect, useState } from "react";
import { Authenticator, Button, Flex, Heading, Link, SelectField, useAuthenticator, useTheme, View } from "@aws-amplify/ui-react";
import { SubscribeButton } from "../../themed";
import pricingDict from "../../data/pricing.json";
import ChildReportTable from "../../components/child-report-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import query from "../../query";
import fetchSubscription from "../../fetchSubscription";
import { DataStore } from "aws-amplify";
import axios from "axios";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import AvatarSelector from "../../components/AvatarSelector";
import { upsertUser } from "../../helpers/userInfo";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import config from "../../components/ChatBot/config.json";
import {Skeleton} from "@mui/material";

function Index() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Account Information - TeachMe TV";
    }, []);

    const { tokens } = useTheme();
    const [searchParams] = useSearchParams();

    const [userData, setUserData] = useState();
    const { user, isLoading } = useAuthenticator((context) => [context.user, context.isLoading]);
    const [isUserLoaded, setIsUserLoaded] = useState(false);

    useEffect(() => {
        if (!isLoading && !isUserLoaded) {
            setIsUserLoaded(true);
        } else if (isUserLoaded && !user) {
            navigate("/");
        }
    }, [isLoading, isUserLoaded, user, navigate]);

    const { name, setName, selectedChar, setSelectedChar, selectedGrade, setSelectedGrade } = useStudyBuddyState();
    const [localName, setLocalName] = useState(name);
    const [localChar, setLocalChar] = useState(selectedChar);
    const [localGrade, setLocalGrade] = useState(selectedGrade);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setLocalName(name);
        setLocalChar(selectedChar);
        setLocalGrade(selectedGrade);
    }, [name, selectedChar, selectedGrade]);

    const onSubmitEdit = () => {
        setName(localName);
        setSelectedChar(localChar);
        setSelectedGrade(localGrade);
        setIsSaving(true);

        upsertUser(user.username, localName, localGrade, localChar).then(() => {
            setIsSaving(false);
        });
    };

    const fetchData = useCallback(async () => {
        setUserData({
            activities: await query(user.username, "activities").catch((err) => console.error(err)),
            subscription: await fetchSubscription(user.username),
        });
    }, [user]);

    useEffect(() => {
        // TODO: If subscriptionID set and user set, call backend to make the link
        // if (user && searchParams.get('subscriptionID'))
        // alert('test')

        if (user && !userData)
            // This should also check for !userData but it doesn't work.
            fetchData().then((_) => console.log("fetchData() completed"));

        if (!user)
            // if not logged in, wipe userData
            setUserData(null);
    }, [fetchData, user, userData]);

    const isMobile = window.innerWidth < 600;

    const css = `
        body {
            background: rgb(102,206,245);
            background: linear-gradient(309deg, rgba(102,206,245,1) 0%, rgba(179,231,250,1) 100%);
        }
    `;

    if (!isUserLoaded) {
        return <Skeleton variant="rectangular" animation="wave" height={200} />;
    }

    return (
        <>
            <style>{css}</style>
            <View
                width={isMobile ? "100%" : "700px"}
                margin={"auto auto"}
                backgroundColor={"#FAFAFA"}
                boxShadow={"0px 20px 0px -10px #FAFAFA, 0px -20px 0px -10px rgba(255, 255, 255, 255), 20px 0px 0px -10px #FAFAFA, -20px 0px 0px -10px #FAFAFA, 0px 0px 0px 10px #FF0000, 5px 5px 15px 5px rgba(0,0,0,0)"}
                padding={tokens.space.large}
            >
                <Heading level={1} paddingBottom={tokens.space.large}>
                    Account Information
                </Heading>
                <View style={{ marginBottom: "1rem" }}>
                    {config.enabled && (
                        <View>
                            <Button onClick={() => navigate("/onboarding/parentBuddy")}>Start Tutorial</Button>
                        </View>
                    )}
                    <View>
                        <Link fontSize={18} href={"https://teachmetv.s3.amazonaws.com/documents/How+to+Use+TeachMe+TV++(1).pdf"}>
                            Learn how to use TeachMe TV 📄
                        </Link>
                    </View>
                </View>
                <Authenticator>
                    {({ signOut }) => (
                        <>
                            <Button
                                variation="link"
                                onClick={async () => {
                                    await DataStore.clear();
                                    searchParams.set("signin", "true");
                                    signOut();
                                    navigate("/account");
                                }}
                            >
                                Sign out
                            </Button>
                            {userData && userData.subscription && (
                                <Flex direction={{ base: "column" }} alignItems={"center"} padding={tokens.space.large}>
                                    <Button
                                        onClick={async () => {
                                            const response = await axios.get(`${process.env["REACT_APP_STRIPE_API_ENDPOINT"]}subscription/manage?customerID=${userData.subscription.customer}`);
                                            window.location.href = response.data.url;
                                        }}
                                    >
                                        Manage Subscription
                                    </Button>
                                </Flex>
                            )}
                        </>
                    )}
                </Authenticator>

                {user && userData && !userData.subscription && (
                    <Flex padding={isMobile ? tokens.space.small : tokens.space.xxxl} direction={{ base: "column" }} maxWidth={isMobile ? 300 : 800} margin={"auto auto"} justifyContent={"center"}>
                        <SubscribeButton pricingDict={pricingDict.data} />
                    </Flex>
                )}
            </View>

            <View
                width={isMobile ? "100%" : "700px"}
                margin={"auto auto"}
                backgroundColor={"#FAFAFA"}
                boxShadow={"0px 20px 0px -10px #FAFAFA, 0px -20px 0px -10px rgba(255, 255, 255, 255), 20px 0px 0px -10px #FAFAFA, -20px 0px 0px -10px #FAFAFA, 0px 0px 0px 10px #FF0000, 5px 5px 15px 5px rgba(0,0,0,0)"}
                padding={tokens.space.large}
                marginTop={tokens.space.large}
            >
                <Heading level={1} paddingBottom={tokens.space.large}>
                    Edit Kids Information
                </Heading>
                {/* Change name */}
                <Flex alignItems={"center"}>
                    <label style={{ fontWeight: "bold" }}>Name</label>
                    <input value={localName} onChange={(e) => setLocalName(e.target.value)} />
                </Flex>

                {/* Change grade */}
                <Flex alignItems={"center"}>
                    <label style={{ fontWeight: "bold" }}>Grade</label>
                    <SelectField value={localGrade} onChange={(e) => setLocalGrade(e.target.value)} label={"grade"} labelHidden>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="5">6</option>
                    </SelectField>
                </Flex>

                {/* Change study buddy */}
                {/*<Flex direction={'column'} alignItems={'flex-start'} style={{position: 'relative', height: '200px', width: '100%'}}>*/}
                {/*    <label style={{fontWeight: 'bold', float: 'left'}}>Study Buddy</label>*/}
                {/*    <AvatarSelector selectedChar={localChar} setSelectedChar={setLocalChar} />*/}
                {/*</Flex>*/}

                {/* Save btn */}
                <Button onClick={onSubmitEdit} variation={"primary"}>
                    Save
                    {isSaving && <AiOutlineLoading3Quarters className={"loading"} />}
                </Button>
            </View>

            {user && (
                <View paddingTop={tokens.space.large}>
                    <ChildReportTable userData={userData} />
                </View>
            )}
        </>
    );
}

export default Index;
