import React, {useEffect, useState} from 'react';
import MultiSelect from "./MultiSelect";
import {useGridApiContext} from "@mui/x-data-grid-pro";

const MultiSelectInternalLinksBase = ({valueOptions, value, onValueChange, ...props}) => {
    const [internalLinkNames, setInternalLinkNames] = useState([])
    const [_value, _setValue] = useState([])

    // Solution to avoid same key error
    useEffect(() => {
        const names = new Set()
        valueOptions.map(valueOption => names.add(JSON.parse(valueOption).name))
        setInternalLinkNames([...names])
    }, [valueOptions])

    useEffect(() => {
        _setValue(value.map(v => v.name))
    }, [value])

    return (
        <MultiSelect
            valueOptions={internalLinkNames}
            value={_value}
            onValueChange={onValueChange}
            {...props}
        />
    );
}

export function MultiSelectInternalLinks({valueOptions, value, onValueChange, ...props}) {
    const internalLinks = valueOptions.map(valueOption => JSON.parse(valueOption))

    const setSelectedInternalLinks = (newValue) => {
        const selectedInternalLinks = []
        newValue.forEach(name => {
            internalLinks.forEach(internalLink => {
                if (internalLink.name === name) selectedInternalLinks.push(internalLink)
            })
        })
        onValueChange(selectedInternalLinks)
    }

    return (
        <MultiSelectInternalLinksBase
            valueOptions={valueOptions}
            value={value}
            onValueChange={setSelectedInternalLinks}
            {...props}
        />
    );
}

export const MultiSelectCellInternalLinks = ({id, value, field, onValueChange, valueOptions, isSearchable}) => {
    const apiRef = useGridApiContext();

    const internalLinks = valueOptions.map(valueOption => JSON.parse(valueOption))
    const selectedInternalLinkNames = value ? value.map(v => v.name) : []

    const setSelectedInternalLinks = (newValue) => {
        const selectedInternalLinks = []
        newValue.forEach(name => {
            internalLinks.forEach(internalLink => {
                if (internalLink.name === name) selectedInternalLinks.push(internalLink)
            })
        })

        onValueChange(selectedInternalLinks, true)
        apiRef.current.setEditCellValue({ id, field,
            value: selectedInternalLinks.map(internalLink => {
                const select = internalLinks.filter(i => i.name === internalLink.name)[0]
                return { name: select.name, url: select.url }
            }) });
    }

    return (
        <MultiSelectInternalLinksBase
            valueOptions={valueOptions}
            value={selectedInternalLinkNames}
            onValueChange={setSelectedInternalLinks}
            isSearchable={isSearchable}
        />
    );
}

export default MultiSelectInternalLinks;