import {Button, Flex, Heading, Image, Text, useTheme, View} from "@aws-amplify/ui-react";
import {useRef, useState} from "react";
import {LandingButton} from "../../themed";
import {useNavigate} from "react-router-dom";

export default function LandingScreenSample({samplesRef, plansRef, signupRef}) {
    const isIPadPortraitWidthOrLower = window.innerWidth < 835;
    const {tokens} = useTheme();
    const audioRef = useRef(new Audio('https://static1.squarespace.com/static/5da5fe56a176d745659b6aed/t/625e570f418a481f42d4362f/1650349844356/The+Super+Kind+Vox+Up.mp3/original/The+Super+Kind+Vox+Up.mp3'));
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const navigate = useNavigate();

    const sections = [{
        name: 'Math', activities: [{
            image: '/images/landing/activity-additions.png',
            text: "Children learn multiple strategies for solving similar types of addition problems. Feedback and tools are always a given.",
            id: "62142df6b5666a0019b07c96"
        }, {
            image: '/images/landing/activity-shapes.png',
            text: "Children discover the relationship between squares and triangles and have fun putting together digital tangram puzzles.",
            id: "60381cb9f334640d04092f15"
        }]
    }, {
        name: 'Language Arts & Reading', activities: [{
            image: '/images/landing/activity-rhyming-pizza.png',
            text: "Kids love the audible Rhyming Pizza activity with silly ingredients to add to the pizza while learning rhyming word families.",
            id: "620e99d623c3020012b27045"
        }, {
            image: '/images/landing/activity-book-club.png',
            text: "Have your kids watch our exclusive Reading  Series, Book Club with narrated fables and Greek myths. We model how good readers ask themselves questions to help guide comprehension and define vocabulary.",
            id: "646895483"
        }]
    }]

    return (<Flex
            ref={samplesRef}
            direction={'column'}
            alignItems={'center'}
            backgroundColor={'white'}
            paddingTop={30}
            paddingBottom={30}
        >
            <Image
                alt={'Brush stroke with title'}
                src='/images/landing/brush-stroke-sample-content.png'
                width={370}
            />
            <View
                paddingTop={tokens.space.large}
            >
                <Heading level={3} fontFamily={'muli, sans-serif'}>Music</Heading>
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    textAlign={'center'}
                    width={isIPadPortraitWidthOrLower ? '90vw' : '880px'}
                    wrap={isIPadPortraitWidthOrLower ? 'wrap' : 'nowrap'}
                >
                    <View position={'relative'}>
                        <Image
                            alt={'Superkind music cover'}
                            src={'/images/landing/activity-superkind.png'}
                            width={isIPadPortraitWidthOrLower ? '80%' : 800}
                        />
                        <Image
                            alt={'Headphones GIF'}
                            src={'/images/landing/headphones.gif'}
                            width={150}
                            position={'absolute'}
                            top={isIPadPortraitWidthOrLower ? 20 : -30}
                            right={isIPadPortraitWidthOrLower ? 20 : -100}
                        />
                        {!isAudioPlaying && <Image
                            alt={'Play button'}
                            src={'/images/landing/button-play.svg'}
                            width={150}
                            position={'absolute'}
                            bottom={-120}
                            right={isIPadPortraitWidthOrLower ? 165 : 0}
                            style={{
                                cursor: 'pointer', transform: isIPadPortraitWidthOrLower ? "translateX(50%)" : ''
                            }}
                            onClick={async () => {
                                await audioRef.current.play();
                                setIsAudioPlaying(true);
                            }}
                        />}
                        {isAudioPlaying && <Button
                            position={'absolute'}
                            right={isIPadPortraitWidthOrLower ? 165 : 0}
                            bottom={-20}
                            borderRadius={30}
                            backgroundColor={'#ff1616'}
                            border={'thick solid black'}
                            fontSize={'1.6rem'}
                            width={'150px'}
                            marginLeft={'20px'}
                            style={{
                                zIndex: 1, transform: isIPadPortraitWidthOrLower ? "translateX(50%)" : ''
                            }}
                            onClick={() => {
                                audioRef.current.pause();
                                setIsAudioPlaying(false);
                            }}
                        >
                            II Pause
                        </Button>}
                    </View>
                    <Text
                        paddingLeft={isIPadPortraitWidthOrLower ? 0 : 50}
                        paddingTop={isIPadPortraitWidthOrLower ? 120 : 0}
                    >
                        Listen to our original songs to make your kids dance and sing along with our downloadable lyric
                        sheets! And... our songs support your child's social emotional growth too!
                    </Text>
                </Flex>
            </View>
            <View
                paddingTop={isIPadPortraitWidthOrLower ? 50 : 100}
                paddingBottom={50}
                textAlign={'center'}
            >
                <Heading level={3} fontFamily={'muli, sans-serif'}>STEM & Building Activities</Heading>
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    textAlign={'center'}
                    width={isIPadPortraitWidthOrLower ? '90vw' : '880px'}
                    wrap={isIPadPortraitWidthOrLower ? 'wrap' : 'nowrap'}
                >
                    <View>
                        <Text>We offer offline activities too! Try our ideas for fun building activities like our Build
                            Your Own Playground. Try it with your class or your kids at home.</Text>
                        <LandingButton
                            text={'Sign up for our newsletter and FREEBIES!'}
                            margin={'30px auto 0px auto'}
                            colors={{background: '#44acee', font: 'white'}}
                            plansRef={signupRef}
                        />
                    </View>
                    <View position={'relative'} paddingTop={100}>
                        <Image
                            alt={'Playground cartoon'}
                            src={'/images/landing/playground.png'}
                            width={isIPadPortraitWidthOrLower ? '80%' : 1600}
                        />
                        <Image
                            alt={'Building GIF'}
                            src={'/images/landing/building.gif'}
                            width={150}
                            position={'absolute'}
                            top={isIPadPortraitWidthOrLower ? -50 : -200}
                            right={isIPadPortraitWidthOrLower ? 5 : -50}
                        />
                    </View>
                </Flex>
            </View>
            <Flex
                wrap={'wrap'}
                justifyContent={'center'}
                gap={isIPadPortraitWidthOrLower ? 40 : 125}
            >
                {sections.map(section => (<Flex
                        key={section.name}
                        direction={'column'}
                        width={isIPadPortraitWidthOrLower ? '90vw' : '430px'}
                        alignItems={'center'}
                        textAlign={'center'}
                        gap={30}
                    >
                        <Heading level={3} fontFamily={'muli, sans-serif'}>{section.name}</Heading>
                        {section.activities.map((activity, index) => (<Flex
                                key={index}
                                direction={'column'}
                                alignItems={'center'}
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                                    navigate(`/play/${activity.id}`);
                                }}
                            >
                                <Image
                                    alt={'Activity thumbnail image'}
                                    src={activity.image}
                                    width={'80%'}
                                />
                                <Text>{activity.text}</Text>
                            </Flex>))}
                    </Flex>))}
            </Flex>


            <LandingButton text={'Sign Up Now'} plansRef={plansRef} marginTop={70} marginBottom={30}/>
        </Flex>)
}