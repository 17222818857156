import React from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";
import SpeakerIcon from '@mui/icons-material/Replay';
import SpeechBubble from "./SpeechBubble";
import rehypeRaw from 'rehype-raw';
import { useNavigate } from 'react-router-dom';

const MarkdownRenderer = ({ markdownContent, context, sender, assistant, handleCloseDialog }) => {
    const [isGeneratingSpeech, setIsGeneratingSpeech] = React.useState(false);
    const navigate = useNavigate();

    // Function to handle full-screen toggle
    const toggleFullScreen = (event) => {
        const element = event.target;

        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            element.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        }
    }

    const style = sender === 'user' ? {} : {
        color: context.avatar.text_color,
        backgroundColor: context.avatar.text_background_color,
    };

    const messageToSpeak = () => {
        if (!markdownContent.includes("Try ["))
            return markdownContent;
        else
            return markdownContent.split(']')[0].replace('[', '');
    }

    return (
        <div
            className="markdown-container"
            style={style}
        >
            <ReactMarkdown
                children={markdownContent}
                rehypePlugins={[rehypeRaw]}
                components={{
                    code: CodeBlock,
                    img: ({node, ...props}) => {
                        return (
                            <img
                                {...props}
                                style={props.style || { width: '100%', cursor: 'zoom-in' }}
                                onClick={!props.href ? toggleFullScreen: ()=>{handleCloseDialog && handleCloseDialog();navigate(props.href)}}
                            />
                        )
                    }
                }}
            />
            {sender === 'assistant' && !isGeneratingSpeech &&
                <SpeakerIcon
                    sx={{cursor: 'pointer'}}
                    onClick={async () => {
                        setIsGeneratingSpeech(true);
                        await assistant.speak(messageToSpeak());
                        setIsGeneratingSpeech(false);
                    }}
                />
            }
            {isGeneratingSpeech &&
                <SpeechBubble />
            }
        </div>
    );
}

export default MarkdownRenderer;
