import React, {useCallback} from 'react';
import DialogueScene from "./DialogueScene";
import GradesCards from "../GradesCards";
import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";
import AudioRecorder from "../audio-recorder";

const GradesCardsContainer = styled(View)`
    margin-left: 10vw;
    width: 50%;
    height: 70%;
    position: absolute;
    z-index: 1;

    @media (max-width: 769px)  {
      width: calc(100% - 10vw - 2rem);
    }
`

function GradeSelectionScene({video, selectedGrade, setSelectedGrade, isWithBackButton, onBackClick, isVoiceControlled, sampleRate}) {
    const setGradeBySpeech = useCallback((text) => {
        if (text.includes('kindergarten')) setSelectedGrade('K')
        else if (text.includes('first') || text.includes('one')) setSelectedGrade('1')
        else if (text.includes('second') || text.includes('two')) setSelectedGrade('2')
        else if (text.includes('third') || text.includes('three')) setSelectedGrade('3')
        else if (text.includes('four')) setSelectedGrade('4')
        else if (text.includes('fifth') || text.includes('five')) setSelectedGrade('5')
        else if (text.includes('six')) setSelectedGrade('5')
    }, [selectedGrade, setSelectedGrade])

    return (
        <div style={{height: '100%', position: 'relative'}}>
            {isVoiceControlled ? (
                <AudioRecorder callbackFn={setGradeBySpeech} sampleRate={sampleRate} />
            ): (
                <GradesCardsContainer>
                    <GradesCards setSelectedGrade={setSelectedGrade} />
                </GradesCardsContainer>
            )}

            <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                <DialogueScene
                    dialgueVideo={video}
                    isWithBackButton={isWithBackButton}
                    onBackClick={onBackClick}
                    videoPos={isVoiceControlled ? 'center' : 'right'}
                />
            </div>
        </div>
    );
}

export default GradeSelectionScene;