import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PlayScreen from './screens/play-screen';
import AccountScreen from './screens/account-screen';
import BlogScreen from './screens/blog-screen';
import ContactScreen from './screens/contact-screen';
import PrivacyScreen from './screens/privacy-screen';
import TermsScreen from './screens/terms-screen';
import PlayScreenActivity from './screens/play-screen-activity';

import './fonts/Neue Kabel W01 Regular.ttf';
import BlogPostScreen from './screens/blog-post-screen';
import SubscriptionConfirmationScreen from './screens/subscription-confirmation-screen';

import awsExports from './aws-exports';
import Amplify from 'aws-amplify';
import { PageNotFoundScreen } from './screens/page-not-found';
import LandingScreen from './screens/landing';
import SubscriptionErrorScreen from './screens/subscription-error-screen';
import AdminEditor from "./screens/admin/editor";
import KidsZoneScreen from "./screens/kids-zone";
import Editor from "./screens/admin/editor";
import Problem from "./screens/problem";
import RestaurantScreen from "./screens/restaurant";
import RestaurantCode from "./screens/restaurant/code";
import StudyBuddy from "./screens/StudyBuddy";
import Home from "./screens/Home";
import ParentBuddy from "./screens/Onboarding/ParentBuddy";
import AccountInformation from "./screens/AccountInformation";
import SchoolScreen from "./screens/school";
import ParentsScreen from './screens/parents';
import PlaygroundScreen from './screens/playground';
import AuthenticatedRoute from "./components/AuthenticatedRoute";
Amplify.configure(awsExports);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={
        <AuthenticatedRoute
            component={<App screen={<KidsZoneScreen />} />}
            fallbackComponent={<App screen={<LandingScreen />} />}
        />
      } />

      <Route path="/home" element={<App screen={<Home />} />} />
      <Route path="/parents" element={<App screen={<ParentsScreen />} />} />
      <Route path="/playground" element={<App screen={<PlaygroundScreen />} />} />
      <Route path="/teachers" element={<App screen={<SchoolScreen />} />} />
      <Route path="/play" element={<App screen={<PlayScreen />} />} />
      <Route path="/play/:id" element={<App screen={<PlayScreenActivity />} />} />
      <Route path="/sample/:id" element={<App screen={<PlayScreenActivity isSample={true} />} />} />
      <Route path="/account" element={<App screen={<AccountScreen />} />} />
      <Route path="/accountInformation" element={<App screen={<AccountInformation />} />} />
      <Route path="/subscription-confirmation" element={<App screen={<SubscriptionConfirmationScreen />} />} />
      <Route path="/subscription-error" element={<App screen={<SubscriptionErrorScreen />} />} />
      <Route path="/blog" element={<App screen={<BlogScreen />} />} />
      <Route path="/blog/:id" element={<App screen={<BlogPostScreen />} />} />
      <Route path="/contact" element={<App screen={<ContactScreen />} />} />
      <Route path="/privacy" element={<App screen={<PrivacyScreen />} />} />
      <Route path="/terms" element={<App screen={<TermsScreen />} />} />
      <Route path="/kids-zone" element={<App screen={<KidsZoneScreen />} />} />
      <Route path="/studyBuddy" element={<App screen={<StudyBuddy />} />} />
      <Route path="/onboarding/parentBuddy" element={<App screen={<ParentBuddy />} />} />
      <Route path={'/admin/editor'} element={<AdminEditor /> } />
      <Route path={'/editor'} element={<Editor /> } />
      <Route path={'/problem'} element={<App screen={<Problem />} />} />
      <Route path={'/restaurant'} element={<RestaurantScreen />} />
      <Route path={'/restaurant/code'} element={<App screen={<RestaurantCode />} />} />
      <Route path={'/restaurant/:name'} element={<RestaurantScreen />} />

      {/* 👇️ only match this when no other routes match */}
      <Route path="*" element={<App screen={<PageNotFoundScreen />} />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
