import {useState, useEffect} from 'react';
import {StepType} from "../constants";

const useDialogue = (char, steps, step) => {
    const [dialogue, setDialogue] = useState({
        texts: [],
        isWithContinue: false,
        isAutoPlayEnabled: false,
        delay: -1
    });

    useEffect(() => {
        const currentStep = steps.data.find(s => s.step === step);
        const texts = currentStep?.contentString[char] || [];

        if (currentStep) {
            if (currentStep.type === StepType.Rest || currentStep.type === StepType.ParentTutorial) {
                setDialogue(null)
            } else {
                setDialogue({
                    texts: texts,
                    isWithContinue: currentStep.isWithContinue,
                    isAutoPlayEnabled: currentStep.isAutoPlayEnabled,
                    isVoiceControlled: currentStep.isVoiceControlled,
                    delay: currentStep.delayMs ? currentStep.delayMs : -1
                })
            }
        }
    }, [step, char, steps])

    return dialogue;
};

export default useDialogue;
