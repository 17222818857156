import { Flex, Heading, Image, View } from "@aws-amplify/ui-react";
import { FlexCenteredColumn } from "../../themed";
import { useContext, useEffect } from "react";
import { TutorialContext } from "../Onboarding/TutorialWrapper";
import GradesCards from "../../components/GradesCards";
import { useLocation } from "react-router-dom";
import SearchBar from "../../components/search-bar";
import { Grid } from "@mui/material";
import { useMobile } from "../../hooks/useMobile";

export default function KidsZoneGrades({
  setSelectedGrade,
  setSearchTerm,
  activities,
}) {
  const location = useLocation();

  const { setRun, setIsTourActive, setIsParentRun, setStepIndex } =
    useContext(TutorialContext);

  const { isMobile } = useMobile();

  useEffect(() => {
    if (location.state && location.state.from === "ParentTutorialScene") {
      setIsParentRun(true);
      setStepIndex(0);
      setRun(true);
      setIsTourActive(true);
    } else if (location.state && location.state.from === "KidsTutorialScene") {
      setIsParentRun(false);
      setStepIndex(0);
      setRun(true);
      setIsTourActive(true);
    }
  }, [location.state, setIsParentRun, setIsTourActive, setRun]);

  return (
    <div id={"step-0"} onClick={() => setRun(false)}>
      <Flex
        direction={isMobile ? "column" : "row"}
        wrap={isMobile ? "wrap" : "nowrap"}
      >
        <FlexCenteredColumn width={isMobile ? "100vw" : "30vw"}>
          <Image
            alt={"Green Glow"}
            src={"/images/kids-zone/green-glow.svg"}
            width={"80%"}
          />
          <Image
            alt={"Robot"}
            src={"/images/kids-zone/WavingAnim.gif"}
            width={"60%"}
            position={"absolute"}
            top={isMobile ? 92 : "30%"}
            right={isMobile ? 80 : 0}
            style={{
              zIndex: 100,
            }}
          />
          <Heading level={3} fontWeight={800} marginTop={isMobile ? 0 : 100}>
            How to have fun:
          </Heading>
          <Heading level={3}>First... choose your grade.</Heading>
          <Grid padding={1} display={"flex"} justifyContent={"center"}>
            <SearchBar
              maxWidth={"100vw"}
              upperLabel=""
              width={"90%"}
              isMobile={isMobile}
              activities={activities.data}
              setSearchTerm={setSearchTerm}
              isStars={!isMobile}
            />
          </Grid>
        </FlexCenteredColumn>

        <View
          width={isMobile ? "100vw" : "68vw"}
          height={'calc(100vh - 80px)'}
          paddingBottom={1}
        >
      
          <GradesCards
            isMobile={isMobile}
            setSelectedGrade={setSelectedGrade}
          />
        </View>
      </Flex>
    </div>
  );
}
