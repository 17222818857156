import {Flex, Heading, Image, Text, View} from "@aws-amplify/ui-react";

export default function LandingScreenPriceCard(props) {
    const {title, price, label, showPointer, showDiscount} = props

    return (<Flex
        padding={'20px'}
        direction={'column'}
        alignItems={'center'}
        width={300}
        height={300}
        style={{
            cursor: 'pointer', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
        }}
        position={'relative'}
        backgroundImage={"url('/images/landing/diamond.png')"}
        onClick={() => window.location.href = props.link}
    >
        <Heading level={2} paddingTop={30} fontFamily={'muli, sans-serif'} fontSize={'1.6em'}>
            {title}
        </Heading>
        <Text paddingTop={'20px'} paddingBottom={'20px'}>{price}</Text>
        {label && <Heading level={3} color={'#ff1616'} fontFamily={'muli, sans-serif'} paddingBottom={'20px'}>
            {label}
        </Heading>}
        {showPointer && <Image
            alt={'Click here GIF'}
            position={'absolute'}
            bottom={-40}
            right={'42%'}
            width={'50px'}
            src={'/images/landing/clickhere.gif'}
        />}
        {showDiscount && <View
            backgroundImage={"url('/images/landing/shape-star.png')"}
            position={'absolute'}
            bottom={'-55px'}
            right={'-5px'}
            borderRadius={'30px'}
            padding={'20px'}
            width={'200px'}
            height={'200px'}
            style={{
                backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', zIndex: 1
            }}
        />}
    </Flex>)
}