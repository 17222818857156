import { useEffect, useState } from 'react';
import getActivities from "../helpers/getActivities";

export default function useActivities() {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    getActivities().then((data) => setActivities(data));
  }, []);

  return activities;
}
