import React, { useEffect, useState } from 'react';
import RestaurantGrid from './grid';
import {Flex, Image, Link, Text} from "@aws-amplify/ui-react";
import {useLocation, useNavigate} from "react-router-dom";
import { initializeGA4 } from '../../helpers/ga4';

initializeGA4('G-CKFW907T7J');

export default function RestaurantScreen() {
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const restaurantName = queryParams.get('name');

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    useEffect(() => {
        if (!restaurantName)
            return;

        navigate(`/restaurant/${restaurantName}`, {replace: true});
    }, [restaurantName]);

    const isMobile = width <= 768;

    return (
        <>
            <Flex
                direction={'row'}
                alignItems={'center'}
                paddingBottom={'0.5em'}
            >
                <Image
                    alt={'Animated logo'}
                    src="/images/logo.gif"
                    height={66}
                    minWidth={isMobile ? '32vw' : 200}
                    objectFit={'contain'}
                    paddingLeft={10}
                    onClick={() => navigate(`/${location.search}`)}
                    style={{ cursor: 'pointer' }}
                />
                <div style={{ borderLeft: '3px solid black', paddingLeft: '10px' }}>
                    <Text
                        style={{
                            fontStyle: 'italic',
                            fontSize: '24px',
                            color: '#555',
                            lineHeight: '1.5',
                            quotes: '"\\201C" "\\201D"',
                            margin: '10px 0',
                            paddingLeft: '10px',
                        }}
                    >
                        We empower children with the tools and strategies they need to excel in school and beyond.

                        <Link
                            href={`/${location.search}`}
                            marginLeft={'0.5em'}
                        >Learn more</Link>
                    </Text>
                </div>
            </Flex>
            <RestaurantGrid />
        </>
    );
}
