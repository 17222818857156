import React, { forwardRef, useRef, useState } from "react";
import { BackButton } from "../../themed";
import CharacterAnimation from "../ChatBot/CharacterAnimation";
import { Grid, useMediaQuery } from "@mui/material";
import styled from "styled-components";

const StyledVideo = styled.video`
  height: 100%;
  position: ${props => props.videoPos === 'right' ? 'absolute' : 'relative'};
  margin-right: ${props => props.videoPos === 'right' ? '-10rem' : 0};
  
  @media only screen and (max-height: 580px) {
    margin-right: ${props => props.videoPos === 'right' ? '-5rem' : 0};
  }
`

const VIDEO_CHARACTERS = ['Pam'];

const getCharacterOrVideoComponent = (selectedChar, dialgueVideo, videoPos, animationName, ref) => {
    const isVideoCharacter = VIDEO_CHARACTERS.includes(selectedChar);

    return isVideoCharacter
        ? <StyledVideo src={dialgueVideo} ref={ref} autoPlay videoPos={videoPos} />
        : <CharacterAnimation characterId={selectedChar} animationName={animationName} />;
};

const DialogueScene = forwardRef(
    (
        {
            dialgueVideo,
            isWithBackButton,
            onBackClick,
            videoPos = "center",
            selectedChar,
            isAudio=true
        },
        ref
    ) => {
        const audioRef = useRef(null);
        const [animationName, setAnimationName] = useState("talking");

        const handleAudioEnded = () => setAnimationName("idle");
        const isSmallHeight = useMediaQuery("(max-height: 864px)");

        return (
            <>
                {isAudio && <audio
                    ref={audioRef}
                    src={dialgueVideo}
                    onEnded={handleAudioEnded}
                    autoPlay={true}
                />}
                <Grid height={"100%"} position={isSmallHeight ? "relative" : undefined}>
                    <Grid
                        style={{
                            height: "10%",
                            zIndex: isSmallHeight ? 100 : 1,
                            position: isSmallHeight ? "absolute" : undefined,
                            width: "100%",
                            justifyContent: "flex-start",
                            display: "flex",
                        }}
                    >
                        {isWithBackButton && (
                            <BackButton
                                onClickHandler={onBackClick}
                                style={{
                                    position: "absolute",
                                    paddingLeft: "1rem",
                                    zIndex: 100,
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            height: "100%",
                            position: isSmallHeight ? "absolute" : undefined,
                            width: "100%",
                            display: "flex",
                            justifyContent: videoPos === 'right' ? 'flex-end' : 'center',
                            overflowX: "hidden",
                        }}
                    >
                        {
                            getCharacterOrVideoComponent(selectedChar, dialgueVideo, videoPos, animationName, ref)
                        }
                    </Grid>
                </Grid>
            </>
        );
    }
);

export default DialogueScene;
