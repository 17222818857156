import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
    apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
});
delete configuration.baseOptions.headers['User-Agent'];

const openai = new OpenAIApi(configuration);

const getDescriptionFromKeywords = async (keywords) => {
    const prompt = `Can you generate a 3-line description from these keywords:\n ${keywords.toString()}`

    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: prompt }],
    });

    return completion.data.choices[0].message.content
}

export default getDescriptionFromKeywords