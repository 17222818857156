import React, {useEffect, useRef, useState} from "react";
import {Flex, useAuthenticator, View} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

import Top from "./top";
import Mistakes from "./mistakes";
import Cards from "./cards";
import ActivityTypes from "./activityTypes";
import Team from "./team";
import Testimonials from "./testimonials";
import Plans from "./plans";
import About from "./about";
import EmailSignup from "./email-signup";
import Banner from "./banner";
import KidsTeachKids from "./kids-teach-kids";
import Awards from "./awards";
import Sample from "./sample";
import LandingScreenTeachers from "./teachers";

export default function LandingScreen({aboutRef}) {
    const plansRef = useRef(null);
    const samplesRef = useRef(null);
    const signupRef = useRef(null);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `var _ctct_m = "0991c274b8198d44480604829b979102";`;
        script.id = 'signupScript';
        script.src = '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup function: remove the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    const isMobile = width < 769

    return (<>
        <Flex direction={'column'} gap={0}>
            <Top isMobile={isMobile}/>

            <ActivityTypes samplesRef={samplesRef}/>
            <Mistakes plansRef={plansRef} isMobile={isMobile}/>
            <KidsTeachKids plansRef={plansRef} isMobile={isMobile}/>

            <About plansRef={plansRef} aboutRef={aboutRef} isMobile={isMobile}/>
            <Awards />
            <Testimonials plansRef={plansRef} isMobile={isMobile}/>
            <Team plansRef={plansRef}/>

            <Banner
                isMobile={isMobile}
                staticText={"TeachMe TV is for "}
                dynamicText={["kids.", "parents.", "teachers.", "kids. Wait, did we mention that already?",]}
            />

            <Cards plansRef={plansRef} isMobile={isMobile}/>
            <EmailSignup signupRef={signupRef} isMobile={isMobile}/>
            <Sample plansRef={plansRef} samplesRef={samplesRef} signupRef={signupRef} isMobile={isMobile}/>
            <LandingScreenTeachers />
            <Plans plansRef={plansRef} isMobile={isMobile}/>
        </Flex>
    </>)
}