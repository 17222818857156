import { useState, useEffect } from 'react';

export const useMobile = () => {
    const [width, setWidth] = useState(window.innerWidth );

    const handleWindowSizeChange = () => setWidth(window.innerWidth );

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    const isMobile = width < 769;

    return { isMobile, width };
};
