import React from 'react';
import DialogueScene from "./DialogueScene";
import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";

const RecommendedActivityContainer = styled(View)`
    margin-left: calc(2rem + 80px); 
    width: 50%;
    height: 70%; 
    position: absolute; 
    z-index: 1;
    display: flex; 
    align-items: center;
  
    @media only screen and (max-width: 820px) {
      margin: 0 2rem;
      width: calc(100% - 4rem);
    }
`

const RecommendedActivity = styled(View)`
    width: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    @media only screen and (max-width: 820px) {
      background: rgba(255, 255, 255, 0.90);
      backdrop-filter: blur(8px);
      padding: 2rem 0;
    }
`

function RecommendationConfirmationScene({activity, video, onBackClick}) {
    return (
        <div style={{height: '100%', position: 'relative'}}>
            <RecommendedActivityContainer>
                <RecommendedActivity>
                    <p style={{fontSize: '1rem', margin: 0, fontWeight: 700}}>{activity.name}</p>
                    <img
                        alt={activity.name}
                        src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png`}
                        style={{
                            width: '80%',
                            aspectRatio: '16/9',
                            borderRadius: 20,
                            objectFit: 'cover',
                            objectPosition: '50% 50%',
                            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)'
                        }}
                    />
                </RecommendedActivity>
            </RecommendedActivityContainer>

            <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                <DialogueScene
                    dialgueVideo={video}
                    videoPos={'right'}
                    isWithBackButton
                    onBackClick={onBackClick}
                />
            </div>
        </div>
    );
}

export default RecommendationConfirmationScene;