import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Image} from "@aws-amplify/ui-react";
import styled from "styled-components";
import {handleScheduleDemo} from "./schedule-demo";

const StyledButton = styled(Button)({
    "&&": {
        transition: "0.5s",

        "&:hover": {
            boxShadow: "none",
            transform: "scale(1.08)",
        },
    },
});

const SchoolScreenIntro = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            container
            spacing={8}
            sx={{
                backgroundColor: "rgb(12 68 153)",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "48px",
                marginTop: "1px",
            }}
        >
            <Grid item md={5} sm={12} xs={12}>
                <Image
                    alt={"teach-me-tv-logo"}
                    src={"/images/schools-screen/teach-me-tv-logo.webp"}
                />
                <Typography
                    variant="h3"
                    style={{
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: isMobile ? '2.25rem' : '3rem', // Smaller size on mobile
                        textAlign: "left",
                    }}
                >
                    Helping your teachers and students succeed just got easier.
                </Typography>
                <Grid display={"flex"} justifyContent={"center"} marginTop={5}>
                    <StyledButton onClick={handleScheduleDemo}>
                        <Image
                            src={"/images/schools-screen/schedule-demo.svg"}
                            alt="Schedule Demo"
                            width={"300px"}
                        />
                    </StyledButton>
                </Grid>
            </Grid>
            <Grid
                item
                md={7}
                sm={12}
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{marginBottom: {xs: "35px"}}}
            >
                <Image
                    alt={"Brush stroke newsletter mobile"}
                    src="/images/schools-screen/student-teacher.webp"
                />
            </Grid>
        </Grid>
    );
};

export default SchoolScreenIntro;
