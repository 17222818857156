import React, {useCallback} from 'react';
import AudioRecorder from "../audio-recorder";
import EmojiTextCards from "../EmojiTextCards";
import DialogueScene from "./DialogueScene";
import {FEEDBACK} from "../../constants";
import EmotionCardsContainer from "../EmotionCardsContainer";

function FeedbackSelectionScene({isVoiceControlled, video, setFeedback, sampleRate}) {
    const setFeedbackBySpeech = useCallback((text) => {
        if (text.includes('fun')) setFeedback('fun')
        else if (text.includes('ok')) setFeedback('tired')
        else if (text.includes('tire')) setFeedback('silly')
    }, [setFeedback])

    return (
        <div style={{height: '100%', position: 'relative'}}>
            { isVoiceControlled && <AudioRecorder callbackFn={setFeedbackBySpeech} sampleRate={sampleRate} />}
            <EmotionCardsContainer>
                <EmojiTextCards data={FEEDBACK} setSelectedEmoji={setFeedback}/>
            </EmotionCardsContainer>

            <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                <DialogueScene
                    dialgueVideo={video}
                    isWithBackButton={false}
                    videoPos={'right'}
                />
            </div>
        </div>
    );
}

export default FeedbackSelectionScene;