import {Flex, Heading, Image, useTheme, View} from "@aws-amplify/ui-react";
import {LandingButton} from "../../themed";

export default function LandingScreenQuestions(props) {
    const {tokens} = useTheme();

    const KidStar = () => (<Image
            alt={'Kid reaching for the stars'}
            src={'/images/landing/kid-star.jpg'}
            width={props.isMobile ? '100%' : '49%'}
            objectFit={'contain'}
        />)

    const Questions = () => (<Flex
            width={props.isMobile ? '100%' : '49%'}
            paddingTop={'20px'}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Heading
                level={2}
                textAlign={'center'}
                paddingTop={'20px'}
                paddingBottom={'20px'}
                fontFamily={'muli, sans-serif'}
                fontSize={'calc(12px + 2.5vw)'}
            >
                Children are continually learning.
            </Heading>
            <Heading
                level={3}
                textAlign={'center'}
                paddingTop={'20px'}
                paddingBottom={'20px'}
                fontFamily={'muli, sans-serif'}
                fontSize={'calc(12px + 1.5vw)'}
            >
                Everything they see, everything they hear contributes to who they'll be.
            </Heading>
            <Flex
                paddingTop={'40px'}
                paddingBottom={'80px'}
                position={'relative'}
            >
                <Image
                    alt={'Clouds'}
                    position={'absolute'}
                    margin={'auto'}
                    top={150}
                    bottom={0}
                    left={0}
                    right={100}
                    width={'60%'}
                    opacity={0.2}
                    src={'/images/landing/clouds.svg'}
                />
                <Heading
                    level={2}
                    textAlign={'center'}
                    position={'relative'}
                    fontFamily={'muli, sans-serif'}
                    fontSize={'calc(12px + 2vw)'}
                    style={{zIndex: 2}}
                >
                    What will your child learn?
                </Heading>
            </Flex>
            <LandingButton text={'Sample Content'} colors={{background: '#44acee', font: 'white'}}
                           plansRef={props.samplesRef} marginBottom={tokens.space.large}/>
        </Flex>)

    return (<View
            position={'relative'}
            backgroundColor={'white'}
        >
            <Flex wrap={'wrap'}>
                {props.isMobile && <>
                    <Questions/>
                    <KidStar/>
                    <Image
                        alt={'Question mark GIF'}
                        position={'absolute'}
                        margin={'auto'}
                        bottom={0}
                        right={0}
                        width={'150px'}
                        src={'/images/landing/question.gif'}
                    />
                </>}
                {!props.isMobile && <>
                    <KidStar/>
                    <Image
                        alt={'Question mark GIF'}
                        position={'absolute'}
                        margin={'auto'}
                        top={0}
                        bottom={0}
                        left={0}
                        right={150}
                        width={'150px'}
                        src={'/images/landing/question.gif'}
                    />
                    <Questions/>
                </>}
            </Flex>
        </View>)
}