import getActivities, {getFilteredActivitiesByGrade} from "./getActivities";
import _ from 'lodash';
import {getActivityHistory} from "./getActivityHistory";

const EMOTION_TO_ACTIVITY = {
    ok: ['music', 'crossword', 'puzzle', 'math', 'word ladder', 'mystery', 'game', 'book'],
    tired: ['Grow', 'Beboppin', 'yoga', 'dance', 'game', 'book'],
    silly: ['cartoon', 'bounce', 'emoji', 'exercise', 'karate', 'martial arts', 'game', 'book'],
    mad: ['book club', 'anger management', 'emotional development', 'exercise', 'dance party', 'game', 'book']
}

const getFilterPdfActivites = (activities) => activities.filter(activity => activity.type !== 'printable');

const getFilteredActivitiesByEmotion = (activities, emotion) => {
    const filteredActivities = activities.filter(a => {
        for (const e of EMOTION_TO_ACTIVITY[emotion]) {
            const name = a.name.toLowerCase()
            const type = a.type.toLowerCase()
            const keywords = a.keywords.join(' ').toLowerCase()
            if (name.includes(e) || type.includes(e) || keywords.includes(e)) return true
        }
        return false
    })
    return filteredActivities
}

const getFilteredActivityByHistory = (activities, history) =>
    activities.filter(activity => !history.some(h => h.activityID === activity.id))

// TODO: Support for variations of spelling for subjects
const getFilteredActivitiesBySubjectsToInclude = (activities, subjectsToInclude) => {
    if (!subjectsToInclude || subjectsToInclude.length === 0)
        return activities;

    const lowercaseSubjects = subjectsToInclude.map(subject => subject.toLowerCase());

    const filteredActivities = activities.filter(activity =>
        activity.subjects.some(subject =>
            lowercaseSubjects.includes(subject.toLowerCase())
        )
    );

    return filteredActivities;
};

const getFilteredActivitiesBySubjectsToExclude = (activities, subjectsToExclude) => {
    if (!subjectsToExclude || subjectsToExclude.length === 0)
        return activities;

    const lowercaseSubjects = subjectsToExclude.map(subject => subject.toLowerCase());

    const filteredActivities = activities.filter(activity =>
        activity.subjects.every(subject =>
            !lowercaseSubjects.includes(subject.toLowerCase())
        )
    );

    return filteredActivities;
};

const groupActivitiesByType = (activities) => {
    return activities.reduce((acc, activity) => {
        if (!acc[activity.type]) {
            acc[activity.type] = [];
        }
        acc[activity.type].push(activity);
        return acc;
    }, {});
};

const getDistinctTypeActivities = (groupedActivities, limit) => {
    let recommendations = [];
    while (recommendations.length < limit && Object.keys(groupedActivities).length) {
        for (let type in groupedActivities) {
            if (groupedActivities[type].length) {
                recommendations.push(groupedActivities[type].shift()); // push and remove the activity
            } else {
                delete groupedActivities[type];
            }
            if (recommendations.length === limit) {
                break;
            }
        }
    }
    return recommendations;
};

// Get recommendation from given grade, emotion, or asking message
// Limit used to limit the number of recommended activities
export const getRecommendations = async (user, grade, emotion, limit = 3, subjectsToInclude = [], subjectsToExclude = [], isOnboarding = false) => {
    const activities = await getActivities();
    let filteredActivities = getFilteredActivitiesByGrade(activities, grade);
    filteredActivities = getFilteredActivitiesByEmotion(filteredActivities, emotion);
    filteredActivities = getFilteredActivitiesBySubjectsToInclude(filteredActivities, subjectsToInclude);
    filteredActivities = getFilteredActivitiesBySubjectsToExclude(filteredActivities, subjectsToExclude);
    if(isOnboarding) {
        filteredActivities = getFilterPdfActivites(filteredActivities);
    }

    const history = await getActivityHistory(user);
    const filteredActivitiesByHistory = _.shuffle(getFilteredActivityByHistory(filteredActivities, history));

    // TODO: If no subject has been specified, don't just suggest activities of the same subject

    const groupedActivities = groupActivitiesByType(filteredActivitiesByHistory);
    const distinctRecommendations = getDistinctTypeActivities(groupedActivities, limit);
    const numRecommendations = distinctRecommendations.length;

    if (numRecommendations >= limit) {
        return distinctRecommendations;
    } else {
        filteredActivities = filteredActivities.filter(activity => !distinctRecommendations.some(h => h.id === activity.id));
        return [...filteredActivitiesByHistory, ...filteredActivities.slice(0, limit - numRecommendations)];
    }
}