import {Button, Flex, Text, useAuthenticator, useTheme} from "@aws-amplify/ui-react";
import {FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa';
import {useNavigate} from "react-router-dom";
import {FlexCenteredColumn} from "../themed";

export default function BaseFooter({isMobile}) {

    const {tokens} = useTheme();
    const navigate = useNavigate();
    const {user} = useAuthenticator((context) => [context.user]);

    const iconSize = isMobile ? 48 : 64;

    return (
        <Flex
            direction={{base: 'row'}}
            justifyContent='space-between'
            style={styles.footer}
        >
            {/* INTERNAL LINKS */}
            <Flex direction={'column'} alignItems='flex-start' textAlign={'left'} padding={tokens.space.large}>
                {user &&
                    <Button fontSize={'14pt'} padding={tokens.space.zero} variation="link" onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                        navigate("/accountInformation")
                    }}>Account</Button>
                }
                <Button fontSize={'14pt'} padding={tokens.space.zero} variation="link" onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                    navigate("/contact")
                }}>Contact</Button>
                <Button fontSize={'14pt'} padding={tokens.space.zero} variation="link" onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                    navigate("/blog")
                }}>Blog</Button>
                <Button fontSize={'14pt'} padding={tokens.space.zero} variation="link" onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                    navigate("/privacy")
                }}>Privacy Policy</Button>
                <Button fontSize={'14pt'} padding={tokens.space.zero} variation="link" onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                    navigate("/terms")
                }}>Terms and Conditions</Button>
            </Flex>

            {/* Social Media */}
            <FlexCenteredColumn>
                <Text>Follow us:</Text>
                <Flex alignItems='flex-start' paddingRight={tokens.space.large} wrap={'wrap'}>
                    <Button variation="link" as={FaInstagram} width={iconSize} height={iconSize}
                            onClick={() => window.location.href = 'https://www.instagram.com/teachmetv/'}/>
                    <Button variation="link" as={FaFacebook} width={iconSize} height={iconSize}
                            onClick={() => window.location.href = 'https://www.facebook.com/TeachMeTv'}/>
                    <Button variation="link" as={FaLinkedin} width={iconSize} height={iconSize}
                            onClick={() => window.location.href = 'https://www.linkedin.com/company/teachme-tv-llc/?viewAsMember=true'}/>
                </Flex>
            </FlexCenteredColumn>
        </Flex>
    )
}

const styles = {
    footer: {
        padding: '10px',
        height: '230px',
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        borderTop: 'thin solid black',
        backgroundColor: 'white'
    }
}