import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React, { createContext, useEffect, useRef, useState } from "react";
import "./App.css";
import BaseFooter from "./components/base-footer";
import Header from "./components/Header";
import ScrollToTop from "./components/scroll-to-top";
import theme from "./custom-theme";
import LandingScreen from "./screens/landing";
import ChatBot from "./components/ChatBot";
import context from "./components/ChatBot/data/sona.json";
import TutorialWrapper from "./screens/Onboarding/TutorialWrapper";

export const AudioContext = createContext();
export const DeviceContext = createContext();

export default function App({ screen }) {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => window.removeEventListener("resize", handleWindowSizeChange);
    }, []);

    const isMobile = width < 769;

    const audioRef = useRef(null);
    const aboutRef = useRef(null);
    const navigationProps = {};
    // if (screen.type === LandingScreen)
    navigationProps["aboutRef"] = aboutRef;

    const VOListenedTo = [];

    return (
        <Authenticator.Provider>
            <ChatBot defaultContext={context} />

            <ThemeProvider theme={theme}>
                <TutorialWrapper>
                    <DeviceContext.Provider value={{ isMobile: width < 769 }}>
                        <AudioContext.Provider
                            value={{
                                audioRef: audioRef,
                                playVO: (sound) => {
                                    return new Promise((resolve, reject) => {
                                        if (!audioRef.current) {
                                            reject(new Error("Audio element not found"));
                                            return;
                                        }

                                        if (VOListenedTo.includes(sound)) {
                                            resolve();
                                            return;
                                        }

                                        if (!sound) {
                                            audioRef.current.pause();
                                            audioRef.current.currentTime = 0;
                                            resolve();
                                            return;
                                        }

                                        VOListenedTo.push(sound);

                                        audioRef.current.src = `/audio/${sound}.mp3`;
                                        audioRef.current.addEventListener("ended", () => resolve());
                                        audioRef.current.addEventListener("error", () => reject(new Error("Audio playback error")));
                                        audioRef.current.play();
                                    });
                                },
                            }}
                        >
                            <audio ref={audioRef} src=""></audio>
                            <div className="App" style={{ position: "relative", minHeight: "100vh", paddingBottom: "246px" }}>
                                <Header {...navigationProps} />
                                <ScrollToTop />
                                {React.cloneElement(screen, {
                                    aboutRef: aboutRef,
                                    audioRef: audioRef,
                                })}
                                <BaseFooter isMobile={isMobile} />
                            </div>
                        </AudioContext.Provider>
                    </DeviceContext.Provider>
                </TutorialWrapper>
            </ThemeProvider>
        </Authenticator.Provider>
    );
}
