import {Button, Heading, useTheme, View} from '@aws-amplify/ui-react';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {EmailSignUp} from "../components/emailSignUp";

export default function BlogPostScreen() {
    const {id} = useParams();
    const {tokens} = useTheme();

    const [content, setContent] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${id.split('-').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')} - TeachMe TV`;

        if (!content)
            fetchContent();
    }, []);

    const fetchContent = async () => {
        setContent(await (await fetch(`${process.env.PUBLIC_URL}/blog-content/${id}.html`)).text());
        setIsLoaded(true);
    }

    const css = `
        h1 {
            font-size: 1.8em;
        }
        h1.entry-title {
            font-size: 1.8em;
            text-align: center;
        }
    `;

    return (
        <View
            width={'1024px'}
            maxWidth={'90vw'}
            margin={'auto auto'}
            textAlign={'left'}
            paddingBottom={tokens.space.xxxl}
        >
            <style>{css}</style>
            {content && <div dangerouslySetInnerHTML={{__html: content}} style={{paddingBottom: tokens.space.large}}/>}
            {!content && isLoaded && <Heading level={1}>Blog post not found</Heading>}

            <EmailSignUp/>

            <center>
                <Button
                    marginTop={tokens.space.xl}
                    variation='link'
                    onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                        navigate('/blog');
                    }}
                >
                    Back to Blog
                </Button>
            </center>
        </View>
    )
}