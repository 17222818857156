import React, {useContext, useEffect} from 'react';
import {Box, Modal} from "@mui/material";
import steps from "../../data/feedbackSteps.json";
import _ from "lodash";
import DialogueBox from "../../components/Scene/DialogueBox";
import {useNavigate} from "react-router-dom";
import useStepChange from "../../hooks/useStepChange";
import useDialogue from "../../hooks/useDialogue";
import useRecommendations from "../../hooks/useRecommendations";
import SceneRenderer from "../../components/Scene/SceneRenderer";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import useMic from "../../hooks/useMic";
import {StepType} from "../../constants";
import {TutorialContext} from "../Onboarding/TutorialWrapper";

const CHECK_CONTINUE_ACTIVITY_STEP = steps.data.filter(d => d.type === StepType.CheckStartActivities)[0]
const CONTINUE_ACTIVITY_STEP = CHECK_CONTINUE_ACTIVITY_STEP.step + 0.1
const MAX_REPEAT = 1

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    border: '2px solid var(--orange, #F7941D)',
    background: '#fff',
    backdropFilter: 'blur(8px)',
};

function FeedbackModal({isOpen, setIsOpen}) {
    const navigate = useNavigate()

    const {
        user,
        videoRef,
        name,
        selectedChar,
        selectedGrade,
        selectedEmotion,
        setSelectedEmotion,
        selectedActivity,
        setSelectedActivity,
        isAutoPlay,
        setIsAutoPlay,
        isVoiceControlled,
        setIsVoiceControlled,
        feedback,
        setFeedback,
        startActivities,
        setStartActivities
    } = useStudyBuddyState();

    // FIXME: Temporary solution for recommendation to work in the new feedback modal sequence
    useEffect(() => {
        setSelectedEmotion('tired')
    }, [setSelectedEmotion])

    const [step, setStep] = useStepChange(true, feedback, user, name, selectedChar, selectedGrade, selectedEmotion, selectedActivity, startActivities);
    const dialogue = useDialogue(selectedChar, steps, step);
    const {recommendations, setRecommendations} = useRecommendations(user, selectedGrade, selectedEmotion);
    const sampleRate = useMic(isVoiceControlled, setIsVoiceControlled)

    const {kidsRepeat, setKidsRepeat} = useContext(TutorialContext)

    useEffect(() => {
        if (kidsRepeat === MAX_REPEAT) {
            setStep(CONTINUE_ACTIVITY_STEP)
        }
    }, [kidsRepeat, setStep])

    const cleanUp = () => {
        setIsOpen(false)
        setStep(0)
        setFeedback('')
        setSelectedEmotion('')
        setRecommendations([])
        setSelectedActivity({})
    }

    const onClickContinue = () => {
        if (step - 1 === CHECK_CONTINUE_ACTIVITY_STEP.step && startActivities) {
            navigate('/kids-zone', { state: { from: 'KidsTutorialScene' } });
        } else if (step === CONTINUE_ACTIVITY_STEP) {
            navigate('/kids-zone', { state: { from: 'KidsTutorialScene' } });
        } else if (!_.isEmpty(selectedActivity)) {
            setKidsRepeat(prev => prev + 1)
            if (selectedActivity.type === 'printable' && selectedActivity.url) {
                window.location.href = selectedActivity.url
            } else {
                navigate(`/play/${selectedActivity.id}`, {state: {fromLink: '/studyBuddy'}})
                cleanUp()
                window.scrollTo(0, 0)
            }
        } else if (selectedEmotion === 'ok') {
            navigate(`/kids-zone?grade=${selectedGrade}`, { state: { from: 'KidsTutorialScene' } });
        } else {
            setStep(Math.floor(step + 1))
        }
    }

    const onBackClick = (s) => {
        const prevStep = steps.data.filter(d => d.step === s.prevStep)[0]
        if (prevStep.type === StepType.CheckStartActivities) {
            setStartActivities(null)
        }
        setStep(s.prevStep)
    }

    return (
        <Modal
            open={isOpen}
            aria-labelledby="feedback modal"
            aria-describedby="provide feedback by voice or buttons"
            onClose={() => {
            }}
        >
            <Box sx={style}>
                {steps.data.map(s => {
                    if (s.step === step) {
                        return (
                            <SceneRenderer
                                key={step}
                                step={s}
                                setFeedback={setFeedback}
                                selectedChar={selectedChar}
                                selectedGrade={selectedGrade}
                                setSelectedEmotion={setSelectedEmotion}
                                recommendations={recommendations}
                                selectedActivity={selectedActivity}
                                setSelectedActivity={setSelectedActivity}
                                onBackClick={() => onBackClick(s)}
                                goToNextScene={() => setStep(prev => prev + 1)}
                                videoRef={videoRef}
                                isVoiceControlled={isVoiceControlled}
                                sampleRate={sampleRate}
                                setStartActivities={setStartActivities}
                            />
                        )
                    }
                    return null
                })}

                {dialogue !== null && (
                    <DialogueBox
                        name={selectedChar}
                        dialogue={dialogue}
                        videoRef={videoRef}
                        isAutoPlay={isAutoPlay}
                        setIsAutoPlay={setIsAutoPlay}
                        onClick={onClickContinue}
                        isVoiceControlled={isVoiceControlled}
                        setIsVoiceControlled={setIsVoiceControlled}
                    />
                )}
            </Box>
        </Modal>
    )
}

export default FeedbackModal;