import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Flex, Grid, Text, TextAreaField, View, TextField, Image, Heading, SelectField } from "@aws-amplify/ui-react";
import { Modal } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import MultiSelect from "./MultiSelect";
import { getKeywords } from "./getKeywords";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { LinkInputCellRow } from "./LinkInputCell";
import { v4 as uuid } from "uuid";
import getDescriptionFromKeywords from "./getDescriptionFromKeywords";
import { deleteThumbnail, postThumbnail } from "./thumbnailAPI";
import { getInternalLinks } from "./getInternalLinks";
import MultiSelectInternalLinks from "./MultiSelectInternalLinks";
import SourcesField from "./SourcesField";

const StyledModalView = styled(View)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    background-color: white;
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 20px;
    max-height: 80vh;
    overflow-y: auto;
`;

const StyledFormHeading = styled(Heading)`
    font-size: 2rem !important;
`;

const LinkField = ({ links, setLinks }) => {
    const addNewLink = () => {
        setLinks([...links, { id: uuid(), name: "", url: "" }]);
    };

    const removeLink = (id) => {
        setLinks(links.filter((link) => link.id !== id));
    };

    const updateLink = (updatedLink) => {
        setLinks((prevLinks) => prevLinks.map((link) => (link.id === updatedLink.id ? updatedLink : link)));
    };

    return (
        <View>
            <Grid container spacing={2} alignItems={"center"}>
                {links.map((link) => (
                    <LinkInputCellRow key={link.id} link={link} removeLink={removeLink} updateLink={updateLink} />
                ))}
            </Grid>

            <Button style={{ marginTop: "1rem" }} onClick={addNewLink}>
                Add new link
            </Button>
        </View>
    );
};

const S3_THUMBNAIL_URL = "https://teachmetv.s3.amazonaws.com/images/thumbnails/";

function AdminAcitivityModal({ isOpen, setIsOpen, activities, setActivities, mode, activityToEdit }) {
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [grades, setGrades] = useState([]);
    const [contentType, setContentType] = useState("video");
    const [subjects, setSubjects] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [description, setDescription] = useState("");
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [prevSelectedKeywords, setPrevSelectedKeywords] = useState([]);
    const [priority, setPriority] = useState(1);
    const [platformInfo, setPlatformInfo] = useState([{ code: "", platform: "genially", platformID: "" }]);
    const [thumbnail, setThumbnail] = useState("");
    const [localThumbnail, setLocalThumbnail] = useState(null);
    const [internalLinks, setInternalLinks] = useState([]);
    const [selectedInternalLinks, setSelectedInternalLinks] = useState([]);
    const [externalLinks, setExternalLinks] = useState([]);
    const [isLoadingDesc, setIsLoadingDesc] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [activity, setActivity] = useState(null);
    const [contentTypeOptions, setContentTypeOptions] = useState([
        { label: "Video", value: "video" },
        { label: "Printable", value: "printable" },
        { label: "Activity", value: "activity" },
        { label: "Music", value: "music" },
        { label: "Game", value: "Game" },
    ]);

    // Use effect to set activity when in edit mode
    useEffect(() => {
        if (mode === "edit" && activityToEdit) {
            setActivity(activityToEdit);
            setTitle(activityToEdit.name);
            setGrades(activityToEdit.grades);
            setContentType(contentTypeOptions.find((option) => option.value === activityToEdit.type)?.value || "video");
            setSubjects(activityToEdit.subjects);
            setDescription(activityToEdit.description);
            setSelectedKeywords(activityToEdit.keywords);
            setPrevSelectedKeywords(activityToEdit.keywords);
            setPriority(activityToEdit.priority);
            setUrl(activityToEdit.url);
            if (activityToEdit.ids) {
                setPlatformInfo(
                    activityToEdit.ids.map((a) => ({
                        code: "",
                        platform: activityToEdit.platform,
                        platformID: a,
                    }))
                );
            } else {
                setPlatformInfo([
                    {
                        code: "",
                        platform: activityToEdit.platform,
                        platformID: activityToEdit.id,
                    },
                ]);
            }
            setThumbnail(`${S3_THUMBNAIL_URL}${activityToEdit.thumbnail}.png`);
            setSelectedInternalLinks(activityToEdit.internalLinks ? activityToEdit.internalLinks.map((link) => link.name) : []);
            setExternalLinks(activityToEdit.externalLinks);
        }
    }, [activityToEdit, mode, contentTypeOptions]);

    useEffect(() => {
        setKeywords(getKeywords(activities));
        setInternalLinks(getInternalLinks(activities));
    }, [activities]);

    const onClickDeleteThumbnail = () => {
        if (localThumbnail) {
            setLocalThumbnail(null);
            setThumbnail("");
            return;
        }

        try {
            if (mode === "edit") {
                deleteThumbnail(thumbnail.replace(S3_THUMBNAIL_URL, "")).then(() => {
                    setThumbnail("");
                });
            } else {
                deleteThumbnail(thumbnail).then(() => {
                    setThumbnail("");
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onChangeLocalThumbnail = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLocalThumbnail(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                const base64 = reader.result;
                setThumbnail(base64);
            };
        }
    };

    const generateDescription = () => {
        // If the keywords are same, don't call this
        if (selectedKeywords !== prevSelectedKeywords) {
            setIsLoadingDesc(true);
            getDescriptionFromKeywords(selectedKeywords)
                .then((desc) => {
                    setPrevSelectedKeywords(selectedKeywords);
                    setDescription(desc);
                    setIsLoadingDesc(false);
                })
                .catch(() => {
                    setDescription("OpenAI API key is not configured for auto-generation");
                    setIsLoadingDesc(false);
                });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const cleanUp = () => {
            setTitle("");
            setPriority(1);
            setUrl("");
            setContentType("video");
            setGrades([]);
            setPlatformInfo([{ code: "", platform: "genially", platformID: "" }]);
            setThumbnail("");
            setSubjects([]);
            setSelectedKeywords([]);
            setExternalLinks([]);
            setSelectedInternalLinks([]);
            setDescription("");

            setIsOpen(false);
            sessionStorage.setItem("isAdminSaved", false);
        };

        let platform = platformInfo[0].platform;
        let platformID = platformInfo[0].platformID;

        // If multiple sources, use the title as the id
        if (platformInfo.length > 1) {
            platformID = title.toLowerCase().replaceAll(" ", "-");
        }

        const data = {
            name: title,
            priority,
            url,
            isFree: false,
            type: contentType,
            grades,
            platform,
            id: platformID,
            appId: "122963",
            thumbnail,
            level: "beginner",
            length: 0,
            subjects,
            keywords: selectedKeywords,
            externalLinks,
            internalLinks: selectedInternalLinks,
            description,
        };

        const filename = title.trim().replaceAll(" ", "-").replaceAll("?", "").replaceAll("+", "");

        if (thumbnail.startsWith("data:image")) {
            // Checking if thumbnail is a base64 string
            postThumbnail(thumbnail, filename)
                .then(() => {
                    data["thumbnail"] = filename;

                    // If multiple ids, add the ids field
                    if (platformInfo.length > 1) {
                        data.ids = platformInfo.map((i) => i.platformID);
                    }

                    if (mode === "add") {
                        setActivities([data, ...activities]);
                    } else if (mode === "edit") {
                        // Find the activity in the activities array and replace it with the new data
                        const updatedActivities = activities.map((a) => (a.id === activity.id ? data : a));
                        setActivities(updatedActivities);
                    }

                    cleanUp();
                })
                .catch(() => {
                    alert("Please upload images of type: png, jpg, jpeg");
                });
        } else if (thumbnail.startsWith(S3_THUMBNAIL_URL)) {
            if (mode === "add") {
                setActivities([data, ...activities]);
            } else if (mode === "edit") {
                data["thumbnail"] = filename;
                // Find the activity in the activities array and replace it with the new data
                const updatedActivities = activities.map((a) => (a.id === activity.id ? data : a));
                setActivities(updatedActivities);
            }

            cleanUp();
        }
    };

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby={mode === "add" ? "Add new activity" : "Edit activity"}>
            <StyledModalView>
                <Grid>
                    <Flex width={"100%"} justifyContent={"flex-end"}>
                        <Button onClick={() => setIsOpen(false)}>
                            <MdOutlineClose />
                        </Button>
                    </Flex>

                    <Flex as={"form"} direction={"column"} marginTop={"1rem"} onSubmit={onSubmit}>
                        <StyledFormHeading level={1}>General information</StyledFormHeading>
                        {/*Title*/}
                        <View style={{ width: "100%" }}>
                            <TextField label={"Title*"} value={title} onChange={(e) => setTitle(e.target.value)} isRequired fullWidth />
                        </View>

                        {/*Grades*/}
                        <View style={{ width: "100%" }}>
                            <Text fontSize={"1rem"}>Grades*</Text>
                            <MultiSelect valueOptions={["K", "1", "2", "3", "4", "5", "6"]} value={grades} onValueChange={(newValue) => setGrades(newValue)} style={{ width: "100%", margin: 0 }} />
                        </View>

                        {/*Content type*/}
                        <View style={{ width: "100%" }}>
                            <SelectField label="Content Type*" isRequired value={contentType} onChange={(e) => setContentType(e.target.value)}>
                                {contentTypeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </SelectField>
                        </View>

                        {/*Subjects*/}
                        <View style={{ width: "100%" }}>
                            <Text fontSize={"1rem"}>Subjects*</Text>
                            <MultiSelect
                                valueOptions={["Math", "Language Arts", "Science", "Music", "Art", "Exercise", "Kids Teaching Kids"]}
                                value={subjects}
                                onValueChange={(newValue) => setSubjects(newValue)}
                                style={{ width: "100%", margin: 0 }}
                            />
                        </View>

                        {/*Keywords*/}
                        <View style={{ width: "100%" }}>
                            <Text fontSize={"1rem"}>Keywords*</Text>
                            <MultiSelect
                                valueOptions={keywords}
                                setValueOptions={setKeywords}
                                value={selectedKeywords}
                                onValueChange={(newValue) => setSelectedKeywords(newValue)}
                                onLeave={generateDescription}
                                style={{ width: "100%", margin: 0 }}
                                isAppendable
                                isSearchable
                            />
                        </View>

                        {/*Description*/}
                        <TextAreaField
                            autoComplete="off"
                            isRequired
                            label="Description*"
                            name="description"
                            rows="3"
                            size="small"
                            onChange={(e) => setDescription(e.currentTarget.value)}
                            value={description}
                            isDisabled={isLoadingDesc}
                            descriptiveText={
                                isLoadingDesc && (
                                    <Flex>
                                        <AiOutlineLoading3Quarters className={"loading"} />
                                        <Text fontSize={"small"}>Auto-generating description from keywords...</Text>
                                    </Flex>
                                )
                            }
                        />

                        {/*Priority*/}
                        <View style={{ width: "100%" }}>
                            <TextField
                                label={"Priority (Number from 1 to 5)"}
                                type={"number"}
                                min={1}
                                max={5}
                                value={priority}
                                onChange={(e) => {
                                    let { value, min, max } = e.target;
                                    // Guardrail against manual input
                                    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                                    setPriority(value);
                                }}
                                fullWidth
                            />
                        </View>

                        {/*URL*/}
                        <View style={{ width: "100%" }}>
                            <TextField label={"URL (for music)"} value={url} onChange={(e) => setUrl(e.target.value)} fullWidth />
                        </View>

                        <StyledFormHeading level={1}>Details</StyledFormHeading>
                        {/* Sources */}
                        {platformInfo.map((info, index) => (
                            <SourcesField key={index} sourceIndex={index} info={info} infos={platformInfo} setInfos={setPlatformInfo} isLoadingInfo={isLoadingInfo} setIsLoadingInfo={setIsLoadingInfo} />
                        ))}

                        <Button
                            onClick={() =>
                                setPlatformInfo((prevInfo) => [
                                    ...prevInfo,
                                    {
                                        code: "",
                                        platform: "",
                                        platformID: "",
                                    },
                                ])
                            }
                        >
                            Add source
                        </Button>

                        {/*Thumbnail*/}
                        <View style={{ width: "100%", marginTop: "1.5rem" }}>
                            <Flex justifyContent={"space-between"}>
                                <Text fontSize={"1rem"}>Thumbnail*</Text>
                                <Flex gap={"1rem"}>
                                    <Button onClick={onClickDeleteThumbnail}>Clear</Button>
                                    <input type="file" accept="image/*" onChange={onChangeLocalThumbnail} />
                                </Flex>
                            </Flex>

                            {isLoadingInfo ? <AiOutlineLoading3Quarters className={"loading"} /> : <Image alt={title} src={thumbnail} height={"300px"} />}
                        </View>

                        <StyledFormHeading level={1}>Related links</StyledFormHeading>
                        {/*Internal links*/}
                        <View style={{ width: "100%" }}>
                            <Text fontSize={"1rem"}>Internal Links</Text>
                            <MultiSelectInternalLinks valueOptions={internalLinks} value={selectedInternalLinks} onValueChange={setSelectedInternalLinks} style={{ width: "100%", margin: 0 }} isSearchable />
                        </View>

                        {/*External links*/}
                        <View style={{ width: "100%" }}>
                            <Text fontSize={"1rem"}>External Links</Text>
                            <LinkField links={externalLinks} setLinks={setExternalLinks} />
                        </View>

                        <Button type="submit">{mode === "add" ? "Add Activity" : "Save Changes"}</Button>
                    </Flex>
                </Grid>
            </StyledModalView>
        </Modal>
    );
}

export default AdminAcitivityModal;
