import {Heading, Image, Text, useTheme, View} from "@aws-amplify/ui-react";

export default function LandingScreenCard(props) {
    const {tokens} = useTheme();

    return (
        <View
            backgroundColor={props.backgroundColor}
            marginTop={props.isMobile ? '0px' : '40px'}
            marginBottom={props.isMobile ? '0px' : '40px'}
        >
            <Heading
                level={1}
                textAlign={'center'}
                marginTop={props.isMobile ? '20px' : '20px'}
                marginLeft={props.isMobile ? '0px' : '45px'}
                marginRight={props.isMobile ? '0px' : '45px'}
                color={'white'}
                fontFamily={'muli, sans-serif'}
                fontSize={props.isMobile ? '16pt': '24pt'}
                fontWeight={props.isMobile ? 'normal' : '900'}
            >
                {props.title}
            </Heading>
            <Image
                alt={'Card image'}
                src={`/images/landing/${props.image}`}
            />
            <Text
                color={'white'}
                textAlign={'center'}
                paddingLeft={tokens.space.large}
                paddingRight={tokens.space.large}
                paddingBottom={tokens.space.large}
            >
                {props.paragraph}
            </Text>
        </View>
    )
}