import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";

const StyledContainer = styled(Container)({
  backgroundColor: "#fffbfa",
  paddingBlock: "48px",

  ".logo": {
    width: "300px",
    height: "auto",
  },

  ".card": {
    backgroundColor: "#d5e3fe",
    padding: "16px",
    textAlign: "center",
    boxShadow: "#f1be30 20px 20px",
    margin: "auto",
    width: "fit-content",
  },

  ".mathematics-card": {
    ".card": {
      width: "90%",
    },
  },

  ".science-card": {
    ".card": {
      width: "90%",
    },
  },

  ".language-card": {
    ".card": {
      width: "90%",
    },
  },

  ".music-card": {
    ".card": {
      width: "90%",
    },
  },

  "@media (max-width: 899px)": {
    ".mathematics-card": {
      ".card": {
        width: "85%",
        margin: "auto",
      },
    },

    ".science-card": {
      ".card": {
        width: "85%",
        margin: "auto",
      },
    },

    ".language-card": {
      ".card": {
        width: "85%",
        margin: "auto",
      },
    },

    ".music-card": {
      ".card": {
        width: "85%",
        margin: "auto",
      },
    },
  },
});

const SYLLABUS = [
  {
    subject: "Mathematics K-5",
    className: "mathematics-card",
    topics: [
      {
        name: "Two-Digit Subtraction with Regrouping",
        url: "https://www.teachmetv.co/play/62b8c0a617bd1400102c644b",
      },
      {
        name: "Multiplication Area Models with 2- Digit Numbers",
        url: "https://www.teachmetv.co/play/6221670d866f05001b2db4ac",
      },
    ],
  },
  {
    subject: "Science K-5",
    className: "science-card",
    topics: [
      {
        name: "Simple Machines",
        url: "https://www.teachmetv.co/play/600b168906b4580d1a915f74",
      },
      {
        name: "Human Anatomy",
        url: "https://www.teachmetv.co/play/62c49c77d27e63001acdc352",
      },
      {
        name: "What Makes the Perfect Playground",
        url: "https://www.teachmetv.co/play/61ba10cfd546010d65e32d4d",
      },
    ],
  },
  {
    subject: "Language Arts K-5",
    className: "language-card",
    topics: [
      {
        name: "1st Grade Zipper's Word Workshop",
        url: "https://www.teachmetv.co/play/64f07afc60524f001970a846",
      },
      {
        name: "K/1st Grade - Rhyming Pizza",
        url: "https://www.teachmetv.co/play/620e99d623c3020012b27045",
      },
      {
        name: "K-2  Candy King's Punctuation Activity",
        url: "https://www.teachmetv.co/play/63628ca57a01e000137ffeee",
      },
    ],
  },
  {
    subject: "Music & Art & Exercise",
    className: "music-card",
    topics: [
      {
        name: "Music: Exploring the Orchestra Part 1 Strings and Woodwinds",
        url: "https://www.teachmetv.co/play/62be8c948ca4d4001122dcc4",
      },
      {
        name: "Music: I Have a Dream",
        url: "https://www.teachmetv.co/play/1586285458938",
      },
      {
        name: "Art: One Point Perspective",
        url: "https://www.teachmetv.co/play/63b629de1bd2cf001245fc55",
      },
    ],
  },
];

const Subjects = () => {
  return (
    <StyledContainer maxWidth={false}>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 2 }}>
        {SYLLABUS &&
          SYLLABUS.map((item, index) => (
            <Grid item md={6} xs={12} className={item.className} key={index}>
              <Box className="card">
                <Typography
                  variant="h5"
                  fontSize={40}
                  fontWeight="bold"
                  gutterBottom
                >
                  {item.subject}
                </Typography>
                {item.topics.map((item) => (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Typography variant="body2" fontSize={16}>
                      {item.name}
                    </Typography>
                  </a>
                ))}
              </Box>
            </Grid>
          ))}
      </Grid>
    </StyledContainer>
  );
};

export default Subjects;
