import {Flex, Text, View} from "@aws-amplify/ui-react";
import Typewriter from 'typewriter-effect';

export default function Banner(props) {
    const {fontSize, verticalPadding, staticText, dynamicText, isMobile, ...rest} = props

    const css = `
        .Typewriter {
            font-size: ${fontSize || isMobile ? '1rem' : '3.3rem'};
            color: white;
            text-align: center;
            font-family: muli, sans-serif;
            text-decoration: underline;
        }
        .typewriter-static {
            font-size: ${fontSize || isMobile ? '1rem' : '3.3rem'};
            color: white;
            text-align: center;
            font-family: muli, sans-serif;
        }
    `

    return (
        <>
            <style>{css}</style>
            <View
                className={'moving-gradient'}
                paddingTop={verticalPadding || 30}
                paddingBottom={verticalPadding || 30}
                width={'100%'}
                margin={'auto auto'}
                {...rest}
            >
                <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={isMobile ? 5 : 15}
                    wrap={'wrap'}
                >
                    <Text className="typewriter-static" fontSize={fontSize || isMobile ? '1rem' : '3.3rem'}>{staticText}</Text>
                    <Typewriter
                        options={{
                            loop: true,
                        }}
                        onInit={(typewriter) => {
                            dynamicText.forEach((text, index) => {
                                typewriter['typeString'](text);
                                typewriter['pauseFor'](1500);
                                typewriter['deleteChars'](text.length);
                                if (index !== dynamicText.length - 1)
                                    typewriter['pauseFor'](500);
                            })
                            typewriter.start();
                        }}
                    />
                </Flex>
            </View>
        </>
    )
}