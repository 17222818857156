import React from 'react';
import DialogueScene from "./DialogueScene";
import RecommendationCards from "../RecommendationCards";
import {View} from "@aws-amplify/ui-react";
import styled from "styled-components";

const RecommendationCardsContainer = styled(View)`
    margin-left: calc(2rem + 80px); 
    width: 50%;
    height: 70%; 
    position: absolute; 
    z-index: 1;
    display: flex; 
    align-items: center;
  
    @media only screen and (max-width: 820px) {
      margin: 0 2rem;
      width: calc(100% - 4rem);
    }
`

function RecommendationScene({recommendations, video, setActivity}) {
    return (
        <div style={{height: '100%', position: 'relative'}}>
            <RecommendationCardsContainer>
                <RecommendationCards recommendations={recommendations} setRecommendation={setActivity} />
            </RecommendationCardsContainer>

            <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                <DialogueScene
                    dialgueVideo={video}
                    isWithBackButton={false}
                    videoPos={'right'}
                />
            </div>
        </div>
    );
}

export default RecommendationScene;