import React, {useEffect, useState} from 'react';
import {Box, Checkbox, Chip, FormControl, Input, ListItemText, MenuItem, Select} from "@mui/material";
import {Button} from "@aws-amplify/ui-react";
import {MdOutlineAdd} from 'react-icons/md'
import {debounce} from 'lodash'

function MultiSelect({ valueOptions, setValueOptions, value, onValueChange, onLeave = () => {}, isSearchable = false, isAppendable = false, style }) {
    const [selectedOptions, setSelectedOptions] = useState(value)
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredOptions, setFilteredOptions] = useState([])

    useEffect(() => {
        setSelectedOptions(value)
    }, [value])

    const handleChange = (event) => {
        setSelectedOptions(event.target.value)
        onValueChange(event.target.value)
    }

    const handleSearchChange = (event) => {
        event.stopPropagation()
        setSearchTerm(event.target.value)
    }

    const addOption = (e) => {
        e.stopPropagation()
        if (searchTerm && searchTerm.trim() !== '' && !selectedOptions.includes(searchTerm)) {
            setSelectedOptions([...selectedOptions, searchTerm])
            onValueChange([...selectedOptions, searchTerm])
            setValueOptions([...valueOptions, searchTerm])
        }
        setSearchTerm('')
    }

    const debouncedSetFilteredOptions = debounce(() => {
        setFilteredOptions(valueOptions.filter((option) =>
            option && option.toLowerCase().includes(searchTerm.toLowerCase())
        ))}, 500)

    useEffect(() => {
        debouncedSetFilteredOptions()
    }, [searchTerm])

    return (
        <FormControl sx={{ m: 1, width: 400, ...style }}>
            <Select
                multiple
                value={selectedOptions}
                onChange={handleChange}
                onBlur={onLeave}
                input={<Input />}
                renderValue={(selected) =>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                    </Box>
                }
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
            >
                { isSearchable && (
                    <MenuItem>
                        <Input
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            inputProps={{ 'aria-label': 'search' }}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        { isAppendable && searchTerm !== '' && filteredOptions.length === 0 && (
                            <Button onClick={e => addOption(e)}>
                                <MdOutlineAdd />
                            </Button>
                        )}
                    </MenuItem>
                )}
                {searchTerm === '' ? valueOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={selectedOptions.includes(option)} />
                        <ListItemText primary={option} />
                    </MenuItem>
                )) : filteredOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={selectedOptions.includes(option)} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MultiSelect;