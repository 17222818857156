import {Flex, Heading, Image, Text, View} from "@aws-amplify/ui-react";
import Vimeo from "@u-wave/react-vimeo";
import {LandingButton} from "../../themed";
import EmailWidget from "./email-widget";

export default function LandingScreenAbout({aboutRef, plansRef}) {
    const isIPadPortraitWidthOrLower = window.innerWidth < 768;

    return (
        <Flex
            ref={aboutRef}
            direction={'column'}
            alignItems={'center'}
            backgroundColor={'white'}
            position={'relative'}
        >
            <Flex
                wrap={'wrap'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Flex padding={25} direction={'column'} alignItems={'center'}
                      maxWidth={window.innerWidth < 1025 ? '100vw' : '35vw'}>
                    <Heading level={1} fontFamily={'muli, sans-serif'}>About us</Heading>
                    <Text textAlign={'center'}>We're a group of educators, technologists and media producers on a
                        mission to ignite children's natural desire to learn. We empower children with the learning
                        tools and strategies they need to excel in school and beyond.</Text>
                </Flex>
                <View
                    position={'relative'}
                    backgroundImage={"url('/images/landing/about-video.gif')"}
                    marginBottom={'-80px'}
                    width={isIPadPortraitWidthOrLower ? '90vw' : '800px'}
                    height={isIPadPortraitWidthOrLower ? '430px' : '600px'}
                    style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Vimeo
                        video={'450910802'}
                        width={isIPadPortraitWidthOrLower ? '300vw' : ''}
                        style={{
                            paddingRight: isIPadPortraitWidthOrLower ? '0' : '50px',
                            paddingBottom: isIPadPortraitWidthOrLower ? '205px' : '25px',
                        }}
                    />
                </View>
            </Flex>
            <Flex alignItems={'center'} justifyContent={'center'} wrap={'wrap'}>
                <Image
                    alt={'Brush stroke sign up header'}
                    src='/images/landing/brush-stroke-signup.png'
                    height={'120px'}
                />
                <EmailWidget/>
            </Flex>
            <Flex paddingTop={50} paddingBottom={50} direction={'column'} alignItems={'center'}
                  justifyContent={'center'} wrap={'wrap'}>
                <View maxWidth={'80vw'} textAlign={'center'}>
                    <Heading level={2} fontFamily={'muli, sans-serif'}>Why all learning is not created equal.</Heading>
                    <Text>The most valuable tool we can give our children is the capacity to become lifelong learners.
                        Drilling math facts and learning by rote, kids never have a chance to truly gain a conceptual
                        understanding of the problems they're trying to solve. TeachMe TV® makes learning interactive
                        and provides games, activities, videos and print materials that engage and help your child grow
                        as a critical, collaborative thinker and creator. Plus- all our activities are age appropriate
                        and you'll never have to worry about supervising screen time. </Text>
                </View>
                <View position={'relative'} width={'100%'}>
                    <View
                        position={'absolute'}
                        top={0}
                        left={0}
                        width={'100%'}
                        height={'100%'}
                        backgroundImage={"url('/images/landing/brush-stroke-2.svg')"}
                        style={{
                            backgroundSize: isIPadPortraitWidthOrLower ? 'auto 100%' : 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                        }}
                    />
                    <View
                        position={'relative'}
                        zIndex={1}
                        padding={'2rem 0'}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <p style={{width: isIPadPortraitWidthOrLower ? '80%' : '30%', fontWeight: 'bold'}}>"Our kids are
                            the leaders of tomorrow. Let's give them the tools they need today."</p>
                        <p style={{width: '80%', marginTop: 0}}>ー Carolyn Sloan, Founder and CEO</p>
                    </View>
                </View>
            </Flex>
            <View paddingBottom={100}>
                <LandingButton text={'Get Started'} plansRef={plansRef}/>
            </View>
            <Image
                alt={'Kid thinking'}
                src={'/images/landing/kid-thinking.png'}
                position={'absolute'}
                bottom={0}
                right={0}
                width={'35vw'}
            />
        </Flex>
    )
}