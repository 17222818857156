import {View} from "@aws-amplify/ui-react";
import React, {useEffect, useRef} from "react";
import EmojiTextCards from "../EmojiTextCards";
import {EMOTIONS} from "../../constants";
import EmotionCardsContainer from "../EmotionCardsContainer";

export default function EmotionSelector({backgroundColor, setSelectedEmotion, setDisplayEmotionSelection}) {
    const viewRef = useRef(null);

    // Add a click event listener to the document
    useEffect(() => {
        setTimeout(() => {
            // Attach the event listener
            document.addEventListener("click", handleClickOutside);
        }, 200);
        const handleClickOutside = (event) => {
            if (viewRef.current && !viewRef.current.contains(event.target)) {
                // Clicked outside the Flex container, so hide the component
                setDisplayEmotionSelection(false);
            }
        };


        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <View
            ref={viewRef}
            style={{
                position: 'absolute',
                width: `400px`,
                top: 175,
                left: 20,
            }}
        >
            <EmotionCardsContainer>
                <EmojiTextCards backgroundColor={backgroundColor} data={EMOTIONS} setSelectedEmoji={(emotion) => {
                    setSelectedEmotion(emotion);
                    setDisplayEmotionSelection(false);
                }}/>
            </EmotionCardsContainer>
        </View>
    )
}