import {AudioButton, FlexCentered} from "../../themed";
import {Heading, View} from "@aws-amplify/ui-react";
import {AudioContext, DeviceContext} from "../../App";
import {useContext, useEffect} from "react";
import {TutorialContext} from "../Onboarding/TutorialWrapper";

export function Selectors({
                              sourceDict,
                              selector,
                              setSelectorFn
                          }) {

    const { isTourActive, setRun, setStepIndex } = useContext(TutorialContext)

    useEffect(() => {
        if (isTourActive) {
            setRun(true)
            setStepIndex(1)
        }
    }, [])

    return (
        <div id={'step-1'} onClick={() => setRun(false)}>
            <FlexCentered>
                {
                    Object.keys(sourceDict.data).map((topicName) => {
                        const topic = sourceDict.data[topicName];
                        return (<Selector key={topicName} topicName={topicName} selector={selector}
                                          label={topic.label}
                                          alt={`${topic.label} Icon`}
                                          id={topic.id}
                                          icon={topic.icon}
                                          setSelectorFn={setSelectorFn}/>)
                    })
                }
            </FlexCentered>
        </div>
    )
}

const Selector = ({topicName, selector, icon, id, label, setSelectorFn}) => {
    const {audioRef} = useContext(AudioContext);
    const {isMobile} = useContext(DeviceContext);

    return <View
        style={{
            cursor: 'pointer',
            border: `thick solid ${selector && topicName.toLowerCase() === selector.toLowerCase() ? 'orange' : 'rgba(255, 230, 170, 0.081)'}`,
            borderRadius: 20
        }}
        onClick={() => audioRef.current.pause()}
    >
        <AudioButton
            onClickHandler={() => setSelectorFn(id.replaceAll(' ', '-'))}
            image={icon}
            sound={label}
            maxWidth={isMobile ? 50 : 150}
        >
            <Heading level={3} fontFamily={'muli, sans-serif'} textAlign={'center'}>
                {label}
            </Heading>
        </AudioButton>
    </View>
}