import React, { useEffect, useRef, useState } from "react";
import Carousel from "./CharacterCarousel";
import CharacterAnimation from "../ChatBot/CharacterAnimation";
import { View } from "@aws-amplify/ui-react";
import { Grid, useMediaQuery } from "@mui/material";

function CharacterSelection({
  name,
  setName,
  characters,
  selectedChar,
  onSelect,
}) {
  const videoRefs = useRef([]);
  const audioRef = useRef(null);
  const [animationName, setAnimationName] = useState("talking");
  const isSmallHeight = useMediaQuery("(max-height: 864px)");

  useEffect(() => {
    const characterData = characters.data.find(
      (char) => char.name === selectedChar
    );
    if (characterData) {
      setAnimationName("talking");
      audioRef.current.src = characterData.audio;
      audioRef.current?.play();
    }
  }, [characters, selectedChar]);

  const handleAudioEnded = () => setAnimationName("idle");

  return (
    <>
      <audio ref={audioRef} src="" onEnded={handleAudioEnded} />
      <Grid
        container
        style={{
          height: "100%",
          position: isSmallHeight ? "relative" : undefined,
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "10%",
            zIndex: isSmallHeight ? 100 : 1,
            position: isSmallHeight ? "absolute" : undefined,
            width: "100%",
          }}
        >
          <Carousel
            characters={characters}
            selectedChar={selectedChar}
            onSelect={onSelect}
            videoRefs={videoRefs}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "100%",
            position: isSmallHeight ? "absolute" : undefined,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            overflowX: "hidden",
          }}
        >
          {characters.data.map((character, index) => (
            <View
              key={index}
              style={{
                display: selectedChar === character.name ? "block" : "none",
              }}
            >
              <CharacterAnimation
                characterId={selectedChar}
                animationName={animationName}
              />
            </View>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default CharacterSelection;
