import React from "react";
import FeedbackSelectionScene from "./FeedbackSelectionScene";
import DialogueScene from "./DialogueScene";
import EmotionSelectionScene from "./EmotionSelectionScene";
import RecommendationScene from "./RecommendationScene";
import RecommendationConfirmationScene from "./RecommendationConfirmationScene";
import { StepType } from "../../constants";
import characters from "../../data/studyBuddyCharacters.json";
import CharacterSelection from "./CharacterSelection";
import GradeSelectionScene from "./GradeSelectionScene";
import RestScene from "./RestScene";
import NameInputScene from "./NameInputScene";
import ParentTutorialScene from "./ParentTutorialScene";
import CheckStartActivities from "./CheckStartActivities";

const SceneRenderer = ({
  step,
  name = "",
  setName = () => {},
  selectedChar = "",
  setSelectedChar = () => {},
  selectedGrade = "",
  setSelectedGrade = () => {},
  setFeedback = () => {},
  setSelectedEmotion = () => {},
  recommendations = [],
  selectedActivity,
  setSelectedActivity = () => {},
  setStartActivities = () => {},
  onBackClick = () => {},
  goToNextScene = () => {},
  videoRef = null,
  isVoiceControlled = false,
  sampleRate = 44100,
  isAudio
}) => {
  switch (step.type) {
    case StepType.CharacterSelection:
      return (
        <CharacterSelection
          name={name}
          setName={setName}
          characters={characters}
          onSelect={setSelectedChar}
          selectedChar={selectedChar}
        />
      );
    case StepType.NameInput:
      return (
        <NameInputScene
          video={step.contentVideo[selectedChar]}
          name={name}
          setName={setName}
          isWithBackButton={step.isWithBackButton}
          onBackClick={onBackClick}
          selectedChar={selectedChar}
        />
      );
    case StepType.FeedbackSelection:
      return (
        <FeedbackSelectionScene
          video={step.contentVideo[selectedChar]}
          isVoiceControlled={isVoiceControlled}
          setFeedback={setFeedback}
          sampleRate={sampleRate}
        />
      );
    case StepType.Dialogue:
      return (
        <DialogueScene
          ref={videoRef}
          dialgueVideo={step.contentVideo[selectedChar]}
          isWithBackButton={step.isWithBackButton}
          onBackClick={onBackClick}
          selectedChar={selectedChar}
          isAudio={isAudio}
        />
      );
    case StepType.GradeSelection:
      return (
        <GradeSelectionScene
          video={step.contentVideo[selectedChar]}
          selectedGrade={selectedGrade}
          setSelectedGrade={setSelectedGrade}
          isWithBackButton={step.isWithBackButton}
          onBackClick={onBackClick}
          isVoiceControlled={isVoiceControlled}
          sampleRate={sampleRate}
        />
      );
    case StepType.Rest:
      return (
        <RestScene
          text={step.contentString[selectedChar]}
          video={step.contentVideo[selectedChar]}
          goToNextScene={goToNextScene}
        />
      );
    case StepType.EmotionSelection:
      return (
        <EmotionSelectionScene
          video={step.contentVideo[selectedChar]}
          setSelectedEmotion={setSelectedEmotion}
          isVoiceControlled={isVoiceControlled}
          sampleRate={sampleRate}
        />
      );
    case StepType.Recommendation:
      return (
        <RecommendationScene
          video={step.contentVideo[selectedChar]}
          recommendations={recommendations}
          setActivity={setSelectedActivity}
        />
      );
    case StepType.RecommendationConfirmation:
      return (
        <RecommendationConfirmationScene
          video={step.contentVideo[selectedChar]}
          activity={selectedActivity}
          onBackClick={onBackClick}
        />
      );
    case StepType.ParentTutorial:
      return (
        <ParentTutorialScene
          ref={videoRef}
          dialgueVideo={step.contentVideo[selectedChar]}
        />
      );
    case StepType.CheckStartActivities:
      return (
        <CheckStartActivities
          video={step.contentVideo[selectedChar]}
          setStartActivities={setStartActivities}
          isVoiceControlled={isVoiceControlled}
          sampleRate={sampleRate}
        />
      );
    default:
      return null;
  }
};

export default SceneRenderer;
