import {Flex, Heading} from "@aws-amplify/ui-react";
import Testimonial from "./testimonial";

export default function LandingScreenTestimonials(props) {
    return (<Flex
        direction={'column'}
        paddingTop={'50px'}
        paddingBottom={'100px'}
        backgroundColor={'#f5fae5'}
        alignItems={'center'}
        justifyContent={'center'}
    >
        <Heading level={1} fontFamily={'muli, sans-serif'} textAlign={'center'}>
            What parents, teachers, and experts are saying
        </Heading>

        <Testimonial
            video={'818500254'}
            message={"As a busy mom of three and 1st grade teacher, finding a valuable interactive tool that engages my kids in an educational way was a top priority for me, especially gearing up for summer. I’m so happy to have found Teach Me TV because it checks all the boxes and my oldest never wants to stop playing (major win!!!)"}
            source={"- Cassy, A., Mom,  daughter, Emilia, age 6, primary school teacher, PA."}
            maxWidth={'60%'}
            openingQuote={true}
            isMobile={props.isMobile}
        />

        <Testimonial
            photo={'testimonial-arianne.png'}
            message={"I love TeachMe TV for my daughter who is 5. It’s a great, fun way to keep her learning!"}
            source={"- Arianne Z. nurse and mother of Rachel, age 5, New Brunswick N.J."}
            maxWidth={'60%'}
            isMobile={props.isMobile}
        />

        <Testimonial
            photo={'testimonial-diane.png'}
            message={"TeachMe TV is user-friendly, easy for teachers and students to navigate. Lessons are fun, and instructive. Animations and narrations are splendid and help keep children engaged."}
            source={"- Diane G., 2nd grade teacher"}
            reverse={true}
            maxWidth={'60%'}
            isMobile={props.isMobile}
        />

        <Testimonial
            photo={'testimonial-antoinette.png'}
            message={"I love the variety of things to do on TeachMe TV. My kids never get bored."}
            source={"- Antoinette, S., Mom, age 34, Ontario Canada"}
            maxWidth={'60%'}
            isMobile={props.isMobile}
        />

        <Testimonial
            photo={'testimonial-suzanne.png'}
            message={"I highly prefer this kind of screen time as opposed to just watching cocomelon or different shows like that. TeachMe TV  is better because my daughter has been able to learn with it! She learned about addition and subtraction, and she likes watching the educational videos on the solar system too! It’s a definite must for any parent who has their child watching TV. TeachMe TV is SO much better!"}
            source={"- Suzanne H. mother of 4-year-old daughter, San José, California"}
            maxWidth={'60%'}
            reverse={true}
            closingQuote={true}
            isMobile={props.isMobile}
        />

        <Testimonial
            photo={'testimonial-dave.png'}
            message={"Carolyn Sloan is a one-of-a-kind educator, author, composer and visionary. We were lucky enough to have her teach two of our kids when she ran the music program at their elementary school. She was brilliant at her job, the songs she composed for the kids to perform still run through our heads and our kids (along with their parents) are still enthralled by the series of the print + audio books she’s written. Carolyn is a visionary educator and artist with enormous heart and talent. We feel lucky to have been in her orbit for the past 8 years. She has the golden touch!"}
            source={"- Dave Isay, Author, Documentarian and Founder of StoryCorps"}
            maxWidth={'60%'}
            openingQuote={true}
            closingQuote={true}
            isMobile={props.isMobile}
        />
    </Flex>)
}