import {useEffect, useRef, useState} from "react";
import {Flex, useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

import Top from "../landing/top";
import Questions from "../landing/questions";
import Mistakes from "../landing/mistakes";
import ActivityTypes from "../landing/activityTypes";
import Fun from "../landing/fun";
import Testimonials from "../landing/testimonials";
import Plans from "../landing/plans";
import About from "../landing/about";
import Sample from "../landing/sample";
import Banner from "../landing/banner";
import KidsTeachKids from "../landing/kids-teach-kids";
import Awards from "../landing/awards";

export default function ParentsScreen() {
    const plansRef = useRef(null);
    const samplesRef = useRef(null);
    const signupRef = useRef(null);

    const navigate = useNavigate();
    const {user} = useAuthenticator((context) => [context.user]);
    useEffect(() => {
        if (user) navigate('/kids-zone')
    }, [user, navigate])

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `var _ctct_m = "0991c274b8198d44480604829b979102";`;
        script.id = 'signupScript';
        script.src = '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup function: remove the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    const isMobile = width < 769

    return (<>
        <Flex direction={'column'} gap={0}>
            <Top plansRef={plansRef} isMobile={isMobile} image={'/images/landing/parents-top.jpg'} hideButtons={true} showOverlay={true}/>

            <Banner
                isMobile={isMobile}
                staticText={"What if you didn't have to supervise "}
                dynamicText={["screen time?", "learning?", "content quality?",]}
            />

            <Fun plansRef={plansRef} isMobile={isMobile}/>
            <ActivityTypes samplesRef={samplesRef}/>
            <Mistakes plansRef={plansRef} isMobile={isMobile}/>
            <Sample plansRef={plansRef} samplesRef={samplesRef} signupRef={signupRef} isMobile={isMobile}/>
            <KidsTeachKids plansRef={plansRef} isMobile={isMobile}/>

            <Banner
                isMobile={isMobile}
                staticText={"Your child will become a better "}
                dynamicText={["critical thinker.", "independent learner.", "strategic problem solver.",]}
            />

            <About isMobile={isMobile}/>
            <Awards />
            <Testimonials plansRef={plansRef} isMobile={isMobile}/>
            <Questions samplesRef={samplesRef} isMobile={isMobile}/>
            <Plans plansRef={plansRef} isMobile={isMobile}/>
        </Flex>
    </>)
}