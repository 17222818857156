import React from 'react';
import styled, {keyframes} from "styled-components";

const Container = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
`

const pulseAnimation = keyframes`
 0% { transform: scale(1) }
 50% { transform: scale(1.6) } 
 100% { transform: scale(1) }
`

const OuterCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  background-color: rgba(247, 148, 29, 0.5);
  animation-name: ${pulseAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: center;
  height: 10px; 
  width: 10px;
`

const InnerCircle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #F7941D;
  z-index: 2;
`

function ListeningIcon() {
    return (
        <Container>
            <InnerCircle />
            <OuterCircle />
        </Container>
    );
}

export default ListeningIcon;