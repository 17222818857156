// ga4.js module

export const initializeGA4 = (measurementId) => {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line no-undef
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', measurementId);
};

export const sendGA4Event = (eventName, eventParams) => {
    window.gtag('event', eventName, eventParams);
};
