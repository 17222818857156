import React from 'react';
import {View} from "@aws-amplify/ui-react";
import styled from "styled-components";

const Container = styled(View)`
  margin-left: calc(2rem + 80px);
  margin-top: 2rem;
  width: 50%;
  height: 70%;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 820px) {
    margin: 0 2rem;
    width: calc(100% - 4rem);
  }
`

function EmotionCardsContainer({children}) {
    return (
        <Container>{children}</Container>
    );
}

export default EmotionCardsContainer;