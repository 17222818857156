import {Button, Flex} from "@aws-amplify/ui-react";

export default function KidsZoneBreadcrumbs({
                                                grade,
                                                subject,
                                                type,
                                                searchTerm,
                                                setSelectedGrade,
                                                setSelectedSubject,
                                                setSelectedType,
                                                setSearchTerm
                                            }) {
    return (<Flex
        alignItems={'center'}
        justifyContent={'center'}
        paddingBottom={30}
    >
        <Button variation={'link'}
                onClick={() => {
                    setSelectedGrade(null)
                    setSelectedSubject(null)
                    setSelectedType(null)
                    setSearchTerm(null)
                }}
        >
            Grade: {grade}
        </Button> >
        {subject &&
            <Button variation={'link'}
                    onClick={() => {
                        setSelectedSubject(null)
                    }}
            >
                Subject: {titleCase(subject.replaceAll('-', ' '))}
            </Button>
        }
        {type &&
            <Button variation={'link'}
                    onClick={() => {
                        setSelectedType(null)
                    }}
            >
                Type: {titleCase(type.replaceAll('-', ' '))}
            </Button>
        }
        {searchTerm &&
            <Button variation={'link'}
                    onClick={() => {
                        setSearchTerm(null)
                    }}
            >
                Search Term: {titleCase(searchTerm.replaceAll('-', ' '))}
            </Button>
        }
    </Flex>)
}

const titleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
}