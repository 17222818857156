import {Heading, Image, Text, View} from "@aws-amplify/ui-react";

export default function SubscriptionErrorScreen() {
    return (<>
            <Heading level={1}>An unexpected error occurred during sign up</Heading>
            <View paddingTop={50}>
                <Text>Please contact us at <a href="mailto:support@teachmetv.co">support@teachmetv.co</a> and we'll
                    resolve this for you.</Text>
            </View>
            <Image
                alt={'Sad robot'}
                src="/images/sad-robot.png"
            />
        </>)
}