import {useEffect, useRef, useState} from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import characters from "../data/studyBuddyCharacters.json";
import {getUserById} from "../helpers/userInfo";

function useStudyBuddyState(isPlayground = false) {
  const { user } = useAuthenticator((context) => [context.user]);
  const videoRef = useRef(null);

  const [name, setName] = useState('')
  const [selectedChar, setSelectedChar] = useState(characters.data[0].name);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [isFirstVisit, setIsFirstVisit] = useState('');
  const [selectedEmotion, setSelectedEmotion] = useState("");
  const [selectedEmotionTimestamp, setSelectedEmotionTimestamp] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState({});
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [isVoiceControlled, setIsVoiceControlled] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [startActivities, setStartActivities] = useState(null);
  const [context, setContext] = useState(null);

  useEffect(() => {
    if (!user)
      return;

    getUserById(user?.username).then(userInfo => {
      if (userInfo) {
        setName(userInfo.name);
        setSelectedChar(userInfo.characterId !== '' ? userInfo.characterId : selectedChar);
        setSelectedGrade(userInfo.grade);
        setIsFirstVisit(userInfo.isFirstVisit ? userInfo.isFirstVisit : false)
      }
    })
  }, [user]);

  useEffect(() => {
    if (!selectedChar)
      return;

    import(`../components/ChatBot/data/${selectedChar}.json`)
        .then(module => {
          const newContext = module.default;
          setContext(newContext);
        })
        .catch(error => {
          console.error("Error loading module:", error);
        });
  }, [selectedChar]);

  const isSelectedEmotionExpiredFn = () => {
    const timeDifference = Math.abs(Date.now() - selectedEmotionTimestamp); // Get the time difference in milliseconds
    const oneHourInMilliseconds = 60 * 60 * 1000; // 1 hour in milliseconds

    // After 1 hour, we consider that we don't know the emotion anymore
    return (timeDifference > oneHourInMilliseconds);
  }

  return {
    user,
    videoRef,
    name: isPlayground ? "Study Buddy" : name,
    setName,
    selectedChar,
    setSelectedChar,
    selectedGrade,
    setSelectedGrade,
    isFirstVisit,
    selectedEmotion,
    setSelectedEmotion,
    selectedEmotionTimestamp,
    setSelectedEmotionTimestamp,
    selectedActivity,
    setSelectedActivity,
    isAutoPlay,
    setIsAutoPlay,
    isVoiceControlled,
    setIsVoiceControlled,
    feedback,
    setFeedback,
    startActivities,
    setStartActivities,
    context,
    isSelectedEmotionExpiredFn
  };
}

export default useStudyBuddyState;
