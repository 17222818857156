import {useState, useEffect, useCallback} from 'react';

function useMic(isVoiceControlled, setIsVoiceControlled, isAllowed = true) {
    const [sampleRate, setSampleRate] = useState(0)

    const getMic = useCallback(() => {
        const constraints = {audio: true}
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                const audioTrack = stream.getAudioTracks()[0];
                const settings = audioTrack.getSettings();
                setSampleRate(settings.sampleRate)
            })
            .catch((err) => {
                console.error('Failed to get user media', err);
                setIsVoiceControlled(false)
            });
    }, [setIsVoiceControlled])

    useEffect(() => {
        if (isAllowed && isVoiceControlled && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            // Check for existing permission
            navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
                if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
                    getMic()
                } else {
                    setIsVoiceControlled(false)
                }
            });
        } else if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setIsVoiceControlled(false)
            alert('For the best experience, please use the latest version of Chrome, Edge, Safari, Firefox, or Opera.')
        }
    }, [getMic, isVoiceControlled, setIsVoiceControlled])

    return sampleRate;
}

export default useMic;
