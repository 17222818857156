import {Card, Divider, Flex, Heading, Image, Text, useTheme, View} from "@aws-amplify/ui-react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import blogPosts from '../data/blogPosts.json';

export default function BlogScreen() {
    useEffect(() => {
        document.title = "Blog - TeachMe TV";
    }, []);

    const {tokens} = useTheme();
    const navigate = useNavigate();

    const isMobile = window.innerWidth < 600;

    return (<View paddingBottom={tokens.space.xxxl}>
            <Heading level={1}>Blog</Heading>
            <Divider paddingTop={tokens.space.medium}/>
            <Flex
                direction={{base: isMobile ? 'column' : 'row'}}
                wrap={'wrap'}
                justifyContent={'center'}
                alignItems={'stretch'}
            >
                {blogPosts.data.filter(post => !post.hidden).map(post => <Card
                    key={post.id}
                    style={{
                        cursor: 'pointer',
                    }}
                    maxWidth={isMobile ? '100%' : '400px'}
                    backgroundColor={'transparent'}
                    onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                        navigate(`/blog/${post.id}`);
                    }}
                >
                    <Image
                        alt={'Featured image'}
                        src={post.featuredImage}
                        borderRadius={tokens.space.medium}
                        height={250}
                    />
                    <Text fontSize={'1em'}>{post.categories.join(', ')}</Text>
                    <Heading level={4} paddingTop={tokens.space.medium}
                             paddingBottom={tokens.space.medium}>{post.title}</Heading>
                    <Text fontSize={'1.15em'}>{post.blob}</Text>
                </Card>)}
            </Flex>
        </View>)
}