import React, {useEffect, useRef} from 'react';

function RestScene({text, video, goToNextScene}) {
    const videoRef = useRef()

    useEffect(() => {
        videoRef.current.addEventListener('ended', () => goToNextScene())
        return videoRef.current.removeEventListener('ended', () => goToNextScene())
    }, [goToNextScene, video])

    return (
        <div style={{height: '100%', width: '100%', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white'}}>{text}</p>

            <video
                ref={videoRef}
                src={video}
                autoPlay
                style={{zIndex: -1, display: 'none'}}
            />
        </div>
    );
}

export default RestScene;