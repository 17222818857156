import { Button } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";

export default function ScrollToTop() {
    const [scrollY, setScrollY] = useState(window.scrollY);

    const handleScrollYChange = () => setScrollY(window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', handleScrollYChange);
        return () => 
            window.removeEventListener('scroll', handleScrollYChange);
    }, []);

    if (scrollY < 100)
        return ''
    else
        return (
            <Button
                position={'fixed'}
                bottom={15}
                right={15}
                backgroundColor={'#00acee'}
                color={'white'}
                fontWeight={'extrabold'}
                onClick={() => window.scrollTo(0, 0)}
                style={{zIndex: 100}}
            >
                ^
            </Button>
        )
}