import {Flex, Heading, Image, Link, useTheme} from "@aws-amplify/ui-react";
import {useEffect} from "react";

export function PageNotFoundScreen() {
    const {tokens} = useTheme();

    useEffect(() => {
        document.title = "Page not found - TeachMe TV";
    }, [])

    return (
        <Flex
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Heading level={1} paddingBottom={tokens.space.xxxl}>Page not found</Heading>
            <Image alt={'Sad robot'} src="/images/sad-robot.png"/>
            <Link href="/">Back to home page</Link>
        </Flex>
    )
}