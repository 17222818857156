import React from "react";
import { Box, Container, Button, Link, Typography } from "@mui/material";
import styled from "styled-components";
import { Image } from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

const StyledContainer = styled(Container)({
  backgroundColor: "#fffbfa",

  ".contact-wrapper": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    textAlign: "center",
    boxSizing: "border-box",
    paddingBlock: "24px",
    height: "100%",

    ".MuiLink-root": {
      marginBottom: "8px",
      display: "inline-block",
      fontSize: "16px",
    },

    ".button-wrapper": {
      position: "relative",
      width: "fit-content",
      margin: "auto",
      marginTop: "140px",

      ".rocket-img": {
        position: "absolute",
        top: "-275%",
        left: "97.5%",
        zIndex: "1",
        width: "150px",
        height: "auto",

        img: {
          width: "150px",
          height: "auto",
        },
      },
    },
  },

  "@media (max-width: 599px)": {
    ".contact-wrapper": {
      ".button-wrapper": {
        marginTop: "300px",
        ".rocket-img": {
          // marginLeft: "-200px",
          // display: "none",
          width:"270px",
          top:"-540%",
          left:"3%",
          // transform: "scaleX(-1)"
        },
      },

      ".footer-brand-logo": {
        width: "300px",
      },
    },
  },
});

const StyledButton = styled(Button)({
  "&&": {
    width: "275px",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    lineHeight: 1.5,
    fontWeight: 700,
    borderRadius: "50px",
    backgroundColor: "#ffcc00",
    border: "1px solid #ffcc00",
    padding: "8px 28px",
    textTransform: "unset",
    transition: "0.5s",

    "&:hover": {
      backgroundColor: "#ffcc00",
      borderColor: "#ffcc00",
      color: "#0c253e",
      boxShadow: "rgba(0, 0, 0, 0.25) 5px 5px 5px 5px",
      transform: "scale(1.015)",
    },
    "&:active": {
      backgroundColor: "#ffcc00",
      borderColor: "#ffcc00",
      color: "#0c253e",
      boxShadow: "rgba(0, 0, 0, 0.25) 5px 5px 5px 5px",
      transform: "scale(1.015)",
    },
  },
});

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <StyledContainer maxWidth={false}>
      <Box className="contact-wrapper">
        <Box>
          <Image
            src={"images/schools-screen/teach-me-tv-footer-logo.webp"}
            className="footer-brand-logo"
            size={600}
          />
          <Box className="button-wrapper">
            <StyledButton
              onClick={() => {
                navigate('/contact');
                window.scrollTo({top: 0, left: 0, behavior: 'instant'});
              }}
            >
              Contact Us 
            </StyledButton>
            <Image
              src={"/images/schools-screen/rocket.webp"}
              className="rocket-img"
            />
          </Box>
        </Box>
        <Box>
          <Link
            href="https://www.teachmetv.co/"
            underline="hover"
            rel="noopener"
            target="_blank"
          >
            www.teachmetv.co
          </Link>

          <Typography variant="body2" fontSize={16} sx={{ color: "#1976d2" }}>
            &copy;2023 TeachMe TV All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default ContactUs;
