import React from 'react';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import {Heading} from "@aws-amplify/ui-react";

export default function RestaurantCode() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const restaurantName = queryParams.get('name');

    return (
        <>
            <Heading level={1}>{restaurantName}</Heading>
            <hr />
            <QRCode value={`http://${window.location.hostname}/restaurant/?name=${restaurantName}`} size={300} />
        </>
    );
}