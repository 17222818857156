export const GRADE_HEADINGS = {
    'K': 'Kindergarten',
    '1': '1st Grade',
    '2': '2nd Grade',
    '3': '3rd Grade',
    '4': '4th Grade',
    '5': '5th & 6th Grade',
}

export const EMOTIONS = {
    ok: {
        emoji: '☺️',
        text: 'Calm, Happy, OK'
    },
    tired: {
        emoji: '😞',
        text: 'Tired, Sad, Sick, Bored'
    },
    silly: {
        emoji: '😜',
        text: 'Silly, Hyper, Confused'
    },
    mad: {
        emoji: '😠',
        text: 'Mad, Frustrated'
    }
}

export const CONTINUE_ACTIVITIES = {
    yes: {
        emoji: '☺️',
        text: 'Yes'
    },
    no: {
        emoji: '😜',
        text: 'No'
    }
}

export const FEEDBACK = {
    fun: {
        emoji: '😆',
        text: 'Fun'
    },
    ok: {
        emoji: '🙂',
        text: 'OK'
    },
    tiring: {
        emoji: '😞',
        text: 'Tiring'
    }
}

export const ACTIVITY_STATUS = {
    started: 'started',
    viewed: 'viewed',
    completed: 'completed'
}

export const NUM_RECOMMENDATIONS = 3

export const StepType = {
    CharacterSelection: "characterSelection",
    NameInput: "nameInput",
    Dialogue: "dialogue",
    GradeSelection: "gradeSelection",
    EmotionSelection: 'emotionSelection',
    FeedbackSelection: 'feedbackSelection',
    Conversation: "conversation",
    Rest: "rest",
    Recommendation: "recommendation",
    RecommendationConfirmation: 'recommendationConfirmation',
    ParentTutorial: 'parentTutorial',
    CheckStartActivities: 'checkStartActivities'
}

export const EmotionStepType = {
    ok: 0,
    tired: 1,
    silly: 2,
    mad: 3
}

export const EMOTIONAL_WORDS = [
    // Positive Emotions
    "happy", "joyful", "elated", "ecstatic", "gleeful", "content", "excited", "euphoric",
    "enthusiastic", "optimistic", "upbeat", "jubilant", "victorious", "grateful", "blessed",
    "playful", "inspired", "cheerful", "merry", "radiant", "satisfied", "fulfilled",

    // Negative Emotions
    "sad", "heartbroken", "grief", "depressed", "mournful", "melancholy", "despair",
    "anguish", "devastated", "tearful", "lonely", "isolation", "empty", "unhappy",
    "disheartened", "downcast", "dismal", "unbearable", "regretful", "remorseful",
    "sorrow", "troubled", "weary", "frustrated", "angry", "annoyed", "irritated",
    "enraged", "outraged", "furious", "livid", "agitated", "aggravated", "infuriated",

    // Neutral and Mixed Emotions
    "calm", "peaceful", "serene", "tranquil", "composed", "relaxed", "neutral", "indifferent",
    "apathetic", "detached", "conflicted", "ambivalent", "mixed", "bewildered", "baffled",
    "perplexed", "puzzled", "confused", "curious", "inquisitive", "intrigued", "interested",

    // Fear and Anxiety
    "anxious", "nervous", "apprehensive", "worried", "concerned", "scared", "terrified",
    "frightened", "panicked", "paranoid", "phobic", "tense", "edgy", "uneasy",

    // Love and Affection
    "love", "affection", "adore", "admire", "care", "tender", "compassion", "romantic",
    "infatuated", "intimate", "warm", "fondness", "devotion", "attachment", "endearing",

    // Surprise
    "surprised", "shocked", "astonished", "amazed", "stunned", "startled", "awe", "wonder",

    // Disgust and Aversion
    "disgust", "revulsion", "nausea", "repulsion", "abhorrence", "aversion", "detest",
    "hate", "hostility", "loathe", "objectionable", "repugnant", "offensive",

    // Anticipation
    "anticipation", "eager", "excitement", "anxious", "yearning", "longing", "craving",
    "impatience", "hopeful", "expectation", "foreboding", "apprehension",
];