import { Flex, Heading, Image, View } from "@aws-amplify/ui-react";
import { LandingButton } from "../../themed";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LandingScreenTop({
  isMobile,
  plansRef,
  showOverlay = false,
  image = "/images/landing/kid-learning.webp",
}) {
  const navigate = useNavigate();

  if (!isMobile)
    return (
      <View
        height={"668px"}
        backgroundImage={`url(${image})`}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 20%",
        }}
        position={"relative"}
      >
        <FloatingDesktopElements />

        <Flex
          direction={"column"}
          width={"38vw"}
          textAlign={"left"}
          justifyContent={"space-evenly"}
          height={"88%"}
        >
          <View paddingTop={100}>
            <Heading
              level={1}
              fontWeight={"900"}
              fontStyle={"normal"}
              fontSize={"4.8rem"}
              fontFamily={"muli, sans-serif"}
              lineHeight={"75px"}
              color={"white"}
              marginLeft={20}
              style={{
                textShadow:
                  "-2px -2px 0 #000,  \n" +
                  "    2px -2px 0 #000,\n" +
                  "    -2px 2px 0 #000,\n" +
                  "     2px 2px 0 #000",
              }}
            >
              Where kids learn to learn.
            </Heading>
          </View>
          {plansRef && (
            <Grid container spacing={2} marginLeft={1} width={"575px"}>
              <Grid item xs={6} md={6}>
                <LandingButton
                  marginTop={"60px"}
                  text={"Get Started"}
                  plansRef={plansRef}
                />
              </Grid>
            </Grid>
          )}
          {!plansRef && (
            <Grid container spacing={2} marginLeft={1} width={"575px"}>
              <Grid item xs={12} md={6}>
                <LandingButton
                  text={"For Schools"}
                  plansRef={plansRef}
                  onClick={() => navigate("/teachers")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LandingButton
                  text={"For Families"}
                  plansRef={plansRef}
                  onClick={() => navigate("/parents")}
                />
              </Grid>
            </Grid>
          )}
        </Flex>
      </View>
    );

  // isMobile
  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      backgroundImage={`url(${image})`}
      height={"calc(100vh - 80px)"}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "55% 50%",
      }}
      position={"relative"}
      justifyContent={"space-between"}
    >
      <View
        backgroundColor={showOverlay ? "rgba(0,0,0,0.5)" : "none"}
        padding={showOverlay ? "10px" : "0px"}
        borderRadius={showOverlay ? "8px" : "0px"}
        marginTop={15}
        position={"relative"}
        style={{
          zIndex: 0,
        }}
      >
        <Heading
          level={1}
          fontWeight={"900"}
          fontStyle={"normal"}
          fontSize={"2.0rem"}
          fontFamily={"muli, sans-serif"}
          textAlign={"center"}
          color={"white"}
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 1.0)" }}
        >
          Where kids learn to learn.
        </Heading>
      </View>

      <Grid container spacing={2} padding={1} marginBottom={5}>
        <Grid item xs={6} md={6}>
          <LandingButton
            text={"For Schools"}
            plansRef={plansRef}
            onClick={() => navigate("/teachers")}
            width={undefined}
            fontSize={"1.1rem"}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <LandingButton
            text={"For Families"}
            plansRef={plansRef}
            onClick={() => navigate("/parents")}
            width={undefined}
            fontSize={"1.1rem"}
          />
        </Grid>
      </Grid>
    </Flex>
  );
}

const FloatingDesktopElements = () => (
  <>
    <Image
      alt={"Floating symbol"}
      position={"absolute"}
      top={"70%"}
      right={"5%"}
      width={"150px"}
      src={"/images/landing/music-notes.svg"}
    />
    <Image
      alt={"Floating symbol"}
      position={"absolute"}
      bottom={"45%"}
      right={"5%"}
      width={"250px"}
      src={"/images/landing/pythagorous-theorem.png"}
    />
  </>
);
