import {Flex, Heading,  View} from "@aws-amplify/ui-react";

export default function LandingScreenTeamMember(props) {
    return (<Flex direction={'column'} width={props.isMobile ? '320px' : '340px'} alignItems={'center'}>
            <View backgroundImage={"url('/images/landing/testimonial-box.svg')"}>
                <img
                    alt={'Team member'}
                    width={250}
                    height={250}
                    style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: 'thick solid #ff1616'
                    }}
                    src={`/images/landing/${props.photo}`}
                />
            </View>
            <Heading level={2} textAlign={'center'} fontFamily={'muli, sans-serif'}>
                {props.name}
            </Heading>
            <Heading level={5} textAlign={'center'} color={'#ff1616'}>
                {props.role}
            </Heading>
            <ul style={{listStyle: 'none', textAlign: 'center', paddingLeft: 0}}>
                {props.description.map((line, index) => <li key={index} style={{fontSize: '15pt'}}>{line}</li>)}
            </ul>
        </Flex>)
}