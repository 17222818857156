import {
    Card,
    Divider,
    Flex,
    Heading,
    Image,
    SearchField,
    SelectField,
    Text,
    useAuthenticator,
    useTheme,
    View
} from "@aws-amplify/ui-react";
import {useNavigate, useSearchParams} from "react-router-dom";

import activities from '../data/activities.json'
import activityTypes from '../data/activityTypes.json'
import subjects from '../data/subjects.json'
import gradeSpelling from '../data/gradeSpelling.json'
import {useEffect, useState} from "react";
import {PrimaryButton, SubscribeButton} from "../themed";
import fetchSubscription from "../fetchSubscription";
import query from "../query";

const PlayScreen = () => {
    useEffect(() => {
        document.title = "Play - TeachMe TV";
    }, []);

    const navigate = useNavigate();
    const {tokens} = useTheme();
    const {user} = useAuthenticator((context) => [context.user]);

    if (!user)
        window.document.location.href = '/account'

    const allGrades = ['all', 'K', '1', '2', '3', '4', '5', '6']

    const [userData, setUserData] = useState();
    const [selectedGrade, setSelectedGrade] = useState(localStorage.getItem('selectedGrade') || 'all');
    const [selectedSubject, setSelectedSubject] = useState(localStorage.getItem('selectedSubject') || 'all');
    const [selectedLength] = useState('all');
    const [selectedActivityType, setSelectedActivityType] = useState(localStorage.getItem('selectedActivityType') || 'all');
    const [search, setSearch] = useState('');

    const [searchParams] = useSearchParams();
    const byPassSubscription = (window.location.href.includes('beta.teachmetv.co') || window.location.href.includes('localhost')) && searchParams.get('test')

    useEffect(() => {
        if (user && !userData)
            fetchData();
    }, [user, userData]);

    const fetchData = async () => {
        setUserData({
            activities: await query(user.username, 'activities'),
            subscription: await fetchSubscription(user.username)
        });
    }

    const onPlayClicked = (hasAccess, activity) => {
        if (hasAccess || byPassSubscription) {
            if (activity.type === 'printable' && activity.url) {
                window.location.href = activity.url;
            } else {
                window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                navigate(`/play/${activity.id}`);
            }
        } else if (window.confirm('You need a paid membership to play this activity. Do you want to subscribe?'))
            navigate('/account');
    }

    const filterLength = (activity) => {
        if (selectedLength === 'all')
            return true

        switch (selectedLength) {
            case '0-2 minutes':
                return activity.length < 2;
            case '2-5 minutes':
                return activity.length >= 2 && activity.length < 5;
            case '5-10 minutes':
                return activity.length >= 5 && activity.length < 10;
            case '10+ minutes':
                return activity.length >= 10;
            default:
                return false;
        }
    }

    const activitiesBySubject = {};
    activities.data.forEach(activity => {
        activity.subjects.forEach(subject => {
            if (!activitiesBySubject.hasOwnProperty(subject))
                activitiesBySubject[subject] = [];

            activitiesBySubject[subject].push(activity);
        })
    });

    const css = `.grow {
        transition: all .2s ease-in-out;
      }
      
      .grow:hover {
        transform: scale(1.05);
    }`;

    const getCardBackgroundColor = (hasAccess) => !hasAccess ? tokens.colors.overlay[50] : tokens.colors.white;

    const ActivityCard = (index, activity, hasAccess) =>
        <Card
            key={index}
            variation="elevated"
            width={window.innerWidth < 600 ? '75vw' : '300px'}
            style={{
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
            borderRadius={20}
            className="grow"
            backgroundColor={getCardBackgroundColor(hasAccess || byPassSubscription)}
            onClick={() => onPlayClicked(hasAccess, activity)}
        >
            <View
                position={'absolute'}
                top={-20}
                left={-20}
                src={activityTypes.data[activity.type].icon}
                width={64}
                height={64}
                style={{backgroundColor: 'white', border: 'thin solid #000000', borderRadius: '50%', zIndex: 1}}
            >
                <Image
                    alt={'Activity type icon'}
                    src={activityTypes.data[activity.type].icon}
                    height={54}
                    style={{backgroundSize: 'contain'}}
                />
            </View>
            {
                user && userData && userData.activities && userData.activities.find(userActivity => userActivity.activityID === activity.id) &&
                <View
                    position={'absolute'}
                    bottom={10}
                    right={10}
                >
                    <Text fontSize={20}>✅</Text>
                </View>
            }

            <Image
                alt={'Activity thumbnail image'}
                src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png`}
                height={150}
                objectFit={'contain'}
                objectPosition={'50% 50%'}
                opacity={!hasAccess && !byPassSubscription ? 0.5 : 1}
            />
            
            <Heading level={4}>{activity.name}</Heading>
            {
                (!activity.grades || activity.grades.length === 0) &&
                <Text>
                    Not graded
                </Text>
            }
            {
                activity.grades && activity.grades.length === 1 &&
                <Text>
                    Grade: {activity.grades[0]}
                </Text>
            }
            {
                activity.grades && activity.grades.length > 1 &&
                <Text>
                    Grades:&nbsp;
                    {activity.grades[0]}
                    -
                    {activity.grades[activity.grades.length - 1]}
                </Text>
            }
        </Card>

    const filteredData = activities.data
        .filter(activity => activity.isFree)
        .filter(activity => activity.type === selectedActivityType || selectedActivityType === 'all')
        .filter(activity => !activity.grades || activity.grades?.includes(selectedGrade) || selectedGrade === 'all')
        .filter(activity => activity.subjects.includes(selectedSubject) || selectedSubject === 'all')
        .filter(activity => filterLength(activity))
        .filter(activity => {
            return activity.name.toLowerCase().includes(search.toLowerCase()) ||
                activity.type.toLowerCase() === search.toLowerCase() ||
                activity.grades?.find(grade => grade.toLowerCase().includes(search.toLowerCase())) ||
                activity.subjects.find(subject => subject.toLowerCase().includes(search.toLowerCase())) ||
                activity.keywords?.find(keyword => keyword.toLowerCase().includes(search.toLowerCase()));
        });

    return (
        <View>
            <style>{css}</style>
            <View style={styles.container}/>
            <Heading level={1}>Kids Zone</Heading>
            <Divider paddingTop={tokens.space.medium}/>

            <Flex
                direction={{base: 'column'}}
                width='95%'
                alignItems={'center'}
                justifyContent={'center'}
                margin={'auto auto'}
                style={styles.section}
            >
                {
                    !user &&
                    <View paddingBottom={tokens.space.large}>
                        <PrimaryButton text={'Sign up/Log in'} fontSize={'2em'} onClick={() => navigate('/account')}/>
                    </View>
                }

                {
                    user &&
                    <View backgroundColor={'white'} paddingBottom={tokens.space.small} border={'thin solid #888888'}
                          borderRadius={20}>
                        <Flex justifyContent={'center'} padding={tokens.space.small}>
                            {
                                Object.keys(activityTypes.data).map(type =>
                                    <View
                                        key={type}
                                        value={type}
                                        maxWidth={'15vw'}
                                        style={{
                                            borderStyle: 'none',
                                            borderRadius: 8,
                                            padding: 8,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: selectedActivityType === type ? 'orange' : 'white'
                                        }}
                                        onClick={() => {
                                            if (type === selectedActivityType) {
                                                localStorage.setItem('selectedActivityType', 'all');
                                                setSelectedActivityType('all')
                                            } else {
                                                localStorage.setItem('selectedActivityType', type);
                                                setSelectedActivityType(type)
                                            }
                                        }}
                                    >
                                        <Image
                                            alt={'Activity type icon'}
                                            src={activityTypes.data[type].icon}
                                            width={60}
                                            height={60}
                                            style={{backgroundSize: 'contain'}}
                                        />
                                        <Text>{activityTypes.data[type].id.toUpperCase()}</Text>
                                    </View>
                                )
                            }
                        </Flex>

                        <Flex direction={'column'}>
                            <Flex wrap={'wrap'} justifyContent={'center'} paddingBottom={tokens.space.small}>
                                <SelectField
                                    label='Grade'
                                    value={selectedGrade}
                                    paddingTop={5}
                                    textAlign={'center'}
                                    onChange={(e) => {
                                        setSelectedGrade(e.target.value);
                                        localStorage.setItem('selectedGrade', e.target.value);
                                    }}
                                >
                                    {
                                        allGrades.map(grade =>
                                            <option key={grade}
                                                    value={grade}>{`${grade.charAt(0).toUpperCase()}${grade.slice(1)}`}</option>
                                        )
                                    }
                                </SelectField>
                                <SelectField
                                    label='Subject'
                                    value={selectedSubject}
                                    paddingTop={5}
                                    textAlign={'center'}
                                    onChange={(e) => {
                                        setSelectedSubject(e.target.value);
                                        localStorage.setItem('selectedSubject', e.target.value);
                                    }}
                                >
                                    <option value={'all'}>All</option>
                                    {
                                        Object.keys(subjects.data).map(subject =>
                                            <option key={subject} value={subject}>{subject}</option>
                                        )
                                    }
                                </SelectField>
                            </Flex>
                            <center>
                                <SearchField
                                    label='Search'
                                    placeholder="Search keyword..."
                                    value={search}
                                    width={'93%'}
                                    backgroundColor='white'
                                    onChange={(e) => setSearch(e.target.value)}
                                    onClear={() => setSearch('')}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            e.target.blur();
                                            window.scrollTo(0, 400)
                                        }
                                    }}
                                />
                            </center>
                        </Flex>
                    </View>
                }

                <View padding={tokens.space.small}>
                    {!user &&
                        <View>
                            <Heading level={3} paddingTop={tokens.space.large}>Sample Content</Heading>
                            {
                                filteredData.length > 0 &&
                                <Flex wrap={'wrap'} justifyContent={'center'} alignItems={'stretch'} maxWidth={'95%'}
                                      paddingTop={tokens.space.large} paddingBottom={tokens.space.large}>
                                    {
                                        filteredData.map((activity, index) =>
                                            ActivityCard(index, activity, true)
                                        )
                                    }
                                </Flex>
                            }
                            {
                                filteredData.length === 0 &&
                                <Text>No results for your current search.</Text>
                            }

                            <Divider paddingTop={tokens.space.large}/>

                            <Heading level={3} paddingTop={tokens.space.large}>Premium Content</Heading>
                            <Text>Get a membership to unlock all activities</Text>
                            {
                                !user &&
                                <PrimaryButton text={"Sign up"} onClick={() => navigate('/account')}
                                               marginTop={tokens.space.medium} marginBottom={tokens.space.xxl}/>
                            }
                            {
                                user && userData && !userData.subscription &&
                                <SubscribeButton marginTop={tokens.space.medium} marginBottom={tokens.space.xxl}/>
                            }
                        </View>
                    }
                    <Flex
                        direction={{base: 'column'}}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        {
                            Object.keys(subjects.data).map((subject, index) => {
                                const filteredActivities = activitiesBySubject[subject]
                                    .filter(activity => activity.type === selectedActivityType || selectedActivityType === 'all')
                                    .filter(activity => !activity.grades || activity.grades?.includes(selectedGrade) || selectedGrade === 'all')
                                    .filter(activity => activity.subjects.includes(selectedSubject) || selectedSubject === 'all')
                                    .filter(activity => filterLength(activity))
                                    .filter(activity => {
                                        return activity.name.toLowerCase().includes(search.toLowerCase()) ||
                                            activity.type.toLowerCase() === search.toLowerCase() ||
                                            activity.grades?.find(grade => {
                                                return grade.toLowerCase().includes(search.toLowerCase()) ||
                                                    (gradeSpelling.data.hasOwnProperty(grade) && gradeSpelling.data[grade].includes(search.toLowerCase()))
                                            }) ||
                                            activity.subjects.find(subject => subject.toLowerCase().includes(search.toLowerCase())) ||
                                            activity.keywords?.find(keyword => keyword.toLowerCase().includes(search.toLowerCase()));
                                    });

                                if (filteredActivities.length === 0)
                                    return '';

                                return <View key={index} paddingTop={tokens.space.small}>
                                    <Heading
                                        level={1}
                                        padding={tokens.space.xxxs}
                                        marginBottom={tokens.space.small}
                                        borderRadius={10}
                                        margin={'auto auto'}
                                    >
                                        {subject}
                                    </Heading>
                                    <Flex wrap={'wrap'} justifyContent={'center'} alignItems={'stretch'} width={'100%'}
                                          paddingTop={tokens.space.large}>
                                        {

                                            filteredActivities.map((activity, index) => {
                                                const hasAccess = activity.isFree || (user && userData && userData.subscription);

                                                return ActivityCard(index, activity, hasAccess);
                                            })
                                        }
                                    </Flex>
                                </View>
                            })
                        }
                    </Flex>

                </View>
                {!user && <PrimaryButton maxWidth={'300px'} margin={'auto auto'} onClick={() => navigate('/account')}
                                         text={"Sign up"}/>}
            </Flex>
        </View>
    )
}

const styles = {
    section: {
        paddingTop: '0.5rem',
        paddingBottom: '2.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundImage: 'url(/images/balls.jpg)',

        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        opacity: 0.1,
        zIndex: -100
    }
}

export default PlayScreen;