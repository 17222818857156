import {Flex, View, Heading, Image, Text} from "@aws-amplify/ui-react";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";

export default function LandingScreenTestimonial(props) {
    const {photo, video, message, source, reverse} = props

    const VideoFrame = styled(View)`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('/images/landing/video-frame.png') no-repeat center center;
      background-size: cover;
      z-index: 1;
      pointer-events: none;
    `

    let img = <></>
    if (photo)
        img = <Image
            alt={'Testimonial photo'}
            width={'250px'}
            height={'250px'}
            src={`/images/landing/${photo}`}
            objectFit={'cover'}
        />
    else if (video)
        img = <View position={'relative'}>
            <Vimeo video={video} width={250} />
            <VideoFrame/>
        </View>

    const testimonial = <Flex direction={'column'}
                              maxWidth={(props.maxWidth && !props.isMobile) ? props.maxWidth : '100%'}>
        <Flex>
            {props.openingQuote && <Image
                alt={'Quotes icon'}
                src="/images/landing/quotes.svg"
                width={'50px'}
                height={'50px'}
            />}
            <Flex alignItems={'flex-end'}>
                {
                    message &&
                    <Heading level={3} fontFamily={'muli bold, sans-serif'} fontStyle={'oblique'}
                             fontSize={props.isMobile ? '14pt' : '18pt'}>
                        {message}
                    </Heading>
                }
                {props.closingQuote && <Image
                    alt={'Quotes icon'}
                    src="/images/landing/quotes.svg"
                    width={'50px'}
                    height={'50px'}
                    style={{transform: 'rotate(180deg)'}}
                />}
            </Flex>
        </Flex>
        <Text>
            {source}
        </Text>
    </Flex>

    return (<Flex padding={'20px'} alignItems='center' justifyContent={'center'} wrap={'wrap'}>
        {(!reverse || props.isMobile) && <>
            {img}{testimonial}
        </>}
        {reverse && !props.isMobile && <>
            {testimonial}{img}
        </>}
    </Flex>)
}