import { useNavigate, useSearchParams } from "react-router-dom";
import Grades from "./grades";
import Subjects from "./subjects";
import Activities from "./activities";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import { useRef } from "react";
import useActivities from "../../hooks/useActivities";
import { useMobile } from "../../hooks/useMobile";

export default function KidsZoneScreen({ audioRef }) {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const activitiesRef = useRef(null);
  if (!user) navigate("/");

  const activities = useActivities();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isMobile } = useMobile();

  // const [width, setWidth] = useState(window.innerWidth);

  // const handleWindowSizeChange = () => setWidth(window.innerWidth);

  // useEffect(() => {
  //     window.addEventListener('resize', handleWindowSizeChange);
  //     return () => window.removeEventListener('resize', handleWindowSizeChange);
  // }, []);

  // const isMobile = width < 769

  const setSelectedGrade = (grade) => setSearchParamWithValue("grade", grade);
  const setSelectedSubject = (subject) =>
    setSearchParamWithValue("subject", subject);
  const setSelectedType = (type) => setSearchParamWithValue("type", type);
  const setSearchTerm = (searchTerm) =>
    setSearchParamWithValue("searchTerm", searchTerm);
  const setSelectedPage = (pageNumber) =>
    setSearchParamWithValue("pageNumber", pageNumber);

  const setSearchParamWithValue = (paramName, paramValue) => {
    // Get the previous value of the parameter
    const prevParamValue = searchParams.get(paramName);

    if (paramValue === null || paramValue === prevParamValue) {
      // Delete the parameter if the new value is null or is the same as the previous value
      searchParams.delete(paramName);
    } else {
      // Otherwise, update the parameter with the new value
      searchParams.set(paramName, paramValue);
    }

    // Update the search parameters in the URL
    setSearchParams(searchParams);
  };

  const grade = searchParams.get("grade");
  const subject = searchParams.get("subject");
  const type = searchParams.get("type");
  const searchTerm = searchParams.get("searchTerm");
  const selectedPage = parseInt(searchParams.get("pageNumber"));

  return (
    <View paddingBottom={100}>
      {!grade && !searchTerm && activities.length !== 0 && (
        <Grades
          setSelectedGrade={setSelectedGrade}
          activities={activities}
          setSearchTerm={setSearchTerm}
        />
      )}

      {grade && !subject && !searchTerm && activities.length !== 0 && (
        <Subjects
          isMobile={isMobile}
          activities={activities}
          setSelectedGrade={setSelectedGrade}
          setSelectedSubject={setSelectedSubject}
          setSearchTerm={setSearchTerm}
          grade={grade}
        />
      )}

      {(subject || searchTerm) && activities.length !== 0 && (
        <Activities
          activitiesRef={activitiesRef}
          audioRef={audioRef}
          isMobile={isMobile}
          activities={activities}
          grade={grade}
          subject={subject}
          type={type}
          searchTerm={searchTerm}
          page={selectedPage}
          setSelectedGrade={setSelectedGrade}
          setSelectedSubject={setSelectedSubject}
          setSelectedType={setSelectedType}
          setSearchTerm={setSearchTerm}
          setPage={setSelectedPage}
        />
      )}
    </View>
  );
}
