import { Flex, Image, useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBanner from "./top-banner";
import { Grid, Box, IconButton } from "@mui/material";
import MobileBurgerMenu from "./mobile-burger-menu";
import NavBarTabs from "./nav-bar-tabs";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AccountMenu from "./dropdown-account-menu";

const iconStyles = {
    height: "2em",
    width: "2em",
};

export default function Header(props) {
    const { aboutRef } = props;
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const { user } = useAuthenticator((context) => [context.user]);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => window.removeEventListener("resize", handleWindowSizeChange);
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            {!user && <TopBanner isMobile={isMobile} />}

            <Grid position={"relative"}>
                <Flex
                    direction={{ base: "row" }}
                    style={{
                        zIndex: 1,
                    }}
                    width="100%"
                    height={"80px"}
                    justifyContent="space-between"
                >
                    <Image alt={"Animated logo"} src="/images/logo.gif" height={66} width={isMobile ? "32vw" : 200} objectFit={"contain"} paddingLeft={10} onClick={() => navigate(user ? "/kids-zone" : "/")} style={{ cursor: "pointer" }} />
                    {!user && (
                        <>
                            <Box sx={{ display: { xs: "block", sm: "none" } }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    size={"large"}
                                    onClick={handleDrawerToggle}
                                    sx={{
                                        display: { xs: "block", sm: "none" },
                                        height: 80,
                                        width: 80,
                                    }}
                                >
                                    {drawerOpen ? <CloseIcon style={iconStyles} /> : <MenuIcon style={iconStyles} />}
                                </IconButton>
                            </Box>
                            <Box sx={{ display: { xs: "none", sm: "block" } }}>
                                <Flex direction={{ base: "row" }} padding="1rem" width="100%" justifyContent="flex-end">
                                    <NavBarTabs aboutRef={aboutRef} />
                                </Flex>
                            </Box>
                        </>
                    )}
                    {user && (
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <Flex direction={{ base: "row" }} padding="1rem" width="100%" justifyContent="flex-end">
                                <AccountMenu />
                            </Flex>
                        </Box>
                    )}
                </Flex>
                {!user && (
                    <MobileBurgerMenu handleDrawerToggle={handleDrawerToggle} drawerOpen={drawerOpen}>
                        <NavBarTabs aboutRef={aboutRef} handleDrawerToggle={handleDrawerToggle} direction={"column"} buttonFontSize={22} />
                    </MobileBurgerMenu>
                )}
            </Grid>
        </>
    );
}
