import React, { useState } from 'react';
import { Resizable } from 're-resizable';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';

function DraggableDialog(props) {
    return (
        <Dialog
            {...props}
            maxWidth={false}
            PaperComponent={DraggablePaperComponent}
        >
            {props.children}
        </Dialog>
    );
}

function DraggablePaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title">
            <div {...props} />
        </Draggable>
    );
}

export default function DragResizeDialog(props) {
    // Conditional default size
    let defaultSize = props.nearFullscreen
        ? {
            width: window.innerWidth * 0.95, // 95% of viewport width
            maxHeight: window.innerHeight * 0.95, // 95% of viewport height
        }
        : {
            width: 1050,
        };

    const [shifted, setShifted] = useState(false);

    const handleShiftClick = () => {
        setShifted(!shifted);
    };

    const dialogStyle = {
        border: '1px solid #ccc',
        borderRadius: '5px',
        overflow: 'hidden',
        backgroundColor: 'white',
    };

    const resizableStyle = {
        marginLeft: shifted ? '-520px' : '0', // Shift the dialog to the left when shifted is true
        marginRight: props.minimized ? '-520px' : '0', // Shift the dialog to the right when minimized is true
        transition: 'margin-left 0.3s ease-in-out, margin-right 0.3s ease-in-out', // Add a smooth transition effect
    }

    return (
        <DraggableDialog open={props.open} onClose={props.onClose}>
            <Resizable defaultSize={defaultSize} minWidth={300} minHeight={300} style={resizableStyle}>
                <div style={dialogStyle}>
                    <DialogTitle
                        style={{
                            cursor: 'move',
                            padding: '15px 15px',
                            background: "linear-gradient(to bottom, #f0f0f0, #dcdcdc)"
                        }}
                        id="draggable-dialog-title"
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                            style={{ position: 'absolute', right: '30px', top: '-3px' }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            edge={'start'}
                            color="inherit"
                            onClick={handleShiftClick}
                            aria-label="minimize"
                            style={{ position: 'absolute', right: '60px', top: shifted ? '-12px' : '-15px', fontWeight: '900' }}
                        >
                            {!shifted && <span>_</span>}
                            {shifted && <span>□</span>}
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers style={{ padding: '15px' }}>
                        {props.children}
                    </DialogContent>
                </div>
            </Resizable>
        </DraggableDialog>
    );
}
