import React, {useState} from 'react';
import {View} from "@aws-amplify/ui-react";
import styled from "styled-components";
import steps from "../../../data/parentBuddySteps.json";
import SceneRenderer from "../../../components/Scene/SceneRenderer";
import DialogueBox from "../../../components/Scene/DialogueBox";
import useStudyBuddyState from "../../../hooks/useStudyBuddyState";
import useDialogue from "../../../hooks/useDialogue";

const ParentBuddyContainer = styled(View)`
  background-color: white;
  height: calc(100vh - 80px);
  position: relative;

  @media only screen and (max-height: 580px) {
    height: 100vh;
  }
`

function Index() {
    const {
        videoRef,
        isAutoPlay,
        setIsAutoPlay,
        isVoiceControlled,
        setIsVoiceControlled
    } = useStudyBuddyState()

    const [step, setStep] = useState(0)
    const dialogue = useDialogue('Pam', steps, step);

    return (
        <ParentBuddyContainer>
            {steps.data.map(s => {
                if (s.step === step) {
                    return (
                        <SceneRenderer
                            key={step}
                            step={s}
                            selectedChar={'Pam'}
                            onBackClick={() => setStep(s.prevStep)}
                            goToNextScene={() => setStep(step + 1)}
                            videoRef={videoRef}
                            isAudio={false}
                        />
                    )
                }
                return null
            })}

            {dialogue !== null && (
                <DialogueBox
                    name={'Pam'}
                    dialogue={dialogue}
                    videoRef={videoRef}
                    isAutoPlay={isAutoPlay}
                    setIsAutoPlay={setIsAutoPlay}
                    onClick={() => setStep(step + 1)}
                    isVoiceControlled={isVoiceControlled}
                    setIsVoiceControlled={setIsVoiceControlled}
                />
            )}
        </ParentBuddyContainer>
    );
}

export default Index;